import {FinancialQuarter} from '@contractool/schema'
import React, {useCallback} from 'react'
import {useHistory, Redirect} from 'react-router-dom'

import {Modal} from 'components/Modal'
import {Button} from 'components/Button'
import {useToasts} from 'hooks'
import {translate} from 'utils/translations'
import Form from './Form'
import {http} from 'utils/http'
import {tag} from './constants'
import {Form as CommonForm} from 'components/Form'

export function New({
  onCreate,
  financialQuarters
}: {
  onCreate: () => void;
  financialQuarters: FinancialQuarter[]
}) {

  const history = useHistory()
  const {success} = useToasts()
  const goToSettingsPage = useCallback(() => history.push('/settings/financial-quarters'), [history])

  return financialQuarters ? (
    <Modal heading={translate('Add Financial Quarter')} onClose={goToSettingsPage}>
      <CommonForm
        initialValues={{id: Date.now()}}
        onSubmit={(financialQuarter) => http.put(`/api/settings/${tag}`, {
          value: [...financialQuarters, financialQuarter]
        })}
        onSuccess={() => {
          onCreate()
          success('Red flags has been updated.', {
            position: 'center'
          })
          goToSettingsPage()
        }}
      >
        <Form />
        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={history.goBack}>
            {translate('Cancel')}
          </Button>

          <CommonForm.Submit>{translate('Save')}</CommonForm.Submit>
        </Modal.Footer>
      </CommonForm>
    </Modal>
  ) : <Redirect to="/settings/financial-quarters" />
}
