import React from 'react'

import {Page} from 'components/Page'
import {FormTabsSection as FormTabs} from 'views/ui/FormTabs'

const NewProjectFormTabs = () => (
  <Page heading="New Project Forms">
    <FormTabs />
  </Page>
)

export default NewProjectFormTabs
