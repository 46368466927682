import * as React from 'react'

import {TextInput} from 'components/TextInput'
import {Context as ReportContext} from 'contexts/reports/tab/report'

const input = {
  className: 'text-xl',
  style: {
    lineHeight: 1.5,
    marginBottom: -1,
    padding: 0
  }
}

const Title = () => {
  const {config, update: updateReport} = React.useContext(ReportContext)
  const {title} = config

  const handleTitleUpdate = React.useCallback(async (updatedTitle) => {
    await updateReport({
      ...config,
      title: updatedTitle
    })
  }, [config, updateReport])

  const [editing, setEditing] = React.useState<boolean>(false)
  const [titleModel, setTitleModel] = React.useState<string>(title)

  const handleClick = () => {
    setEditing(true)
  }

  const handleBlur = async () => {
    await handleTitleUpdate(titleModel)
    setEditing(false)
  }

  return (
    editing ? (
      <TextInput
        autoFocus
        input={input}
        name="title"
        onBlur={handleBlur}
        onChange={setTitleModel}
        value={titleModel}
      />
    ) : <h2 className="text-xl" onClick={handleClick}>{titleModel}</h2>
  )
}

export default Title
