import React from 'react'
import {FieldTemplate, Role, User} from '@contractool/schema'
import {Form} from 'components/Form'
import {Option} from 'components/Dropdown'
import {useRequest} from 'hooks/useRequest'

export const convertArrayToObject = (array: any, key: string) => {
  const initialValue = {}

  return array.reduce((obj: any, item: any) => {
    return {
      ...obj,
      [item[key]]: item
    }
  }, initialValue)
}

export const getOptions = (array: any[] | undefined): Option<any>[] => {
  if (array === undefined || array.length <= 0) {
    return [{label: '', value: ''}]
  } else {
    return array?.map((arr) => {
      if (typeof arr === 'string') {
        return {label: arr, value: arr}
      }
      if (typeof arr === 'boolean') {
        return {label: arr ? 'Yes' : 'No', value: arr}
      }
      const label = arr.name === undefined ? arr.title : arr.name

      return {label: label, value: arr.id}
    })
  }
}

export const isAllowedForUser = (field: FieldTemplate, user?: User) => {
  if (!user || field.userGroups.length === 0) {
    return true
  }
  for (let group of user.groups) {
    if (field.userGroups.indexOf(group.key) > -1) {
      return true
    }
  }

  return false
}

export function TeamRoleSelect({
  role,
  className,
  groups,
  ...rest
}: {
    role: Role;
    className?: string;
    groups?: string[];
    [name: string]: any
}) {
  return role.singular ? (
    <SingularRoleSelect role={role} className={className} groups={groups} {...rest} />
  ) : (
    <MultipleRoleSelect role={role} className={className} groups={groups} {...rest} />
  )
}

function SingularRoleSelect({
  role,
  className,
  groups,
  ...rest
}: {
    role: Role;
    className?: string;
    groups?: string[];
}) {
  const apiParams = React.useMemo(
    () => ({
      groups_in: groups || []
    }),
    [groups]
  )
  const userRole = React.useMemo(
    () => ({
      key: 'role',
      value: role.key
    }),
    [role]
  )

  return (
    <Form.UserDropdown
      name={role.full_key}
      label={role.label}
      legend={role.legend}
      helptext={role.helptext}
      autocomplete={true}
      className={className}
      api="api/users"
      apiParams={apiParams}
      role={userRole}
      required={role.required}
      {...rest}
    />
  )
}

function MultipleRoleSelect({
  role,
  className,
  groups
}: {
    role: Role;
    className?: string;
    groups?: string[];
}) {
  const [users] = useRequest<User[]>('/api/users', [], {
    params: {dropdown: 1, groups_in: groups || []}
  })

  return (
    <Form.UserMultiselect
      name={role.full_key}
      label={role.label}
      legend={role.legend}
      helptext={role.helptext}
      autocomplete={true}
      className={className}
      options={getOptions(users)}
      role={{key: 'role', value: role.key}}
      required={role.required}
    />
  )
}
