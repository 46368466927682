import * as React from 'react'
import ReactECharts, {EChartsOption} from 'echarts-for-react'

import {Context as ReportContext} from 'contexts/reports/tab/report'

const wrapperStyle = {
  height: '100%',
  overflow: 'hidden'
}

const Chart: React.FC<{
    options: EChartsOption;
    onReportList: (data: any) => void
}> = ({options, onReportList}) => {
  const {config: {
    title
  }} = React.useContext(ReportContext)

  const eChartRef = React.useRef(null)
  // React.useEffect(() => {
  //     console.log('e chart mounted', eChartRef.current);
  //     if (eChartRef.current) { // need to check, because of simple-box and accident
  //         // @ts-ignore
  //         const eChartInstance = eChartRef.current.getEchartsInstance();
  //         const resizeEventListener = function() {
  //             console.log('e chart resize', arguments);
  //             eChartInstance.resize();
  //         };
  //         window.addEventListener('resize', resizeEventListener);
  //
  //         return () => {
  //             window.removeEventListener('resize', resizeEventListener);
  //         };
  //     }
  //
  //     return () => {};
  // }, []);
  const onChartReadyCallback = React.useCallback(() => {
    console.log('onChartReadyCallback')
  }, [])

  // const EventsDict = React.useMemo(() => ({}), []);
  const onEvents = {
    'click': onReportList
  }

  const style = React.useMemo(() => ({
    height: '100%'
  }), [])

  if ('value' in options) { // render simple box
    return (
      <>
        <div
          className="text-xl mt-5 mr-5 text-gray-800 cursor-pointer hover:text-blue-500"
        >
          {options.value}
        </div>
        <div className="mt-3 text-sm">
          {title}
        </div>
      </>
    )
  }

  if (Array.isArray(options)) { // render number cards
    return (
      <div className="flex-auto flex flex-wrap">
        {options.map((option: any, idx: number) => (
          <div className="flex-grow border m-1 p-6 rounded-lg" key={option.value.toString() + idx} style={{minHeight: 142}}>
            <div
              className="text-xl mr-5 text-gray-800 cursor-pointer hover:text-blue-500"
              onClick={() => onReportList({
                data: {
                  name: option.label
                }
              })}
            >
              {option.value}
            </div>
            <div className="mt-3 text-sm" style={{width: 80}}>
              {option.label}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div style={wrapperStyle}>
      <ReactECharts
        ref={eChartRef}
        option={options}
        notMerge={true}
        theme="default"
        onChartReady={onChartReadyCallback}
        onEvents={onEvents}
        style={style}
      />
    </div>
  )
}

const memoizedChart = React.memo(Chart)

export default memoizedChart
