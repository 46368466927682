import {Option} from '../components/Dropdown'

export const commodities: Record<string, Record<string, Option<any>[]>> = {
  'Global Real Estate and Facilities (GREF)': {
    'Non Indirect Procurement': [
      {label: 'Expedites and Reworks', value: 'Expedites and Reworks'},
      {
        label: 'Professional Engineering Services',
        value: 'Professional Engineering Services'
      },
      {label: 'Excess and Obsolescence', value: 'Excess and Obsolescence'},
      {label: 'Test Equipment Development', value: 'Test Equipment Development'},
      {label: 'Royalty', value: 'Royalty'},
      {label: 'Purchase Price Variance', value: 'Purchase Price Variance'},
      {
        label: 'Supplier Assist for Like-New Process',
        value: 'Supplier Assist for Like-New Process'
      },
      {label: 'Test Support Activities', value: 'Test Support Activities'},
      {label: 'Leased (Rent)', value: 'Leased (Rent)'},
      {label: 'Owned (Taxes, Land)', value: 'Owned (Taxes, Land)'},
      {label: 'Electricity', value: 'Electricity'},
      {label: 'Natural Gas', value: 'Natural Gas'},
      {label: 'Water', value: 'Water'}
    ],
    'Indirect Procurement': [
      {label: 'Construction', value: 'Construction'},
      {label: 'Engineering/Design', value: 'Engineering/Design'},
      {label: 'Office Furniture', value: 'Office Furniture'},
      {label: 'Transportation Security', value: 'Transportation Security'},
      {label: 'Building/Grounds Maintenance', value: 'Building/Grounds Maintenance'},
      {label: 'Cafeteria', value: 'Cafeteria'},
      {label: 'Coffee/Snack Programs', value: 'Coffee/Snack Programs'},
      {label: 'EHS', value: 'EHS'},
      {label: 'Mailroom/Shipping', value: 'Mailroom/Shipping'},
      {label: 'Record Storage', value: 'Record Storage'},
      {
        label: 'Container, Storage and Hand Tools',
        value: 'Container, Storage and Hand Tools'
      },
      {
        label: 'Material Handling Machinery and Equipment',
        value: 'Material Handling Machinery and Equipment'
      },
      {label: 'Spare Parts/Consumables/MRO', value: 'Spare Parts/Consumables/MRO'},
      {label: 'Office Supplies', value: 'Office Supplies'},
      {label: 'Freight Security', value: 'Freight Security'},
      {label: 'Guard Services', value: 'Guard Services'},
      {label: 'Investigations', value: 'Investigations'},
      {label: 'Technology (LENEL, Cameras)', value: 'Technology (LENEL, Cameras)'},
      {label: 'Aviation', value: 'Aviation'},
      {
        label: 'Asset Recovery, Reuse, and Recycling Partner Services',
        value: 'Asset Recovery, Reuse, and Recycling Partner Services'
      },
      {
        label: 'Asset Recovery Reverse Logistics Services',
        value: 'Asset Recovery Reverse Logistics Services'
      },
      {
        label: 'Electronic Disposition Partner Audit',
        value: 'Electronic Disposition Partner Audit'
      },
      {label: 'Producer Responsibility', value: 'Producer Responsibility'},
      {
        label: 'ARS - Asset Recovery and Disposal',
        value: 'ARS - Asset Recovery and Disposal'
      },
      {label: 'WEEE', value: 'WEEE'},
      {
        label: 'Domestic Passenger Transportation',
        value: 'Domestic Passenger Transportation'
      }
    ]
  },

  'HR Related Services': {
    'Non Indirect Procurement': [{label: 'HR Related Services', value: ''}],
    'Indirect Procurement': [
      {label: 'Broker/Consultant Fees', value: 'Broker/Consultant Fees'},
      {label: 'General Benefits', value: 'General Benefits'},
      {label: 'Life and Disability Insurance', value: 'Life and Disability Insurance'},
      {label: 'Medical Insurance', value: 'Medical Insurance'},
      {label: 'Retirement/401K Provision', value: 'Retirement/401K Provision'},
      {
        label: 'Car Leasing, Automobile Insurance, Fuel Cards',
        value: 'Car Leasing, Automobile Insurance, Fuel Cards'
      },
      {label: 'Training - Course Delivery', value: 'Training - Course Delivery'},
      {label: 'Training - Course Development', value: 'Training - Course Development'},
      {label: 'Immigration', value: 'Immigration'},
      {label: 'Relocation', value: 'Relocation'},
      {label: 'Payroll', value: 'Payroll'},
      {label: 'Personnel Recruitment', value: 'Personnel Recruitment'},
      {
        label: 'Catering and Meals Associated with Employee Travel',
        value: 'Catering and Meals Associated with Employee Travel'
      },
      {
        label: 'Hotels, Air, Transportation, Visa/Passport',
        value: 'Hotels, Air, Transportation, Visa/Passport'
      },
      {label: 'Personal Travel Security', value: 'Personal Travel Security'},
      {label: 'Travel Agencies', value: 'Travel Agencies'},
      {label: 'HR Operations', value: 'HR Operations'},
      {label: 'Outplacement Services', value: 'Outplacement Services'},
      {label: 'Security Background Check', value: 'Security Background Check'}
    ]
  },
  'Human Capital': {
    'Non Indirect Procurement': [
      {label: 'Legal Services', value: 'Legal Services'},
      {label: 'IT Research and Development', value: 'IT Research and Development'}
    ],
    'Indirect Procurement': [
      {label: 'Business Operations', value: 'Business Operations'},
      {label: 'IT', value: 'IT'},
      {label: 'Strategy', value: 'Strategy'},
      {label: 'Engineering Services', value: 'Engineering Services'},
      {label: 'Marcom Services', value: 'Marcom Services'},
      {
        label: 'Contingent Labor (Worker Type: Staff Aug) - Manufacturing/Industrial',
        value: 'Contingent Labor (Worker Type: Staff Aug) - Manufacturing/Industrial'
      },
      {
        label:
                    'Contingent Labor (Worker Type: Staff Aug) - Others (Non-Manufacturing/Industrial)',
        value:
                    'Contingent Labor (Worker Type: Staff Aug) - Others (Non-Manufacturing/Industrial)'
      },
      {label: 'Banking and Investment', value: 'Banking and Investment'},
      {label: 'Collections', value: 'Collections'},
      {label: 'Corporate finance', value: 'Corporate finance'},
      {
        label: 'Credit Agencies and Card Providers',
        value: 'Credit Agencies and Card Providers'
      },
      {label: 'Insurance', value: 'Insurance'},
      {label: 'Security-Related Services', value: 'Security-Related Services'},
      {label: 'Audit', value: 'Audit'}
    ]
  },
  Marcom: {
    'Indirect Procurement': [
      {label: 'Database Management', value: 'Database Management'},
      {label: 'Email Management', value: 'Email Management'},
      {label: 'Fax Management', value: 'Fax Management'},
      {label: 'Licensed Data Lists', value: 'Licensed Data Lists'},
      {label: 'Audio/Visual', value: 'Audio/Visual'},
      {label: 'Event Management', value: 'Event Management'},
      {label: 'Meeting and Events Venue', value: 'Meeting and Events Venue'},
      {label: 'Printing', value: 'Printing'},
      {label: 'Production (Creative)', value: 'Production (Creative)'},
      {label: 'Promotional Items', value: 'Promotional Items'},
      {label: 'Shipping/Storage', value: 'Shipping/Storage'},
      {label: 'Sponsorships/Participation Fee', value: 'Sponsorships/Participation Fee'},
      {label: 'Staff Augmentation', value: 'Staff Augmentation'},
      {label: 'Support Services/Technology', value: 'Support Services/Technology'},
      {label: 'Training', value: 'Training'},
      {label: 'Case Studies', value: 'Case Studies'},
      {label: 'Creative', value: 'Creative'},
      {label: 'Lead Generation (Non-Events)', value: 'Lead Generation (Non-Events)'},
      {label: 'Marketing Programs/Campaigns', value: 'Marketing Programs/Campaigns'},
      {label: 'Marketing Technology', value: 'Marketing Technology'},
      {label: 'Opinion Authorship', value: 'Opinion Authorship'},
      {label: 'Photography/Images', value: 'Photography/Images'},
      {label: 'Production', value: 'Production'},
      {label: 'Public Relations', value: 'Public Relations'},
      {label: 'Sales Incentives', value: 'Sales Incentives'},
      {label: 'Telemarketing', value: 'Telemarketing'},
      {label: 'Video', value: 'Video'},
      {label: 'Web Services Creation', value: 'Web Services Creation'},
      {
        label: 'Web Services Maintenance/Activation',
        value: 'Web Services Maintenance/Activation'
      },
      {label: 'Primary Research', value: 'Primary Research'},
      {label: 'Syndicated Research', value: 'Syndicated Research'},
      {label: 'Whitepapers', value: 'Whitepapers'},
      {label: 'Affiliate Marketing', value: 'Affiliate Marketing'},
      {label: 'Media Support Technology', value: 'Media Support Technology'},
      {label: 'Paid Media', value: 'Paid Media'},
      {label: 'Search Marketing', value: 'Search Marketing'},
      {label: 'Social Media', value: 'Social Media'},
      {label: 'Alliance Marketing', value: 'Alliance Marketing'},
      {label: 'Loyalty Programs', value: 'Loyalty Programs'},
      {label: 'Promoter Services', value: 'Promoter Services'},
      {label: 'Retail Store Furniture', value: 'Retail Store Furniture'},
      {label: 'Retail Store Set Up', value: 'Retail Store Set Up'},
      {label: 'Direct Marketing Print', value: 'Direct Marketing Print'},
      {label: 'Paper', value: 'Paper'},
      {label: 'Print Postage', value: 'Print Postage'},
      {label: 'Translation Services', value: 'Translation Services'},
      {label: 'Interpretation Services', value: 'Interpretation Services'}
    ],
    'Non Indirect Procurement': [{label: 'Partner Funding', value: 'Partner Funding'}]
  },
  'Customer Facing Services': {
    'Indirect Procurement': [
      {label: 'Care Support', value: 'Care Support'},
      {label: 'Contact Auditing', value: 'Contact Auditing'},
      {label: 'Miscellaneous/Sundry', value: 'Miscellaneous/Sundry'},
      {label: 'Sales Support', value: 'Sales Support'},
      {label: 'Tech Support', value: 'Tech Support'},
      {
        label: 'Client - Desktop, Notebook, Workstation Break/Fix',
        value: 'Client - Desktop, Notebook, Workstation Break/Fix'
      },
      {
        label: 'Client and Enterprise CFA (Preventive and Proactive) or CFS Services',
        value: 'Client and Enterprise CFA (Preventive and Proactive) or CFS Services'
      },
      {
        label: 'Enterprise - Storage and Server and Rack Break/Fix',
        value: 'Enterprise - Storage and Server and Rack Break/Fix'
      },
      {
        label: 'Mobile and Other Peripheral Break/Fix',
        value: 'Mobile and Other Peripheral Break/Fix'
      },
      {label: 'DOSD (Tech Direct)', value: 'DOSD (Tech Direct)'},
      {label: 'Application Services', value: 'Application Services'},
      {label: 'CIS - Client Install (No PM)', value: 'CIS - Client Install (No PM)'},
      {label: 'Customer Facing IT Consulting', value: 'Customer Facing IT Consulting'},
      {label: 'Deployment Services', value: 'Deployment Services'},
      {label: 'EDT - Enterprise Deployment', value: 'EDT - Enterprise Deployment'},
      {
        label: 'EIS - Enterprise Install (No PM)',
        value: 'EIS - Enterprise Install (No PM)'
      },
      {
        label: 'ICC DCS - Integration and Deployment',
        value: 'ICC DCS - Integration and Deployment'
      },
      {
        label: 'Professional Services - Expenses',
        value: 'Professional Services - Expenses'
      },
      {
        label: 'MVS - Multivendor Support Services',
        value: 'MVS - Multivendor Support Services'
      },
      {label: 'EDT - Consulting', value: 'EDT - Consulting'},
      {label: 'Managed Services', value: 'Managed Services'}
    ],
    'Non Indirect Procurement': [
      {
        label: 'Warranty/Labor Reimbursement Program',
        value: 'Warranty/Labor Reimbursement Program'
      }
    ]
  },
  Technology: {
    'Indirect Procurement': [
      {label: 'Client Devices', value: 'Client Devices'},
      {label: 'Computer Hardware', value: 'Computer Hardware'},
      {label: 'Computer Maintenance', value: 'Computer Maintenance'},
      {label: 'Copying Services', value: 'Copying Services'},
      {
        label: 'Installation/Uninstallation Services',
        value: 'Installation/Uninstallation Services'
      },
      {label: 'Mainframe Hardware', value: 'Mainframe Hardware'},
      {label: 'Mainframe Maintenance', value: 'Mainframe Maintenance'},
      {label: 'Network Hardware', value: 'Network Hardware'},
      {label: 'Network/Telecom Maintenance', value: 'Network/Telecom Maintenance'},
      {label: 'Other Hardware Maintenance', value: 'Other Hardware Maintenance'},
      {label: 'Peripherals Client Devices', value: 'Peripherals Client Devices'},
      {label: 'Peripherals Data Center', value: 'Peripherals Data Center'},
      {
        label: 'Managed Print Service and Hardware',
        value: 'Managed Print Service and Hardware'
      },
      {label: 'Storage Hardware', value: 'Storage Hardware'},
      {label: 'Test and Measurement', value: 'Test and Measurement'},
      {label: 'Storage Maintenance', value: 'Storage Maintenance'},
      {label: '3rd Party Co-location', value: '3rd Party Co-location'},
      {label: 'Audio/Web/Video Conferencing', value: 'Audio/Web/Video Conferencing'},
      {label: 'Cell Phone - Purchases', value: 'Cell Phone - Purchases'},
      {label: 'Telecommunication - Internet', value: 'Telecommunication'},
      {label: 'Voice', value: 'Voice'},
      {label: 'Cell Phone - Usage', value: 'Cell Phone - Usage'},
      {label: 'Telecommunication - Data', value: 'Telecommunication - Data'},
      {
        label: 'Laptops, Tablets and Standalone Processors',
        value: 'Laptops, Tablets and Standalone Processors'
      }
    ],
    'Non Indirect Procurement': [
      {
        label: 'Electronic Hardware and Component Parts and Accessories',
        value: 'Electronic Hardware and Component Parts and Accessories'
      },
      {
        label: 'Printed Circuits and Integrated Circuits and Micro Assemblies',
        value: 'Printed Circuits and Integrated Circuits and Micro Assemblies'
      },
      {
        label: 'Measuring, Observing, Testing Instruments',
        value: 'Measuring, Observing, Testing Instruments'
      },
      {
        label: 'Professional Engineering and Research Services',
        value: 'Professional Engineering and Research Services'
      },
      {label: 'Engineering Prototype Materials', value: 'Engineering Prototype Materials'},
      {
        label: 'Import Duty and Vat On Development Materials',
        value: 'Import Duty and Vat On Development Materials'
      }
    ]
  },
  Logistics: {
    'Indirect Procurement': [{label: 'Logistics', value: 'Logistics'}],
    'Non Indirect Procurement': [
      {label: 'Cushioning Supplies', value: 'Cushioning Supplies'},
      {
        label: 'Packaging Tubes and Cores and Labels and Accessories',
        value: 'Packaging Tubes and Cores and Labels and Accessories'
      },
      {
        label: 'Securing and Protecting Supplies',
        value: 'Securing and Protecting Supplies'
      },
      {label: 'Packaging - Non Dutiable', value: 'Packaging - Non Dutiable'},
      {
        label: 'Finished Goods - Outbound Ground (Bulk)',
        value: 'Finished Goods - Outbound Ground (Bulk)'
      },
      {
        label: 'Finished Goods - Outbound Ground (Parcel)',
        value: 'Finished Goods - Outbound Ground (Parcel)'
      },
      {
        label: 'Finished Goods - Outbound Air (Bulk)',
        value: 'Finished Goods - Outbound Air (Bulk)'
      },
      {
        label: 'Finished Goods - Outbound Air (Parcel)',
        value: 'Finished Goods - Outbound Air (Parcel)'
      },
      {
        label: 'Finished Goods - Outbound Sea (Bulk)',
        value: 'Finished Goods - Outbound Sea (Bulk)'
      },
      {
        label: 'Finished Goods - Contract Logistics',
        value: 'Finished Goods - Contract Logistics'
      },
      {label: 'Finished Goods - Inbound Air', value: 'Finished Goods - Inbound Air'},
      {label: 'Finished Goods - Inbound Ocean', value: 'Finished Goods - Inbound Ocean'},
      {label: 'Inbound SLC', value: 'Inbound SLC'},
      {label: 'Services', value: 'Services'},
      {
        label: 'Finished Goods - Outbound White Glove',
        value: 'Finished Goods - Outbound White Glove'
      },
      {
        label: 'Finished Goods - Remanufacture/Resell',
        value: 'Finished Goods - Remanufacture/Resell'
      },
      {
        label: 'Client and Enterprise Parts (Services Logistics) - Outbound Ground (Bulk)',
        value: 'Client and Enterprise Parts (Services Logistics) - Outbound Ground (Bulk)'
      },
      {
        label: 'Client and Enterprise Parts (Services Logistics) - Outbound Air (Bulk)',
        value: 'Client and Enterprise Parts (Services Logistics) - Outbound Air (Bulk)'
      },
      {
        label: 'Client and Enterprise Parts (Services Logistics) - Outbound Air (Parcel)',
        value: 'Client and Enterprise Parts (Services Logistics) - Outbound Air (Parcel)'
      },
      {
        label: 'Client and Enterprise Parts (Services Logistics) - Inbound Air',
        value: 'Client and Enterprise Parts (Services Logistics) - Inbound Air'
      },
      {
        label: 'Configuration Services (Services Logistics) - Contract Logistics',
        value: 'Configuration Services (Services Logistics) - Contract Logistics'
      },
      {
        label:
                    'Client and Enterprise Parts (Services Logistics) - Outbound Ground (Parcel)',
        value:
                    'Client and Enterprise Parts (Services Logistics) - Outbound Ground (Parcel)'
      },
      {
        label: 'Client and Enterprise Parts (Services Logistics) - Contract Logistics',
        value: 'Client and Enterprise Parts (Services Logistics) - Contract Logistics'
      },
      {label: 'VMI freight', value: 'VMI freight'},
      {
        label: 'Customs Duty, Tax and Other Statutory Expense',
        value: 'Customs Duty, Tax and Other Statutory Expense'
      },
      {label: 'Expedites Shipment Freight Cost', value: 'Expedites Shipment Freight Cost'},
      {
        label: 'Inbound Freight - Non Dell Manufacturing',
        value: 'Inbound Freight - Non Dell Manufacturing'
      }
    ]
  },
  Software: {
    'Indirect Procurement': [{label: 'Software', value: 'Software'}],
    'Non Indirect Procurement': [
      {label: 'Consulting and Support', value: 'Consulting and Support'},
      {
        label: 'Direct Software Maintenance and Support',
        value: 'Direct Software Maintenance and Support'
      },
      {label: 'Embedded Software Licenses', value: 'Embedded Software Licenses'},
      {label: 'Embedded Software Royalty', value: 'Embedded Software Royalty'},
      {label: 'Engineering Support', value: 'Engineering Support'},
      {label: 'Enterprise Hardware', value: 'Enterprise Hardware'},
      {
        label: 'Indirect Software Maintenance and Support',
        value: 'Indirect Software Maintenance and Support'
      },
      {label: 'Maintenance Mainframes', value: 'Maintenance Mainframes'},
      {
        label: 'Non- Recoverable Engineering (NRE)',
        value: 'Non- Recoverable Engineering (NRE)'
      },
      {label: 'OEM and IoT', value: 'OEM and IoT'},
      {label: 'OEM Software Licenses', value: 'OEM Software Licenses'},
      {label: 'OEM Software Royalty', value: 'OEM Software Royalty'},
      {
        label: 'On Premise and Perpetual Licenses',
        value: 'On Premise and Perpetual Licenses'
      },
      {
        label: 'On Premise and Subscription Licenses',
        value: 'On Premise and Subscription Licenses'
      },
      {label: 'Peripherals', value: 'Peripherals'},
      {label: 'Printers and Scanners', value: 'Printers and Scanners'},
      {label: 'Rack and Stack', value: 'Rack and Stack'},
      {
        label: 'SaaS/Hosted Software Applications',
        value: 'SaaS/Hosted Software Applications'
      },
      {
        label: 'Shrink Wrap/Off the Shelf Software',
        value: 'Shrink Wrap/Off the Shelf Software'
      },
      {label: 'Software', value: 'Software'},
      {label: 'Software License Resale', value: 'Software License Resale'},
      {
        label: 'Software Maintenance and Support Resale',
        value: 'Software Maintenance and Support Resale'
      }
    ]
  },
  'Financial Services': {
    'Indirect Procurement': [{label: 'Financial Services', value: 'Financial Services'}],
    'Non Indirect Procurement': [
      {
        label: 'Bank Guarantee and/or Bank Charges, Collection fees',
        value: 'Bank Guarantee and/or Bank Charges, Collection fees'
      },
      {label: 'Cash Bidding', value: 'Cash Bidding'},
      {label: 'Legal Settlements', value: 'Legal Settlements'},
      {
        label: 'Candidate Interview Expense Reimbursement',
        value: 'Candidate Interview Expense Reimbursement'
      }
    ]
  },

  'Government, Taxation, Trade Regulation': {
    'Indirect Procurement': [{label: 'Government, Taxation, Trade Regulation', value: ''}],
    'Non Indirect Procurement': [
      {
        label: 'Customs and Duties to Government Authority',
        value: 'Customs and Duties to Government Authority'
      },
      {label: 'Government Administration Fees', value: 'Government Administration Fees'},
      {
        label: 'Government Agencies/Statutory Bodies',
        value: 'Government Agencies/Statutory Bodies'
      },
      {label: 'Taxes to Tax Authority', value: 'Taxes to Tax Authority'}
    ]
  },

  'Community and Social Service': {
    'Indirect Procurement': [
      {label: 'Community and Social Service', value: 'Community and Social Service'}
    ],
    'Non Indirect Procurement': [
      {
        label: 'Dell Giving Charitable Donations',
        value: 'Dell Giving Charitable Donations'
      },
      {label: 'Social Clubs/Membership', value: 'Social Clubs/Membership'},
      {
        label: 'China Employee Steering Committee Payment',
        value: 'China Employee Steering Committee Payment'
      },
      {label: 'Labor Union', value: 'Labor Union'}
    ]
  },

  'Value Added Resellers': {
    'Indirect Procurement': [
      {label: 'Value Added Resellers', value: 'Value Added Resellers'}
    ],
    'Non Indirect Procurement': [{label: 'DFS VARs', value: 'DFS VARs'}]
  },

  'Rebates and Refunds': {
    'Indirect Procurement': [{label: 'Rebates and Refunds', value: 'Rebates and Refunds'}],
    'Non Indirect Procurement': [
      {label: 'Rebates to Customers/Suppliers', value: 'Rebates to Customers/Suppliers'},
      {label: 'Refunds to/from Customers', value: 'Refunds to/from Customers'},
      {
        label: 'Buy Back - SWAP Program, Buy Back Used Hardware',
        value: 'Buy Back - SWAP Program, Buy Back Used Hardware'
      }
    ]
  },

  Production: {
    'Indirect Procurement': [{label: 'Production', value: 'Production'}],
    'Non Indirect Procurement': [{label: 'Production', value: 'Production'}]
  }
}
