import {EmailTemplates} from '@contractool/schema'
import React, {FC} from 'react'
import ReactDOM from 'react-dom'
import {useHistory} from 'react-router'

import {Button} from 'components/Button'
import {translate} from 'utils/translations'

interface IListProps {
  emailTemplates: EmailTemplates[]
}

const List: FC<IListProps> = ({emailTemplates}) => {
  const history = useHistory()

  const buttonContainer = document.getElementById('page-header-right')

  return (
    <><table className="table">
      <thead>
        <tr>
          <th>{translate('ID')}</th>
          <th>{translate('Name')}</th>
          <th>{translate('Tag')}</th>
          <th>{translate('Subject')}</th>
        </tr>
      </thead>
      <tbody>
        {emailTemplates.map((emailTemplate) => (
          <tr
            key={emailTemplate.id}
            className="hover:bg-gray-000 cursor-pointer rounded"
            onClick={() => history.push(`/settings/email-templates/${emailTemplate.id}/edit`)}
          >
            <td>{emailTemplate.id}</td>
            <td>{emailTemplate.name}</td>
            <td>{emailTemplate.tag}</td>
            <td>{emailTemplate.subject}</td>
          </tr>
        ))}
      </tbody>
    </table>
    {buttonContainer && ReactDOM.createPortal((
      <Button.Link to="/settings/email-templates/new" color="blue" icon="add">
        {translate('Add Email Template')}
      </Button.Link>
    ), buttonContainer)}
    </>
  )
}

export default List
