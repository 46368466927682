import {Project} from '@contractool/schema'
import {Button} from 'components/Button'
import React, {useMemo, FC} from 'react'
import {sign} from 'utils/auth'
import {translate} from 'utils/translations'

const ShowDocumentButton: FC<{project: Project}> = ({project}) => {
  const latestAttachment = project.attachments.reduce(
    (p, v) => v.id >= p.id ?
      v : p,
    {id:0, download_url:'', icon: 'description'}
  )
  const icon = useMemo(() => {
    switch (latestAttachment.icon) {
      case 'file-alt':
      case 'file-archive':
      case 'file-audio':
      case 'file-excel':
      case 'file-image':
      case 'file-pdf':
      case 'file-powerpoint':
      case 'file-video':
      case 'file-word':
        return latestAttachment.icon
      default:
        return 'description'
    }
  }, [latestAttachment.icon])

  return latestAttachment.download_url.length ? (
    <a
      href={sign(latestAttachment.download_url)}
      target="_blank"
      rel="noopener noreferrer"
      className="ml-4"
    >
      <Button className="whitespace-no-wrap" color="blue" icon={icon}>
        {translate('Show document')}
      </Button>
    </a>
  ) : null
}

export default ShowDocumentButton
