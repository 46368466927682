import React, {FC} from 'react'
import {translate} from 'utils/translations'

export const DemoTutorial: FC<{}> = () => {
  return (
    <div>
      <h1 className="text-2xl mb-4">{`${translate('Welcome to your new project')}.`}</h1>
      <p>
        {translate(
          'Once all data and files have been submitted, please continue by pressing Progress to next state.'
        )}
      </p>
    </div>
  )
}
