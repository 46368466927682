import {User} from '@contractool/schema'
import {createContext} from 'react'

const AuthContext = createContext<{
    user: User | null;
    loggedIn: boolean;
    login: (user: User) => void;
    logout: () => void;
    update: (user: User) => void;
      }>({
        user: null,
        loggedIn: false,
        login: () => {},
        logout: () => {},
        update: () => {}
      })

export default AuthContext
