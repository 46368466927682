import * as React from 'react'

import {CurrencyInput} from 'components/form/inputs'
import {cx} from 'utils/classnames'

interface ISavingInputProps {
    bold?: boolean;
    disabled?: boolean;
    invalid?: boolean;
    value?: number;
    onChange?: (value: string) => void;
}

const locale = 'en-US'
const currency = 'USD'

const SavingInput: React.FC<ISavingInputProps> = ({disabled, invalid, value, onChange, bold}) => (
  (typeof value === 'number' && !isNaN(value)) ? (
    <CurrencyInput
      className={cx(
        'focus:outline-none placeholder-gray-400 text-center w-full',
        bold ? 'font-bold' : '',
        invalid === true ? 'text-red-900' : '',
        value < 0 ? 'text-red-900' : '',
        invalid === false ? 'text-green-900' : ''
      )}
      disabled={disabled}
      value={String(value)}
      onChange={onChange}
      locale={locale}
      currency={currency}
    />
  ) : (
    <>---</>
  )
)

export default SavingInput
