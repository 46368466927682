import React, {FC, useContext, useEffect, useState} from 'react'
import {Form} from 'components/Form'
import {AppContext} from 'contexts'
import {CustomField} from 'components'
import {FormRole} from 'components'
import {getOptions} from 'utils/form'
import {useRequest} from 'hooks/useRequest'

import {FieldTemplate, Project, ProjectType, ProjectCategory, Role} from '@contractool/schema'
import {EventEmitter} from 'utils/eventEmitter'
import {translate} from 'utils/translations'
import {Mutable as ProjectTitle} from 'components/form/ProjectTitle'
import ProjectFormContext from 'views/project/ProjectFormContext'
import {Workflow} from '../../../../_common/schema/Workflow'

export const DefaultForm: FC<{project: Project}> = ({project}) => {
  const {create, supplierTitle} = React.useContext(ProjectFormContext)
  const [categories] = useRequest<ProjectCategory[]>('/api/project-categories', [])
  const [types] = useRequest<ProjectType[]>('/api/project-types', [])
  const categoriesAsOptions = getOptions(categories)
  const typesAsOptions = getOptions(types)

  const {config} = useContext(AppContext)

  const fields = config.fields
    ? config.fields.filter((f: FieldTemplate) => !f.hidden && f.workflow === project.workflow)
    : []

  const workflow = config.workflows.filter((v: Workflow) => v.key === project.workflow)[0]
  let titleSeparator = workflow.settings.title_separator
  const [titleParts, setTitlePars] = useState<any>({})
  const addTitlePart = (part:string, titlePart:string) => {
    let newParts = JSON.parse(JSON.stringify(titleParts))
    newParts[titlePart] = part
    console.log('addTitlePart', part, titlePart, newParts)
    setTitlePars(newParts)
  }
  if (Object.keys(titleParts).length === 0) {
    addTitlePart('Human Capital', 'dropdown.fields.commodity_lone')
  }
  if (Object.keys(titleParts).length === 1) {
    addTitlePart('Finance', 'dropdown.fields.commodity_ltwo')
  }

  console.log('DefaultFormProject', project)

  for (let titlePart of workflow.settings.title) {
    EventEmitter.subscribe(titlePart, (part:string) => {
      addTitlePart(part, titlePart)
    })
  }

  useEffect(() => {
    if (!create) {
      return
    }
    let parts = []
    for (let titlePart of workflow.settings.title) {
      if (titlePart === 'supplier_title') {
        parts.push(supplierTitle)
      } else {
        parts.push(titleParts[titlePart])
      }
    }

    console.log('parts', parts, titleParts)
    let title = parts.join(titleSeparator).trim()

    EventEmitter.dispatch('new-project.set-form-values', {
      title: title
    })
  }, [titleParts, create, titleSeparator, supplierTitle, workflow.settings.title])

  let groups: any = {}
  for (let f of fields) {
    if (f.group !== '') {
      groups[f.group] = f.group
    }
  }

  return project ? (
    <>
      {config.roles && fields && (
        <>
          <ProjectTitle className="mb-8" />
          <div className="divide-y divide-gray-100">
            <div className="py-8 w-full">
              {categories.length > 0 && (
                <Form.Dropdown
                  name="category_id"
                  className="mb-6"
                  label={translate(config.integration === 'dell_ip' ? 'Category L1' : 'Spend Category')}
                  options={categoriesAsOptions}
                  required={true}
                />
              )}
              {types.length > 0 && (
                <Form.Multiselect
                  name="types"
                  label={translate('Project Types')}
                  options={typesAsOptions}
                  autocomplete
                  placeholder={translate('Project Types')}
                  dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
                  className="mt-6"
                  changeKeys={{key1: 'id', key2: 'title'}}
                  required={true}
                />
              )}
              {/*<Form.DatePicker*/}
              {/*    name="finishes_at"*/}
              {/*    label={translate('Target Date for completion')}*/}
              {/*    className="mt-6"*/}
              {/*    required={true}*/}
              {/*/>*/}
              {fields
                .filter((f: FieldTemplate) => f.group === '')
                .filter((f: FieldTemplate) => create ? true : (f.type !== 'ATTACHMENT'))//lets not display attachment uploads on edit form
                .map((field: FieldTemplate) => (
                  /*//TODO IP intake add supplier field to other implementations*/
                  <CustomField
                    key={`${field.name}`}
                    field={field}
                    option="fields."
                    className="mt-6 mb-4"
                  />
                ))}
              {create && config.roles
                .map(
                  (role: Role) =>
                    role.showInForm &&
                    role.workflow === project.workflow &&
                    role.key !== 'admin' &&
                    role.key !== 'requestor' &&
                    role.key !== 'assignee' && (
                      <FormRole role={role} key={role.key}/>
                    )
                )}
            </div>
            {Object.keys(groups).map((group: string) => {
              return (
                <div key={group}>
                  <h1 className="text-xl">{group}</h1>
                  <div className="py-8 w-full">
                    {fields
                      .filter((f: FieldTemplate) => f.group === group)
                      .map((field: FieldTemplate) => (
                        <CustomField
                          key={`${field.name}`}
                          field={field}
                          option="fields."
                          className="mt-6 mb-2"
                        />
                      ))}
                  </div>
                </div>
              )
            })}
            {/*{fields.map((arr:any, index:number) => (*/}
            {/*<div*/}
            {/*key={`rowdiv${index}`}*/}
            {/*className="flex flex-wrap divide-x divide-gray-100"*/}
            {/*>*/}
          </div>
          {/*))}*/}
          {/*</div>*/}
        </>
      )}
    </>
  ) : null
}
