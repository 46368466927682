import {Clause, ClauseCategory} from '@contractool/schema'
import React, {useCallback} from 'react'

import {translate} from 'utils/translations'
import {Button} from 'components/Button'
import {Form} from 'components/Form'
import {Modal} from 'components/Modal'
import TinymceInput from 'components/template-clause/clause/TinymceInput'
import {useRequest} from 'hooks/useRequest'

const changeKeys = {
  key1: 'id',
  key2: 'name'
}

const modalContainerStyle = {
  height: '100%'
}

const modalStyle = {
  content: {
    height: '90vh'
  }
}

const ClauseOverlay: React.FC<{
    cta: string;
    heading: string;
    initialValues: Clause;
    onDismiss: () => void;
    onSubmit: (clause: Clause) => void;
}> = ({cta, heading, initialValues, onDismiss, onSubmit}) => {
  const [clauseCategories] = useRequest<ClauseCategory[]>('/api/clause-categories', [])
  const clauseCategoryOptions = clauseCategories.map(clauseCategory => ({
    label: clauseCategory.name,
    value: clauseCategory.id
  }))

  const handleSubmit = useCallback((clause: Clause) => Promise.resolve({data: clause}), [])

  return (
    <Modal
      heading={heading}
      isOpen={true}
      onClose={onDismiss}
      compact={false}
      size="w-9/12"
      containerStyle={modalContainerStyle}
      style={modalStyle}
    >
      <Form
        className="h-full flex flex-col"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={onSubmit}
      >
        <Form.TextInput
          name="name"
          label={translate('Name')}
          className="mb-6"
          autoFocus={true}
        />
        <Form.Field
          name="content"
          label={translate('Content')}
          className="flex flex-col flex-1 mb-6"
          component={TinymceInput}
        />
        <Form.Multiselect
          className="mb-6"
          name="categories"
          label={translate('Categories')}
          options={clauseCategoryOptions}
          placeholder="Select categories"
          changeKeys={changeKeys}
        />
        <div className="flex justify-between mt-16">
          <Button
            color="white"
            onClick={onDismiss}
          >
            {translate('Cancel')}
          </Button>
          <Form.Submit>
            {cta}
          </Form.Submit>
        </div>
      </Form>
    </Modal>
  )
}

export default ClauseOverlay
