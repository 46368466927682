import React, {FC} from 'react'
import {Form as CommonForm, FormContext} from 'components/Form'
import moment from 'moment'
import _ from 'lodash'

import {Option} from 'components/Dropdown'

const years = _.range(moment().year() - 1, moment().year() + 9).reduce((prev: Option<number>[], val: number) => {
  return [...prev, {label: `${val}`, value: val}]
}, [])

const requiredFields = [
  'id',
  'year',
  'qt1',
  'qt2',
  'qt3',
  'qt4'
]

interface IFinancialQuartersForm {}

const Form: FC<IFinancialQuartersForm> = () => {
  const {values, invalid, markInvalid} = React.useContext(FormContext)

  React.useEffect(() => {
    const names = Object.keys(values).map((key: string) => key)
    const isAllValid = names.every((name) => (requiredFields.includes(name)) && (values[name].length ||
      !isNaN(parseInt(values[name]))))
    const isAllSet = requiredFields.every((v) => names.includes(v))

    if (isAllValid && isAllSet) {
      markInvalid(false)
    } else {
      markInvalid(true)
    }
  }, [invalid, markInvalid, values])

  return (
    <div>
      <div>
        <CommonForm.Dropdown
          name="year"
          options={years}
          placeholder="Year"
          required
        />
      </div>
      <div>
        <CommonForm.DatePicker
          name="qt1"
          placeholder="Quarter 1"
          required
        />
      </div>
      <div>
        <CommonForm.DatePicker
          name="qt2"
          placeholder="Quarter 2"
          required
        />
      </div>
      <div>
        <CommonForm.DatePicker
          name="qt3"
          placeholder="Quarter 3"
          required
        />
      </div>
      <div>
        <CommonForm.DatePicker
          name="qt4"
          placeholder="Quarter 4"
          required
        />
      </div>
    </div>
  )
}


export default Form
