import {Project} from '@contractool/schema'
import React, {FC, useContext} from 'react'
import {Route, Switch} from 'react-router'
import ReactTooltip from 'react-tooltip'
import {Icon} from 'components/Icon'
import {Tabs, Tab} from 'components/Tabs'
import {Menu, MenuItem} from 'components/Menu'
import {Confirmation} from 'components/Confirmation'
import {sign} from 'utils/auth'
import {Link, useParams} from 'react-router-dom'
import {Modal} from 'components/Modal'
import {Form} from 'components/Form'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import {Button} from 'components/Button'
import {SidePanel} from './../ProjectDetail'
import {ApprovalNew} from './ApprovalNew'
import {ApprovalDetail} from './ApprovalDetail'
import {ApprovalEdit} from './ApprovalEdit'
import {format, parseISO} from 'date-fns'
import {useToasts} from 'hooks'
import {GuardContext} from 'contexts'
import {userPermissionRequest} from 'utils/wildcard'

export const ApprovalsPanel: FC<{project: Project; onUpdate: () => void; onClose: () => void}> = ({
  project,
  onUpdate,
  onClose
}) => {
  // const history = useHistory();

  // history.push(`/projects/${project.id}/approvals/74/resolve`);

  const approvedCount = project.approvals.filter(a => a.state === 'APPROVED').length
  const pendingCount = project.approvals.filter(a => a.state === 'PENDING').length
  const deniedCount = project.approvals.filter(a => a.state === 'DENIED').length
  const canceledCount = project.approvals.filter(a => a.state === 'CANCELED').length

  return (
    <SidePanel
      heading={translate('Approvals')}
      right={
        <Link
          to={`/projects/${project.id}/approvals/new`}
          className="flex items-center text-blue-700"
        >
          <Icon name="add" size={5} />
          <span className="ml-2">{translate('Create new')}</span>
        </Link>
      }
    >
      {project.approvals.length ? (
        <Tabs>
          <Tab name="pending" heading={translate('Pending') + ' ' + pendingCount} className="pt-4">
            <ApprovalTab project={project} state="PENDING" />
          </Tab>
          <Tab name="approved" heading={translate('Approved') + ' ' + approvedCount} className="pt-4">
            <ApprovalTab project={project} state="APPROVED" />
          </Tab>
          <Tab name="denied" heading={translate('Denied') + ' ' + deniedCount} className="pt-4">
            <ApprovalTab project={project} state="DENIED" />
          </Tab>
          <Tab name="canceled" heading={translate('Canceled') + ' ' + canceledCount} className="pt-4">
            <ApprovalTab project={project} state="CANCELED" />
          </Tab>
        </Tabs>
      ) : (
        <div className="py-4">
          <div className="w-12 h-12 flex items-center justify-center border border-gray-000 text-gray-600 bg-gray-000 rounded-full m-auto">
            <Icon name="list" size={5} />
          </div>

          <div className="text-gray-600 text-center pt-6">
            {`${translate('There are no approvals yet')}...`}
          </div>
        </div>
      )}
      <Switch>
        <Route path={`/projects/${project.id}/approvals/new`}>
          <ApprovalNew onCreate={onUpdate} project={project} />
        </Route>

        <Route exact path={`/projects/${project.id}/approvals/:approvalId/resolve`}>
          <ResolveApprovalModal
            onCancel={onClose}
            onSuccess={() => {
              onUpdate()
              onClose()
            }}
            project={project}
          />
        </Route>
        <Route exact path={`/projects/${project.id}/approvals/:approvalId`}>
          <ApprovalDetail onClose={onClose} project={project} />
        </Route>
        <Route exact path={`/projects/${project.id}/approvals/:approvalId/edit`}>
          <ApprovalEdit
            onClose={onClose}
            onUpdate={() => {
              onUpdate()
              onClose()
            }}
            project={project}
          />
        </Route>
      </Switch>
    </SidePanel>
  )
}

const ApprovalTab: FC<{
    project: Project;
    state: 'PENDING' | 'APPROVED' | 'DENIED' | 'CANCELED';
}> = ({project, state}) => {
  let approvals = project.approvals.filter((approval) => approval.state === state)

  if (approvals.length === 0) {
    return (
      <div className="pt-6 pb-4">
        <div className="w-12 h-12 flex items-center justify-center border border-gray-000 text-gray-600 bg-gray-000 rounded-full m-auto">
          <Icon name="list" size={5} />
        </div>

        <div className="text-gray-600 text-center pt-6">
          {`${
            (translate('There are no :state approvals',
              {
                state: state.toLowerCase()
              }))
          }...`}
        </div>
      </div>
    )
  }

  return (
    <table className="w-full">
      <tbody className="">
        {approvals.map((approval) => (
          <tr key={approval.id}>
            <td className="w-14 py-4 pr-4">
              <Link to={`/projects/${project.id}/approvals/${approval.id}/resolve`}>
                {approval.state === 'PENDING' && (
                  <div
                    className="w-10 h-10 flex items-center justify-center border border-gray-200 text-gray-500 bg-white rounded-full"
                    title={translate('Approve') + ' ' + approval.title}
                  >
                    <Icon name="done" size={5}/>
                  </div>
                )}

                {approval.state === 'APPROVED' && (
                  <div
                    className="w-10 h-10 flex items-center justify-center border border-teal-000 text-teal-600 bg-teal-000 rounded-full"
                    title={translate('Approved')}
                  >
                    <Icon name="done" size={5}/>
                  </div>
                )}

                {approval.state === 'DENIED' && (
                  <div
                    className="w-10 h-10 flex items-center justify-center border border-red-000 text-red-700 bg-red-000 rounded-full"
                    title={translate('Approval Denied')}
                  >
                    <Icon name="close" size={5}/>
                  </div>
                )}

                {approval.state === 'CANCELED' && (
                  <div
                    className="w-10 h-10 flex items-center justify-center border border-red-000 text-red-700 bg-red-000 rounded-full"
                    title={translate('Approval Canceled')}
                  >
                    <Icon name="remove_circle" size={5}/>
                  </div>
                )}
              </Link>
            </td>
            <td className="py-4">
              <Link to={`/projects/${project.id}/approvals/${approval.id}/resolve`}>
                {approval.title}
              </Link>
            </td>
            <td className="w-31 py-4 pl-10 pr-13">
              <img
                data-tip={approval.assignee.name}
                src={sign(approval.assignee.avatar_url)}
                alt={approval.assignee.name}
                className="w-8 h-8 rounded-full"
              />
              <ReactTooltip />
            </td>
            <td className="w-40 py-4">
              <Icon
                name="date_range"
                size={6}
                className="mr-3 inline text-gray-500"
              />
              <span className="w-15 text-gray-600">
                {formatDeadline(approval.deadline)}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const ResolveApprovalModal: FC<{
    project: Project;
    onCancel: () => void;
    onSuccess: () => void;
}> = ({project, onSuccess, onCancel}) => {
  const {approvalId} = useParams<{approvalId?: string}>()
  const approval = project.approvals.find((t) => approvalId && t.id === parseInt(approvalId))

  const {error} = useToasts()
  const {user} = useContext(GuardContext)
  if (!approval) return null
  const isResolved = ['APPROVED', 'DENIED'].includes(approval.state)
  const canApprove = () => {
    if (userPermissionRequest(user, 'approval.superpower'))
    {
      return true
    }

    return approval.assignee_id === user.id
  }
  const handleSubmit = (values: any, mode?: string) => {
    switch (mode) {
      case 'deny':
        values.state = 'DENIED'
        break
      case 'cancel':
        values.state = 'CANCELED'
        break
    }

    return http.put(approval.state_url, values)
  }

  return approval.state ? (
    <Modal
      heading={isResolved ? translate('Re-open approval') : translate('Approve') + ' ' + approval.title}
      size="regular"
      onClose={onCancel}
      corner={approval.state === 'PENDING' && (
        <Menu handle="more_vert">
          <Form
            initialValues={{state: 'CANCELED'}}
            onSubmit={handleSubmit}
            onSuccess={onSuccess}
            onError={(errors: any) => {
              error(translate(errors.state[0]))
            }}
            allowPristineSubmission={true}
            supportsSubmitMode
          >
            <Confirmation
              onConfirm={() => {}}
              trigger={({onClick}) => (
                <MenuItem
                  icon="remove_circle"
                  onClick={(e) => {
                    e.stopPropagation()
                    onClick()
                  }}
                >
                  {translate('Cancel')}
                </MenuItem>
              )}
              heading={translate('Cancel this approval')}
              buttonText={translate('Yes, Cancel')}
              color="yellow"
              button={
                <Form.Submit color="yellow" mode="cancel">
                  {translate('Yes, Cancel')}
                </Form.Submit>
              }
              content={

                <Form.TextArea
                  name="cancel_reason"
                  className="w-full mt-8"
                  label="Reason of cancelation"
                  placeholder="Add reason here..."
                />

              }
            >
              {translate('Are you sure you want to Cancel this approval?')}
            </Confirmation>
          </Form>
        </Menu>
      )}
    >

      <Form
        initialValues={{state: isResolved ? 'PENDING' : 'APPROVED', comment: approval.comment, cancel_reason: approval.cancel_reason}}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        onError={(errors: any) => {
          error(translate(errors.state[0]))
        }}
        allowPristineSubmission={true}
        supportsSubmitMode
      >
        <p className="pb-12">
          {translate('Are you sure you want to :action following approval?', {
            action: isResolved ? translate('re-open') : translate('approve')
          })}
          {translate('It will disappear from')}
          <span className="italic capitalize"> {approval.state.toLowerCase()} </span>
          {`${translate('approvals and move to')} `}
          <span className="italic">
            {isResolved ? translate('Open') : translate('Approved')}
          </span>
                    .
        </p>
        <div className="w-full bg-gray-000 p-4 items-center justify-center flex">
          <div className="flex-initial">
            {approval.state === 'PENDING' && (
              <div className="w-10 h-10 flex items-center justify-center border border-gray-200 text-gray-500 rounded-full">
                <Icon name="done" size={5} />
              </div>
            )}

            {approval.state === 'APPROVED' && (
              <div className="w-10 h-10 flex items-center justify-center border border-teal-000 text-teal-600 bg-teal-000 rounded-full">
                <Icon name="done" size={5} />
              </div>
            )}

            {approval.state === 'DENIED' && (
              <div className="w-10 h-10 flex items-center justify-center border border-red-000 text-red-600 bg-red-000 rounded-full">
                <Icon name="close" size={5} />
              </div>
            )}

            {approval.state === 'CANCELED' && (
              <div className="w-10 h-10 flex items-center justify-center border border-red-000 text-red-600 bg-red-000 rounded-full">
                <Icon name="remove_circle" size={5} />
              </div>
            )}
          </div>
          <div className="flex-1 ml-4">{approval.title}</div>
          <div className="flex-none ml-4 mr-15">
            <div className="flex items-center justify-center">
              <img
                data-tip={approval.assignee.name}
                src={sign(approval.assignee.avatar_url)}
                alt={approval.assignee.name}
                className="w-8 h-8 rounded-full"
              />
              <ReactTooltip />
            </div>
          </div>
          <div className="flex-initial text-gray-600 whitespace-no-wrap flex items-center">
            <Icon name="date_range" size={6} className="text-gray-600 inline mr-3" />
            {formatDeadline(approval.deadline)}
          </div>
        </div>
        <div className="mb-12">
          <textarea
            rows={10}
            className="pb-5 mt-5 w-full focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 resize-none leading-tight border-gray-200 focus:border-blue-700"
            disabled
            value={approval.body}
          />
        </div>
        {(approval.state === 'CANCELED') && <>
          <Form.TextArea
            name="cancel_reason"
            className="w-full mt-8"
            label="Reason of cancelation"
            disabled
          />
          <Modal.Footer className="flex justify-between">
            <Button color="white" onClick={onCancel}>
              {translate('Close')}
            </Button>
          </Modal.Footer>
        </>}
        {(approval.state !== 'CANCELED') && <>
          <Form.TextInput
            name="cancel_reason"
            className="w-full mt-8"
            label="Note"
            placeholder="Add comment here..."
          />
          <Modal.Footer className="flex justify-between">
            <Button color="white" onClick={onCancel}>
              {translate('Close')}
            </Button>

            <div>

              {!isResolved && canApprove() && (
                <Form.Submit className="mr-3" color="white" mode="deny">
                  {translate('Deny')}
                </Form.Submit>
              )}
              {canApprove() &&
                <Form.Submit mode="apply">
                  {translate('Yes, :value', {
                    value: isResolved ? translate('re-open') : translate('approve')
                  })}
                </Form.Submit>}
            </div>
          </Modal.Footer>
        </>}
      </Form>
    </Modal>
  ) : <></>
}

export const formatDeadline = (date: string) => {
  return format(parseISO(date), 'dd MMM yyyy')
}
