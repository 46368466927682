import {ApprovalRule, FinancialQuarter, RedFlag, NotificationSetting, EmailTemplates} from '@contractool/schema'
import React, {useMemo} from 'react'
import {Route, Switch} from 'react-router'

import {RouteTabs, Tab} from 'components/Tabs'
import {Page} from 'components/Page'
import {useRequest} from '../../utils/hooks/useRequest'
import {tag as redFlagsTag} from 'views/settings/red-flags/constants'
import {tag as approvalRulesTag} from 'views/settings/approval-rules/constants'
import {tag as financialQuartersTag} from 'views/settings/financial-quarters/constants'
import {tag as notificationSettingsTag} from 'views/settings/notifications/constants'
import {tag as emailTemplatesTag} from 'views/settings/email-templates/constants'
import {New as RedFlagNew} from './red-flags/New'
import {Edit as RedFlagEdit} from './red-flags/Edit'
import RedFlagsList from './red-flags/List'
import EmailTemplatesList from './email-templates/List'
import {New as ApprovalRuleNew} from './approval-rules/New'
import {Edit as ApprovalRuleEdit} from './approval-rules/Edit'
import ApprovalRulesList from './approval-rules/List'
import {New as FinancialQuarterNew} from './financial-quarters/New'
import {Edit as FinancialQuarterEdit} from './financial-quarters/Edit'
import FinancialQuartersList from './financial-quarters/List'
import {New as NotificationSettingNew} from './notifications/New'
import {Edit as NotificationSettingEdit} from './notifications/Edit'
import NotificationSettingsList from './notifications/List'
import {New as EmailTemplatesSettingNew} from './email-templates/New'
import {Edit as EmailTemplatesSettingEdit} from './email-templates/Edit'
import {Pagination} from 'components/Pagination'
import {useQueryParams} from 'hooks/useParams'


export function SettingsPage() {
  const [params, {setParam}] = useQueryParams({phrase: '', page: 1, per_page: 10})
  
  const [redFlagsResponse, {refresh: refreshRedFlags}] = useRequest<RedFlag[]>(`/api/settings/${redFlagsTag}`, [])
  const redFlags = useMemo(() => redFlagsResponse ?? [], [redFlagsResponse])

  const [approvalRulesResponse, {refresh: refreshApprovalRules}] = useRequest<ApprovalRule[]>(`/api/settings/${approvalRulesTag}`, [])
  const approvalRules = useMemo(() => approvalRulesResponse ?? [], [approvalRulesResponse])

  const [financialQuartersResponse, {refresh: refreshFinancialQuarters}] = useRequest<FinancialQuarter[]>(`/api/settings/${financialQuartersTag}`, [])
  const financialQuarters = useMemo(() => financialQuartersResponse ?? [], [financialQuartersResponse])

  const [notificationSettingsResponse, {refresh: refreshNotificationSettings}] = useRequest<NotificationSetting[]>(`/api/settings/${notificationSettingsTag}`, [])
  const notificationSettings = useMemo(() => notificationSettingsResponse ?? [], [notificationSettingsResponse])

  const [emailTemplatesResponse, {refresh: refreshEmailTemplatesSettings, pagination: paginationEmailTemplates}] = useRequest<EmailTemplates[]>(`/api/settings/${emailTemplatesTag}`, [], {
    params: {
      ...params,
      per_page: 10
    }
    
  })
  const emailTemplates = useMemo(() => emailTemplatesResponse ?? [], [emailTemplatesResponse])

  return (
    <Page heading="Settings">
      <RouteTabs match="/settings/:tab">
        <Tab name={notificationSettingsTag} heading="Notifications" className="py-8">
          <div className="-mx-6">
            <NotificationSettingsList notificationSettings={notificationSettings} />
          </div>
        </Tab>
        <Tab name={emailTemplatesTag} heading="Email Templates" className="py-8">
          <div className="-mx-6">
            <EmailTemplatesList emailTemplates={emailTemplates} />
          </div>
          <div className="flex justify-center pt-16">
            <Pagination
              currentPage={paginationEmailTemplates.currentPage}
              totalPages={paginationEmailTemplates.totalPages}
              totalBlocks={7}
              onSelect={(page) => setParam('page', page)}
            />
          </div>
        </Tab>

        <Tab name={approvalRulesTag} heading="Approval Rules" className="py-8">
          <div className="-mx-6">
            <ApprovalRulesList approvalRules={approvalRules} />
          </div>
        </Tab>

        <Tab name={redFlagsTag} heading="Red Flags" className="py-8">
          <div className="-mx-6">
            <RedFlagsList redFlags={redFlags} />
          </div>
        </Tab>

        <Tab name={financialQuartersTag} heading="Financial Quarters" className="py-8">
          <div className="-mx-6">
            <FinancialQuartersList financialQuarters={financialQuarters} />
          </div>
        </Tab>

        <Tab name="integrations" heading="Integrations" className="py-8">

        </Tab>

        <Tab name="api" heading="API" className="py-8">

        </Tab>
      </RouteTabs>

      <Switch>
        <Route path={`/settings/${redFlagsTag}/new`}>
          <RedFlagNew redFlags={redFlags} onCreate={refreshRedFlags} />
        </Route>

        <Route path={`/settings/${redFlagsTag}/:id/edit`}>
          <RedFlagEdit redFlags={redFlags} onUpdate={refreshRedFlags} />
        </Route>

        <Route path={`/settings/${approvalRulesTag}/new`}>
          <ApprovalRuleNew approvalRules={approvalRules} onCreate={refreshApprovalRules} />
        </Route>

        <Route path={`/settings/${approvalRulesTag}/:id/edit`}>
          <ApprovalRuleEdit approvalRules={approvalRules} onUpdate={refreshApprovalRules} />
        </Route>

        <Route path={`/settings/${financialQuartersTag}/new`}>
          <FinancialQuarterNew financialQuarters={financialQuarters} onCreate={refreshFinancialQuarters} />
        </Route>

        <Route path={`/settings/${financialQuartersTag}/:id/edit`}>
          <FinancialQuarterEdit financialQuarters={financialQuarters} onUpdate={refreshFinancialQuarters} />
        </Route>

        <Route path={`/settings/${notificationSettingsTag}/new`}>
          <NotificationSettingNew notificationSettings={notificationSettings} onCreate={refreshNotificationSettings} />
        </Route>

        <Route path={`/settings/${notificationSettingsTag}/:id/edit`}>
          <NotificationSettingEdit notificationSettings={notificationSettings} onUpdate={refreshNotificationSettings} />
        </Route>

        <Route path={`/settings/${emailTemplatesTag}/new`}>
          <EmailTemplatesSettingNew  onCreate={refreshEmailTemplatesSettings} />
        </Route>

        <Route path={`/settings/${emailTemplatesTag}/:id/edit`}>
          <EmailTemplatesSettingEdit onUpdate={refreshEmailTemplatesSettings} />
        </Route>
      </Switch>
    </Page>
  )
}
