import React, {useEffect, useState} from 'react'
import {Section, Variation} from './DesignSystem'
import {useBigLoader, useSmallLoader} from '../../components/Loader'
import {Button} from '../../components/Button'

export function Loaders() {
  const [smallLoading, setSmallLoading] = useState(false)
  const [bigLoading, setBigLoading] = useState(false)
  const smallLoader = useSmallLoader()
  const bigLoader = useBigLoader()

  useEffect(() => {
    if (bigLoading) {
      setInterval(() => {
        setBigLoading(false)
        bigLoader.stop()
      }, 2000)
    }
    if (smallLoading) {
      setInterval(() => {
        setSmallLoading(false)
        smallLoader.stop()
      }, 2000)
    }
  }, [bigLoading, smallLoading, smallLoader, bigLoader])

  const smallLoaderHandler = () => {
    setSmallLoading(true)
    smallLoader.start('Loading...')
  }

  const bigLoaderHandler = () => {
    setBigLoading(true)
    bigLoader.start('Loading...')
  }

  return (
    <>
      <Section title="Loaders">
        <Variation hint="Small loader">
          <Button onClick={smallLoaderHandler}>Start small loader</Button>
        </Variation>
        <Variation hint="Big loader">
          <Button onClick={bigLoaderHandler}>Start big loader</Button>
        </Variation>
      </Section>
    </>
  )
}
