import React, {FC} from 'react'
import {ReactComponent as SplashScreenImage} from '../../assets/images/login/splash_screen.svg'
import {ReactComponent as Logo} from './../../assets/cequence.svg'
import {translate} from 'utils/translations'

export const Layout: FC = ({children}) => {
  const year = new Date().getFullYear()

  return (
    <div className="flex h-full">
      <div className="lg:w-2/5 flex flex-col overflow-y-auto">
        <div className="px-16 py-10">
          <Logo className="h-8" />
        </div>
        <div className="flex-1 flex flex-col justify-center px-30 py-10">
          <div>{children}</div>
        </div>
        <div className="px-16 py-10 select-none">
          <p className="text-gray-600">
                        &copy;{year}&nbsp;
            <a href="/" title="Cequence">
                            Cequence
            </a>
                        . {translate('All rights reserved')}.
          </p>
        </div>
      </div>

      <div className="flex-1 h-full hidden lg:flex bg-blue-000 justify-center items-center px-40">
        <SplashScreenImage className="w-full max-w-200" />
      </div>
    </div>
  )
}
