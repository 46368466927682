import {FinancialQuarter} from '@contractool/schema'
import React, {useCallback, useMemo} from 'react'
import {useParams, useHistory, Redirect} from 'react-router'

import {Button} from 'components/Button'
import {Icon} from 'components/Icon'
import {Menu, MenuItem} from 'components/Menu'
import {Modal} from 'components/Modal'
import {useToasts} from 'hooks'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import Form from './Form'
import {tag} from './constants'
import {Form as CommonForm} from 'components/Form'

export function Edit({financialQuarters, onUpdate}: {
  financialQuarters: FinancialQuarter[]
  onUpdate: () => void
}) {
  const history = useHistory()
  const {id: idParam} = useParams<{id: string}>()
  const id = parseInt(idParam)
  const index = useMemo(() => financialQuarters.findIndex(({id: fqId}) => id === fqId), [financialQuarters, id])

  const goToSettingsPage = useCallback(() => history.push('/settings/financial-quarters'), [history])
  const {success} = useToasts()

  const deleteFQ = useCallback(() => {
    http.put(`/api/settings/${tag}`, {
      value: financialQuarters.filter(({id: fQId}) => id !== fQId)
    }).then(() => {
      onUpdate()
      success('The red flag has been deleted.', {
        position: 'center'
      })
      goToSettingsPage()
    })
  }, [id, financialQuarters, goToSettingsPage, onUpdate, success])
  const financialQuarter = financialQuarters[index]

  return financialQuarters ? (
    <Modal
      heading={translate('Financial Quarter Detail')}
      onClose={goToSettingsPage}
      contentClassName="h-124"
      corner={
        <Menu
          handle={({toggleMenu}) => (
            <div onClick={toggleMenu} className="flex items-center cursor-pointer">
              <Icon name="more_horiz" className="text-gray-600 cursor-pointer" size={6} />
            </div>
          )}
        >
          <MenuItem icon="remove_circle" onClick={deleteFQ}>
            {translate('Delete')}
          </MenuItem>
        </Menu>
      }
    >
      <CommonForm
        initialValues={financialQuarter}
        onSubmit={(fq) => {
          financialQuarters[index] = fq

          return http.put(`/api/settings/${tag}`, {
            value: financialQuarters
          })
        }}
        onSuccess={() => {
          onUpdate()
          success('Red flags has been updated.', {
            position: 'center'
          })
          goToSettingsPage()
        }}
      >
        <Form />

        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={history.goBack}>
            {translate('Cancel')}
          </Button>

          <CommonForm.Submit>{translate('Save Changes')}</CommonForm.Submit>
        </Modal.Footer>
      </CommonForm>
    </Modal>
  ) : <Redirect to="/settings/financial-quarters" />
}
