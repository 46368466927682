import React, {useContext, useCallback} from 'react'
import {Supplier} from '@contractool/schema'
import {Form} from '../../components/Form'
import {http} from '../../utils/http'
import {translate} from 'utils/translations'
import {Modal} from '../../components/Modal'
import {Button} from '../../components/Button'
import {useHistory} from 'react-router-dom'
import {useToasts} from 'hooks'
import SupplierForm from './SupplierForm'
import {convertArrayToObject} from '../../utils/form'
import {AppContext} from 'contexts'

export function SupplierNew({
  onCreate,
  closeUrl
}: {
    onCreate: (supplier: Supplier) => void;
    closeUrl: string;
}) {
  const history = useHistory()
  const {success} = useToasts()
  const {config} = useContext(AppContext)

  const close = useCallback(() => {
    history.push(closeUrl)
  }, [history, closeUrl])

  const handleSubmit = useCallback((values) => http.post<Supplier>('/api/suppliers', values), [])
  const handleSuccess = useCallback(
    (supplier) => {
      onCreate(supplier)
      success(
        `${translate('Supplier :title was added successfully', {
          title: supplier.title
        })}`
      )
      close()
    },
    [onCreate, close, success]
  )

  let fieldsDefaults = convertArrayToObject(config.supplier_fields, 'name')
  for (let key in fieldsDefaults) {
    fieldsDefaults[key] = ''
  }

  return (
    <Modal heading={translate('Add supplier')} onClose={close}>
      <Form
        initialValues={{
          title: '',
          address_street: '',
          address_city: '',
          address_zip: '',
          address_state: '',
          contacts: [],
          fields: fieldsDefaults
        }}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
      >
        <SupplierForm create={true} />
        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={close}>
            {translate('Cancel')}
          </Button>

          <Form.Submit>{translate('Add supplier')}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
