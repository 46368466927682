import {CustomReport} from '@contractool/schema'
import * as React from 'react'
import {useHistory} from 'react-router-dom'

import {Context as ReportsContext} from 'contexts/reports'
import {Context as ReportTabContext} from 'contexts/reports/tab'
import {Form} from 'components/Form'
import {Modal} from 'components/Modal'
import {http} from 'utils/http'
import {translate} from 'utils/translations'

import {Form as ReportForm} from 'components/reports'

const ReportCreateOverlay = () => {
  const {refreshTabs} = React.useContext(ReportsContext)
  const {idx: tabIdx} = React.useContext(ReportTabContext)

  const handleFormSubmit = (values: CustomReport) => http.post<CustomReport>(`/api/report-tabs/${tabIdx}`, values)

  const history = useHistory()
  const handleClose = React.useCallback(() => {
    history.push(`/reports/${tabIdx}`)
  }, [history, tabIdx])

  const handleSuccess = React.useCallback(async () => {
    await refreshTabs()
    handleClose()
  }, [refreshTabs, handleClose])

  return (
    <Modal
      heading={translate('Add report')}
      onClose={handleClose}
      size="small"
    >
      <Form
        initialValues={{
          title: '',
          metric: '',
          groupBy: '',
          chartType: ''
        }}
        onSubmit={handleFormSubmit}
        onSuccess={handleSuccess}
      >
        <ReportForm/>
        <Modal.Footer className="flex justify-center">
          <Form.Submit>{translate('Create')}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ReportCreateOverlay
