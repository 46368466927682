import React, {FC} from 'react'
import {Icon} from '../Icon'

const Search: FC<{
    className?: string;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    onClear?: () => void;
    onKeyUp?: (event:any) => void;
}> = ({className, value, placeholder, onChange, onClear, onKeyUp}) => {

  const cssClassName = className + ' relative h-14 select-none'

  return (
    <div className={cssClassName}>
      <input
        type="search"
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            onClear && onClear()
          }
        }}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        className="w-full h-14 py-4 pr-4 pl-9 focus:outline-none border-b border-gray-100 focus:border-blue-700 appearance-none placeholder-gray-500"
      />

      <div className="absolute left-0 top-0 w-7 h-14 flex items-center justify-center">
        <Icon name="search" className="text-gray-500" size={6}/>
      </div>
    </div>
  )
}

const EnhancedSearch = React.memo(Search)

export default EnhancedSearch
