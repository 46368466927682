import {Document, Project} from '@contractool/schema'
import {parseISO, format} from 'date-fns'
import React, {FC, useContext} from 'react'
import {SidePanel} from './ProjectDetail'
import {http} from 'utils/http'
import {Route, Switch} from 'react-router'
import {Link} from 'react-router-dom'
import {Icon} from 'components/Icon'
import {AppContext} from 'contexts'
import {sign} from 'utils/auth'
import {translate} from 'utils/translations'
import {permissionRequest} from 'utils/wildcard'
import {AddFiles} from 'views/projects/AddFiles'
import {iconHandler} from 'views/projects/ProjectLogs/DocumentLog'
import {DocumentUpload} from './DocumentUpload'
import {ProjectDocumentDetail} from 'views/projects/ProjectDocumentDetail'
import {EventEmitter} from 'utils/eventEmitter'

/* returns main document as Attachment */
const mainDocument = (project: Project): Document | null => {
  const attachments = project.attachments.filter(
    (value) => project.document_id + '' === value.id + ''
  )
  if (attachments.length === 1) return attachments[0]

  return null
}
export const ContractPanel: FC<{
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
}> = ({project, onUpdate, onClose}) => {
  const {config} = useContext(AppContext)

  const refresh = () => {
    onClose()
    onUpdate()
  }

  if (!config.config) {
    return null
  }
  const onSend = (file: {path: string; name: string}) => {
    let currentMainDocument = mainDocument(project)
    const data = {...file, project_document: true}
    console.log('upload contract panel start')
    EventEmitter.dispatch('contract-start', {})
    if (currentMainDocument === null) {
      return http.post<Document>(project.attachments_url, {file: data})
    }

    return http.post<Document>(currentMainDocument.versions_url, {file: data})
  }

  let permissions = project.can

  return (
    <SidePanel
      heading={translate('Contract')}
      right={
        <>
          {config.config.office365 ? (
            <a
              href={project.new_contract_url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-700 mr-4"
            >
              <Icon name="word" size={5} />
              <span className="ml-2">{translate('Create online')}</span>
            </a>
          ) : null}

          {permissionRequest(permissions, 'project.widget.documents.edit') && (
            <Link
              to={`/projects/${project.id}/contract/browse`}
              className="flex items-center text-blue-700"
            >
              <Icon name="add" size={5} />
              <span className="ml-2">{translate('Add file')}</span>
            </Link>
          )}
        </>
      }
    >
      <ContractDocument project={project} />
      {permissionRequest(permissions, 'project.widget.documents.edit') ? (
        <DocumentUpload onUpload={() => {
          refresh()
          setTimeout(() => {
            EventEmitter.dispatch('contract-end', {})
          }, 1500)

        }} onSend={onSend} />
      ) : null}
      <Switch>
        <Route path={`/projects/${project.id}/documents/:documentId`}>
          <ProjectDocumentDetail onUpdate={refresh} onClose={onClose} project={project} />
        </Route>

        {permissionRequest(permissions, 'project.widget.documents.edit') && (
          <Route exact path={`/projects/${project.id}/contract/browse`}>
            <AddFiles
              type="main"
              onUpdate={refresh}
              onClose={onClose}
              project={project}
            />
          </Route>
        )}
      </Switch>
    </SidePanel>
  )
}

export const ContractDocument: FC<{project: Project}> = ({project}) => {
  const {config} = useContext(AppContext)

  if (project.attachments_url.length === 0) return null
  const projectDocument = mainDocument(project)
  if (projectDocument === null) return <></>
  let latestVersion = projectDocument.versions[projectDocument.versions.length - 1]
  let permissions = project.can
  let icon = iconHandler(projectDocument.title)

  return (
    <>
      <div className="border border-gray-200 p-6 mb-4 rounded-xl flex">
        {icon}
        <div className="pl-3">
          <Link to={`/projects/${project.id}/documents/${projectDocument.id}`}>
            <div className="leading-tighter">{projectDocument.title}</div>
            <div className="text-gray-600 text-sm pt-2">
              {translate('Version')} {projectDocument.versions.length} -{' '}
              {format(parseISO(latestVersion.created_at), 'dd. MMM')}
            </div>
          </Link>
        </div>
        <div className="flex-1 flex flex-row-reverse">
          {permissionRequest(permissions, 'project.widget.documents.view') && (
            <a
              href={sign(projectDocument.download_url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="get_app" size={6} className="ml-3 text-gray-500" />
            </a>
          )}
          {config.config.office365 &&
                        permissionRequest(permissions, 'project.widget.documents.edit') && (
            <a
              href={sign(projectDocument.edit_url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="edit" size={6} className="ml-3 text-gray-500" />
            </a>
          )}
          {permissionRequest(permissions, 'project.widget.documents.view') && (
            <a
              href={sign(projectDocument.preview_url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="remove_red_eye" size={6} className="ml-3 text-gray-500" />
            </a>
          )}
        </div>
      </div>
    </>
  )
}
