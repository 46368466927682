import * as React from 'react'

const pattern = /^[0-9]*$/

const inputAcceptsOnlyNumbersOf = (Component: React.ComponentType<any>) =>
  (props: any) => {
    const onChange = (v: string) => {
      if (pattern.test(v)) {
        props.onChange(v)
      }
    }

    return (
      <Component
        {...props}
        onChange={onChange}
      />
    )
  }

export default inputAcceptsOnlyNumbersOf
