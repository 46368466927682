import {ClauseResource} from 'resources'
import update from 'immutability-helper'


export default function templateClauseReducer(state:ClauseResource[], action:any){
  switch (action.type){
    case 'add':
      return [...state, action.payload]
    case 'add-in-position':
      return update(state, {
        $splice:[
          [action.payload.index,0,action.payload.clause]
        ]
      })
    case 'move' :
      return update(state, {
        $splice: [
          [action.payload.id, 1],
          [action.payload.afterId, 0, action.payload.clause]
        ]
      })
    case 'delete':
      return update(state, {
        $splice:[
          [action.payload.index, 1]
        ]
      })

    default:
      return state
  }
}
