import {useStatefulResource} from '@rest-hooks/legacy'
import React, {FC, createContext, useCallback, useContext, useMemo, useState} from 'react'
import {useHistory} from 'react-router'
import {Route} from 'react-router-dom'
import {useFetcher, useInvalidator} from 'rest-hooks'

import {translate} from 'utils/translations'
import {Icon} from 'components/Icon'
import {Menu as CommonMenu, MenuItem} from 'components/Menu'
import {Pagination} from 'components/Pagination'
import {renderedTinyMceContentStyle} from 'components/TinymcePluginMDF'
import {ClauseResource} from 'resources'
import TemplateClauseContext from 'views/template-clause/context'
import {SortDirection} from 'views/template-clause/types'

import ClauseCreateOverlay from './ClauseCreateOverlay'
import ClauseEditOverlay from './_id'

const SortIcon = ({
  direction
}: {
    direction: 'asc' | 'desc',
}) => (
  <Icon name={direction === 'asc' ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} size={5} />
)

const clausesSortableColumns = ['name', 'content'] as const
type ClausesSortableColumn = typeof clausesSortableColumns[number];

interface IResourceType {
    keyword: string,
    page: number,
    sortBy: ClausesSortableColumn,
    sortDirection: SortDirection
}

interface IContext {
    currentPage: number
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    clausesSortBy: ClausesSortableColumn
    setClausesSortBy: React.Dispatch<React.SetStateAction<ClausesSortableColumn>>
    clausesSortDirection: SortDirection
    setClausesSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>
    resourceParams: IResourceType
    refreshClauses: () => void
}

export const Context = createContext<IContext>({
  currentPage: null as unknown as number,
  setCurrentPage: null as unknown as React.Dispatch<React.SetStateAction<number>>,
  clausesSortBy: null as unknown as ClausesSortableColumn,
  setClausesSortBy: null as unknown as React.Dispatch<React.SetStateAction<ClausesSortableColumn>>,
  clausesSortDirection: null as unknown as SortDirection,
  setClausesSortDirection: null as unknown as React.Dispatch<React.SetStateAction<SortDirection>>,
  resourceParams: null as unknown as IResourceType,
  refreshClauses: null as unknown as () => void
})

const Provider: FC = ({children}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [clausesSortBy, setClausesSortBy] = useState<ClausesSortableColumn>(clausesSortableColumns[0])
  const [clausesSortDirection, setClausesSortDirection] = useState<SortDirection>('asc')

  const {searchString} = useContext(TemplateClauseContext)

  const resourceParams = useMemo(() => ({
    keyword: searchString,
    page: currentPage,
    sortBy: clausesSortBy,
    sortDirection: clausesSortDirection
  }), [
    searchString,
    currentPage,
    clausesSortBy,
    clausesSortDirection
  ])

  const invalidateClauses = useInvalidator(ClauseResource.list())
  const refreshClauses = useCallback(() => {
    invalidateClauses(resourceParams)
  }, [invalidateClauses, resourceParams])

  const value = useMemo(() => ({
    currentPage,
    setCurrentPage,
    clausesSortBy,
    setClausesSortBy,
    clausesSortDirection,
    setClausesSortDirection,
    resourceParams,
    refreshClauses
  }), [
    currentPage,
    setCurrentPage,
    clausesSortBy,
    setClausesSortBy,
    clausesSortDirection,
    setClausesSortDirection,
    resourceParams,
    refreshClauses
  ])

  return <Context.Provider value={value}>{children}</Context.Provider>
}


const Layout = () => {
  const history = useHistory()

  const {
    setCurrentPage,
    clausesSortBy,
    setClausesSortBy,
    clausesSortDirection,
    setClausesSortDirection,
    resourceParams,
    refreshClauses
  } = useContext(Context)

  const {data: {data: clauses, meta}} = useStatefulResource(ClauseResource.list(), resourceParams)

  const handleSort = useCallback((column: ClausesSortableColumn) => {
    setClausesSortBy(column)
    setClausesSortDirection(column !== clausesSortBy || clausesSortDirection === 'desc' ? 'asc' : 'desc')
    setCurrentPage(1)
  }, [clausesSortBy, clausesSortDirection, setCurrentPage, setClausesSortBy, setClausesSortDirection])

  const deleteClause = useFetcher(ClauseResource.delete())
  const handleRemove = useCallback(async (clause: ClauseResource) => {
    await deleteClause(clause)
    refreshClauses()
  }, [deleteClause, refreshClauses])

  const {
    newClauseVisible,
    newClauseToggle
  } = React.useContext(TemplateClauseContext)

  return clauses ? (
    <>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>
                <div className="flex items-center cursor-pointer" onClick={() => handleSort('name')}>
                  {translate('Name')}
                  {clausesSortBy === 'name' && clausesSortDirection && (
                    <SortIcon direction={clausesSortDirection} />
                  )}
                </div>
              </th>
              <th>
                <div className="flex items-center cursor-pointer" onClick={() => handleSort('content')}>
                  {translate('Content')}
                  {clausesSortBy === 'content' && clausesSortDirection && (
                    <SortIcon direction={clausesSortDirection} />
                  )}
                </div>
              </th>
              <th className="w-16"/>
            </tr>
          </thead>
          <tbody>
            {clauses.map((clause: ClauseResource) => (
              <tr className="align-text-top" key={clause.id}>
                <td>
                  {clause.name}
                </td>
                <td>
                  <div className="clause-content" dangerouslySetInnerHTML={{__html: clause.content}}/>
                </td>
                <td>
                  <CommonMenu handle="more_horiz">
                    <MenuItem
                      icon="edit"
                      onClick={() => history.push(`/template-clause/clauses/${clause.id}`)}
                    >
                      {translate('Edit')}
                    </MenuItem>
                    <MenuItem
                      icon="delete"
                      onClick={() => handleRemove(clause)}
                    >
                      {translate('Delete')}
                    </MenuItem>
                  </CommonMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="flex justify-center pt-16">
          <Pagination
            currentPage={meta.current_page}
            totalPages={meta.last_page}
            totalBlocks={5}
            onSelect={(page) => {
              setCurrentPage(page)
            }}
          />
        </div>

        <style>
          {renderedTinyMceContentStyle}
        </style>
      </div>
      {newClauseVisible && (
        <ClauseCreateOverlay onDismiss={newClauseToggle.off}/>
      )}
    </>
  ) : null
}

const View = () => (
  <Provider>
    <Route path="/template-clause/clauses">
      <Layout/>
      <Route path="/template-clause/clauses/:id">
        <ClauseEditOverlay/>
      </Route>
    </Route>
  </Provider>
)

export default View
