import {TemplateCategory} from '@contractool/schema'
import React, {useRef} from 'react'
import {ReactSortable} from 'react-sortablejs'

import {translate} from 'utils/translations'
import {Button} from 'components/Button'
import {Confirmation} from 'components/Confirmation'
import {Icon} from 'components/Icon'
import {Modal} from 'components/Modal'
import {TextInput} from 'components/TextInput'
import {useRequest} from 'hooks/useRequest'
import {http} from 'utils/http'
// import {useToasts} from 'hooks';

const TemplateCategoriesOverlay: React.FC<{
    onDismiss: () => void
}> = ({onDismiss}) => {
  const [templateCategories, {refresh: refreshTemplateCategories}] = useRequest<TemplateCategory[]>('/api/template-categories', [])

  const [draftTemplateCategory, setDraftTemplateCategory] = React.useState<TemplateCategory | null>(null)

  // todo: additionalCreationRef is hacking solution, we need to refactor it
  const additionalCreationRef = useRef(false)
  const handleCreateClick = React.useCallback(() => {
    setDraftTemplateCategory(draftTemplateCategory => {
      if (draftTemplateCategory) {
        additionalCreationRef.current = true

        return draftTemplateCategory
      }

      return {
        id: 0,
        name: '',
        order: templateCategories.length
      }
    })
  }, [templateCategories])

  const [sortableList, setSortableList] = React.useState(templateCategories)
  React.useEffect(() => {
    setSortableList(templateCategories)
    setDraftTemplateCategory(null)
  }, [templateCategories])

  React.useEffect(() => {
    if (!draftTemplateCategory && additionalCreationRef.current) {
      additionalCreationRef.current = false
      setDraftTemplateCategory({
        id: 0,
        name: '',
        order: templateCategories.length
      })
    }
  }, [draftTemplateCategory, templateCategories])

  const orderTemplateCategory = React.useCallback(async (payload: TemplateCategory[]) => {
    const {
      data: updatedTemplateCategory
    } = await http.post<TemplateCategory>('/api/template-categories-order', {
      payload
    })

    return updatedTemplateCategory
  }, [])

  const handleReorderList = React.useCallback(async (event: any) => {
    const payload = [...templateCategories]
    const movedTemplateCategory = payload.splice(event.oldIndex, 1)
    payload.splice(event.newIndex, 0, ...movedTemplateCategory)

    await orderTemplateCategory(payload)
    refreshTemplateCategories()
  }, [orderTemplateCategory, templateCategories, refreshTemplateCategories])

  const handleUpdate = React.useCallback(async (payload: TemplateCategory) => {
    await http.put<TemplateCategory>(`/api/template-categories/${payload.id}`, payload)
    refreshTemplateCategories()
  }, [refreshTemplateCategories])

  const handleCreate = React.useCallback(async (payload: TemplateCategory) => {
    await http.post<TemplateCategory>('/api/template-categories', payload)
    refreshTemplateCategories()
  }, [refreshTemplateCategories])

  const handleRemove = React.useCallback(async (id: number) => {
    await http.delete<TemplateCategory>(`/api/template-categories/${id}`)
    refreshTemplateCategories()
  }, [refreshTemplateCategories])

  const handleClose = React.useCallback(() => {
    onDismiss()
  }, [onDismiss])

  return (
    <Modal
      heading={translate('Template categories')}
      isOpen={true}
      onClose={handleClose}
      size="small"
      corner={
        <Button
          className="flex text-blue-600 items-center"
          color="white"
          onClick={handleCreateClick}
        >
          <Icon name="add" size={5} className="mr-2" />
          {translate('Create new')}
        </Button>
      }
      compact={false}
    >
      {templateCategories.length > 0 && (
        <ReactSortable
          tag="ul"
          list={sortableList}
          setList={setSortableList}
          onUpdate={handleReorderList}
          handle=".drag-handle"
        >
          {sortableList.map((sortableListItem: any) => (
            <li key={sortableListItem.id}>
              <TemplateCategoryCrud
                templateCategory={sortableListItem}
                onUpdate={handleUpdate}
                onRemove={handleRemove}
              />
            </li>
          ))}
        </ReactSortable>
      )}
      {draftTemplateCategory && (
        <TemplateCategoryCrud
          templateCategory={draftTemplateCategory}
          onCreate={handleCreate}
          mode="create"
        />
      )}
      <div className="mt-16 flex justify-end">
        <Button color="blue" onClick={handleClose}>
          {translate('Done')}
        </Button>
      </div>
    </Modal>
  )
}

const TemplateCategoryCrud: React.FC<{
    templateCategory: TemplateCategory
    onUpdate?: (payload: TemplateCategory) => void
    onRemove?: (id: number) => void
    onCreate?: (payload: TemplateCategory) => void
    mode?: string
}> = ({
  templateCategory,
  onUpdate,
  onRemove,
  onCreate,
  mode = 'edit'
}) => {
  // const {success} = useToasts();

  const handleUpdate = (payload: TemplateCategory) => {
    if (onUpdate) {
      onUpdate(payload)
    }
  }
  const handleRemove = () => {
    if (onRemove) {
      onRemove(templateCategory.id)
    }
  }
  const handleCreate = (payload: TemplateCategory) => {
    if (onCreate) {
      onCreate(payload)
    }
  }

  return (
    <div
      className="flex items-center text-gray-700"
    >
      <div className="drag-handle cursor-pointer mr-6">
        <Icon name="reorder" size={6} className="text-gray-500" />
      </div>
      <div className="flex-auto">
        <Name
          templateCategory={templateCategory}
          mode={mode}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
        />
      </div>
      <div className="flex w-6">
        {
          mode === 'edit' && (
            <Confirmation
              onConfirm={handleRemove}
              trigger={({onClick}) => (
                <div onClick={onClick} title={translate('Delete tab')}>
                  <Icon name="delete" size={6} className="text-gray-500 cursor-pointer" />
                </div>
              )}
              heading={translate('Remove template category')}
              buttonText={translate('Yes, remove')}
              color="red"
            >
              {translate('Are you sure you want to remove this template category? This action can\'t be undone.')}
            </Confirmation>
          )
        }
      </div>
    </div>
  )
}

export default TemplateCategoriesOverlay

const input = {
  className: 'leading-tighter'
}

const Name: React.FC<{
    templateCategory: TemplateCategory,
    onCreate: (payload: TemplateCategory) => void,
    onUpdate: (payload: TemplateCategory) => void,
    mode: string,
}> = ({templateCategory, onCreate, onUpdate, mode}) => {
  const {name} = templateCategory

  const handleNameInput = React.useCallback(async (updatedName) => {
    if (!updatedName) { return }
    const handleInput = mode === 'create' ? onCreate : onUpdate
    await handleInput({
      ...templateCategory,
      name: updatedName
    })
  }, [templateCategory, onCreate, onUpdate, mode])

  const [editing, setEditing] = React.useState<boolean>(mode === 'create')
  const [nameModel, setNameModel] = React.useState<string>(name)

  const handleClick = () => {
    setEditing(true)
  }

  const handleBlur = async () => {
    setEditing(false)
    await handleNameInput(nameModel)
  }

  return (
    editing ? (
      <TextInput
        autoFocus
        input={input}
        name="name"
        onBlur={handleBlur}
        onChange={setNameModel}
        value={nameModel}
      />
    ) : <div className="border-b border-gray-200 leading-tighter py-5" onClick={handleClick}>{nameModel}</div>
  )
}
