import React, {FC} from 'react'
import {Form} from 'components/Form'
import {SupplierContact} from '../../../../_common/schema'
import {Modal} from 'components/Modal'
import {Button} from 'components/Button'
import {TextInput} from 'components/TextInput'
import {inputAcceptsOnlyNumbersOf} from 'features/form/input'
import {translate} from 'utils/translations'

const NumberInput = inputAcceptsOnlyNumbersOf(TextInput)

export const SupplierContactModal: FC<{
    label: string;
    contact: SupplierContact;
    onCancel: () => void;
    onSuccess: (contact: SupplierContact) => void;
    onSubmit: (values: SupplierContact) => Promise<any>;
    isOpen?: boolean;
}> = ({label, contact, onSuccess, onCancel, onSubmit, isOpen}) => {

  return (
    <Modal
      heading={label}
      size="small"
      onClose={onCancel}
      portalClassName="supplier-contact"
      isOpen={isOpen}
    >
      <Form initialValues={contact} onSubmit={onSubmit} onSuccess={onSuccess}>
        <Form.TextInput
          name="name"
          label={translate('Name')}
          className="mb-6"
          required={true}
        />
        <Form.TextInput
          name="email"
          label={translate('Email')}
          className="mb-6"
          type="email"
          required={true}
        />
        <Form.Field
          name="phone"
          label={translate('Phone')}
          component={NumberInput}
          type="tel"
          pattern="[0-9]"
          required={false}
        />

        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={onCancel}>
            {translate('Cancel')}
          </Button>

          <Form.Submit>{label}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
