import React, {FC} from 'react'
import {Icon} from './Icon'

export const LEFT_BARRIER = null
export const RIGHT_BARRIER = null
const isBarrier = (page: number | null): page is null => page === null

export interface PaginationProps {
    currentPage: number;
    totalPages: number;
    totalRecords?: number;
}

export const Pagination: FC<
    PaginationProps & {
        totalBlocks: number;
        onSelect?: (page: number) => void;
    }
> = ({
  currentPage,
  totalPages,
  totalBlocks,

  onSelect = () => {}
}) => {
  const numbers = generate(currentPage, totalPages, totalBlocks)
  const [prevPage, nextPage] = [currentPage !== 1, currentPage !== totalPages]

  function select(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number): void {
    // blur the pressed pagination button
    // note: even though we blur the button, the "<TAB> position"
    // is still "focused" on this button. Pressing <TAB> goes
    // to the next "page button". this is probably not right?
    e.currentTarget.blur()

        // scroll to the top of `<main>`
        // note: `<main>` is hard-coded here; might cause problems later
        document.querySelector('main')?.scroll(0, 0)

        // emit the change
        onSelect(page)
  }

  return (
    <nav className="flex select-none" role="navigation">
      <button
        onClick={(e) => select(e, currentPage - 1)}
        disabled={!prevPage}
        className={`w-14 h-14 rounded flex justify-center items-center focus:outline-none focus:border-blue-700 focus:shadow-outline ${
          prevPage
            ? 'text-gray-600 hover:bg-gray-100 cursor-pointer'
            : 'text-gray-200 cursor-not-allowed'
        }`}
        aria-label="Go to previous page"
        aria-current={!prevPage}
      >
        <Icon name="arrow_back" size={5} />
      </button>

      {numbers.map((page, i) => {
        return (
          <div
            className="w-14 h-14 flex justify-center items-center"
            key={pageKey(page, i)}
          >
            {isBarrier(page) ? (
              <span className="text-gray-600">...</span>
            ) : (
              <button
                onClick={(e) => select(e, page)}
                className={`w-full h-full hover:bg-gray-100 rounded focus:outline-none focus:border-blue-700 focus:shadow-outline ${
                  page === currentPage
                    ? 'text-blue-700 cursor-not-allowed'
                    : 'text-gray-600 cursor-pointer'
                }`}
                aria-label={`Go to page ${page}`}
                aria-current={page === currentPage}
                disabled={page === currentPage}
              >
                {page}
              </button>
            )}
          </div>
        )
      })}

      <button
        onClick={(e) => select(e, currentPage + 1)}
        disabled={!nextPage}
        className={`w-14 h-14 rounded flex justify-center items-center focus:outline-none focus:border-blue-700 focus:shadow-outline ${
          nextPage
            ? 'text-gray-600 hover:bg-gray-100 cursor-pointer'
            : 'text-gray-200 cursor-not-allowed'
        }`}
        aria-label="Go to next page"
        aria-current={!nextPage}
      >
        <Icon name="arrow_forward" size={5} />
      </button>
    </nav>
  )
}

export function generate(
  currentPage: number,
  totalPages: number,
  totalBlocks: number
): (null | number)[] {
  const [min, max] = [1, totalPages]

  if (totalBlocks < 5) {
    throw new Error('[totalBlocks] must be at least 5')
  }

  if (totalBlocks % 2 === 0) {
    throw new Error('[totalBlocks] must not be a multiple of 2')
  }

  const remaining = totalBlocks - 2
  const leftOffset = currentPage - min - 1
  const rightOffset = max - 1 - currentPage
  const [leftGap, rightGap] = gaps(leftOffset, rightOffset, remaining)

  if (leftGap && rightGap) {
    return [
      min,
      LEFT_BARRIER,
      ...range(
        currentPage - Math.floor(remaining / 2) + 1,
        currentPage + Math.ceil(remaining / 2) - 2
      ),
      RIGHT_BARRIER,
      max
    ]
  }

  if (leftGap && !rightGap) {
    return [min, LEFT_BARRIER, ...range(totalPages - remaining + 1, totalPages)]
  }

  if (!leftGap && rightGap) {
    return [...range(1, remaining), RIGHT_BARRIER, max]
  }

  return range(1, totalPages)
}

export function range(from: number, to: number): number[] {
  return Array.from(Array(to - from + 1).keys()).map((i) => i + from)
}

function gaps(leftOffset: number, rightOffset: number, remaining: number): [boolean, boolean] {
  let leftGap = leftOffset > remaining
  let rightGap = rightOffset > remaining

  if (leftGap) {
    rightGap = rightOffset >= remaining - 2
  }

  if (rightGap) {
    leftGap = leftOffset >= remaining - 2
  }

  return [leftGap, rightGap]
}

function pageKey(page: number | null, index: number): number {
  // barriers get special keys
  if (page === null) {
    return index === 1 ? -1 : -2
  }

  return page
}
