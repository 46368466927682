import React, {FC, useContext} from 'react'
import {FormContext} from 'components/Form'
import {translate} from 'utils/translations'

const ProjectTitle: FC<{className?: string}> = ({className}) => {
  const {values} = useContext(FormContext)

  return (
    <div className={`${className}`}>
      <div className="text-gray-600 mb-1">{translate('Title')}</div>
      <div className="flex justify-between items-center bg-gray-100 rounded-lg py-3 pl-3 pr-6">
        <span className={`${values.title !== '' ? 'text-blue-500' : 'text-gray-500'}`}>
          {values.title !== ''
            ? values.title
            : translate('Title is automatically generated')}
        </span>
      </div>
    </div>
  )
}

export default ProjectTitle
