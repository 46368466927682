import React, {useCallback, useState} from 'react'
import {Modal} from 'components/Modal'
import {translate} from 'utils/translations'
import {useHistory} from 'react-router'
import {useToasts} from 'hooks'
import {http} from 'utils/http'
import {Form} from 'components/Form'
import DocumentForm from './Form'
import {Button} from 'components/Button'
import {Document} from '@contractool/schema'
import {AiCollection} from '../../../../../_common/schema/AiCollection'

export interface AiDocument {
  id: string;
  document_id: number;
  answers: any;
  collections?: AiCollection[];
  document: Document
}

const NewDocument = ({
  onCreate,
  closeUrl
}: {
  onCreate: (document: AiDocument) => void;
  closeUrl: string;
}) => {
  const [documents, setDocuments] = useState<number[]>([])
  const history = useHistory()
  const close = useCallback(() => {
    history.push(closeUrl)
  }, [history, closeUrl])

  const handleSubmit = useCallback((values) => {
    return http.post<AiDocument>('/api/ai/documents', {
      ...values,
      documents
    })

  }, [documents])

  const {success} = useToasts()
  const handleSuccess = useCallback(
    (document) => {
      onCreate(document)
      success(
        `${translate('Ai Document was added successfully')}`
      )
      close()
    },
    [onCreate, close, success]
  )

  return (
    <Modal heading={translate('Add Document')} onClose={close}>
      <Form
        initialValues={{
        }}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
      >
        <DocumentForm documents={documents} setDocuments={setDocuments} />
        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={close}>
            {translate('Cancel')}
          </Button>

          <Form.Submit>{translate('Add document')}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default NewDocument
