import {RedFlag, Role, User, UserGroup} from '@contractool/schema'
import React, {FC, useContext} from 'react'
import ReactDOM from 'react-dom'
import {useHistory} from 'react-router'

import {Button} from 'components/Button'
import {AppContext} from 'contexts'
import {useRequest} from 'hooks/useRequest'
import {translate} from 'utils/translations'

interface IListProps {
    redFlags: RedFlag[]
}

const List: FC<IListProps> = ({redFlags}) => {
  const history = useHistory()

  const [users] = useRequest<User[]>('/api/users', [], {
    params: {id: redFlags.map((redFlag) => redFlag.userId)}
  })
  const {config} = useContext(AppContext)
  const buttonContainer = document.getElementById('page-header-right')

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Risk')}</th>
            <th>{translate('Project Role')}</th>
            <th>{translate('Group')}</th>
            <th>{translate('User')}</th>
          </tr>
        </thead>
        <tbody>
          {redFlags.map((redFlag) => (
            <tr
              key={redFlag.id}
              className="hover:bg-gray-000 cursor-pointer rounded"
              onClick={() => history.push(`/settings/red-flags/${redFlag.id}/edit`)}
            >
              <td>{redFlag.input}</td>
              <td className="text-gray-600">{redFlag.risk}</td>
              <td>{config.roles.find((role: Role) => redFlag.roleKey === role.key)?.label}</td>
              <td className="text-gray-600">{config.user_groups.find((group: UserGroup) => redFlag.groupKey === group.key)?.label}</td>
              <td>{users.find(user => redFlag.userId === user.id)?.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {buttonContainer && ReactDOM.createPortal((
        <Button.Link to="/settings/red-flags/new" color="blue" icon="add">
          {translate('Add red flag')}
        </Button.Link>
      ), buttonContainer)}
    </>
  )
}

export default List
