import React, {FC} from 'react'

import {Form as CommonForm} from 'components/Form'
import {translate} from 'utils/translations'

interface IEmailTemplateFormProps {
}

const Form: FC<IEmailTemplateFormProps> = () => {

  return (
    <>
      <div className="flex mb-6 -mx-2">
        <div className="w-2/3 mx-2">
          <CommonForm.TextInput
            name="name"
            placeholder={translate('Name')}
            label="Name"
            autoFocus
          />
        </div>
      </div>
      <div className="flex mb-6 -mx-2">
        <div className="w-2/3 mx-2">
          <CommonForm.TextInput
            name="tag"
            label="Tag"
            placeholder={translate('Tag')}
          />
        </div>
      </div>
      <div className="flex mb-6 -mx-2">
        <div className="w-2/3 mx-2">
          <CommonForm.TextInput
            name="subject"
            label="Subject"
            placeholder={translate('Subject')}
          />
        </div>
      </div>
      <div className="flex mb-6 -mx-2">
        <div className="w-2/3 mx-2">
          <CommonForm.TextArea
            name="body"
            label="Body"
            placeholder={translate('Body')}
          />
        </div>
      </div>
    </>
  )
}

export default Form
