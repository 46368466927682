import {TeamMember} from '@contractool/schema'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {Route, Switch} from 'react-router'

import {ProjectTeamModal} from './ProjectTeamModal'
import {Avatar} from 'components/Avatar'
import {Button} from 'components/Button'
import ProjectContext from './ProjectContext'

export const ProjectTeam: FC<{
    onUpdate: () => void;
    onClose: () => void;
}> = ({onUpdate, onClose}) => {
  const {resource: project, teamMembers} = React.useContext(ProjectContext)

  return (
    <>
      <Link className="flex relative w-1/2 text-right" to={`/projects/${project.id}/team`}>
        {teamMembers.length === 0 && <Button
          color="white"
          className="absolute self-center"
        >Team</Button>}
        {teamMembers.sort((a: TeamMember, b: TeamMember) => {
          let aId = project.team.assignee?.id
          if (a.user_id !== aId && b.user_id !== aId) {
            return 0
          }
          
          return a.user_id === aId ? 1 : -1
        }).map((member, index, arr) => (
          <div
            key={member.id}
            className="absolute self-center"
            style={{right: 27 * index}}
            title={member.user.name}
          >
            <Avatar
              user={member.user}
              className={`${
                arr.length === index + 1 ? 'w-13 h-13' : 'w-11 h-11'
              } border-solid border-2 border-white`}
            />
          </div>
        ))}
      </Link>
      <Switch>
        <Route
          path={'/projects/:projectId/team'}
          render={() => (
            <ProjectTeamModal
              onUpdate={onUpdate}
              onClose={onClose}
            />
          )}
        />
      </Switch>
    </>
  )
}
