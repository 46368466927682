import * as React from 'react'

import {Dropdown} from 'components/Dropdown'
// import {Select} from 'components/select';

interface IYearSelectProps {
    value: number | null;
    onChange: (year: number | null) => void;
    placeholder?: string;
    years?: number[]
    max?: number
}

const currentYear = (new Date()).getFullYear()

const YearSelect: React.FC<IYearSelectProps> = ({value, onChange, placeholder, years, max = 10}) => {
  const options = React.useMemo(() => {
    let year = currentYear
    if (years) {
      return years.filter(v => v < currentYear + max).map((year) => ({
        value: year,
        label: String(year)
      }))
    } else {
      const options = []
      while (year < currentYear + max) {
        options.push({
          value: year,
          label: String(year)
        })
        year++
      }

      return options
    }

  }, [years, max])

  return (
    <Dropdown
      name={String(Math.random())}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      autocomplete={true}
    />
  )
}

export default YearSelect
