import {Translations} from '@contractool/schema'
import {http} from './http'

let map: Translations = null as unknown as Translations
let fetching = false

const substitute = (substitutions: {[name: string]: string}) =>
  Object.entries(substitutions)
    .map(([key, value]) => (input: string) =>
      input.replace(new RegExp(`(\\W|^):${key}(\\W|$)`, 'g'), `$1${value}$2`)
    )
    .reduce(
      (xs, x) => (input) => x(xs(input)),
      (x) => x
    )

export const init = async () => {
  fetching = true
  const response = await http.get<Translations>('api/translations')
  map = (response ?? {}).data
  fetching = false
}

export const translate = (input: string, substitutions: {[name: string]: string} = {}) =>
  substitute(substitutions)((map && map[input]) ?? input)

export const getFetching = () => fetching
export const getReadiness = () => map !== null
