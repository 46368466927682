import {State} from './types'
import {SET_PARAM} from './actionTypes'

const initialState = {
  params: {
    team: {
      assignee: []
    }
  }
}


export default function(state:State = initialState, action: {type: string, payload: any}) {
  switch (action.type) {
    case SET_PARAM: {
      return {
        ...state,
        params: {
          ...state.params,
          [action.payload.key]: action.payload.value
        }
      }
    }
    default:
      return state
  }
}
