import {Template, TemplateCategory} from '@contractool/schema'
import React, {useCallback} from 'react'

import {translate} from 'utils/translations'
import {Button} from 'components/Button'
import {Form} from 'components/Form'
import {Modal} from 'components/Modal'
import {useRequest} from 'hooks/useRequest'

const changeKeys = {
  key1: 'id',
  key2: 'name'
}

const TemplateOverlay: React.FC<{
    cta: string;
    heading: string;
    initialValues: Template;
    onDismiss: () => void;
    onSubmit: (template: Template) => void;
}> = ({cta, heading, initialValues, onDismiss, onSubmit}) => {
  const [templateCategories] = useRequest<TemplateCategory[]>('/api/template-categories', [])
  const templateCategoryOptions = React.useMemo(() => (
    templateCategories.map(templateCategory => ({
      label: templateCategory.name,
      value: templateCategory.id
    }))
  ), [templateCategories])

  const handleSubmit = useCallback((template: Template) => Promise.resolve({data: template}), [])

  return (
    <Modal
      heading={heading}
      isOpen={true}
      onClose={onDismiss}
      compact={false}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={onSubmit}
      >
        <Form.TextInput
          name="name"
          label={translate('Name')}
          className="mb-6"
          autoFocus={true}
        />
        <Form.Multiselect
          className="mb-6"
          name="categories"
          autocomplete={true}
          label={translate('Categories')}
          options={templateCategoryOptions}
          placeholder="Select categories"
          changeKeys={changeKeys}
        />
        <div className="flex justify-between mt-16">
          <Button
            color="white"
            onClick={onDismiss}
          >
            {translate('Cancel')}
          </Button>
          <Form.Submit>
            {cta}
          </Form.Submit>
        </div>
      </Form>
    </Modal>
  )
}

export default TemplateOverlay
