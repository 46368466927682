import React from 'react'
import {Icon} from 'components/Icon'
import {translate} from 'utils/translations'
import {AiCollection} from '../../../../../_common/schema/AiCollection'
import {Menu, MenuItem} from 'components/Menu'
import {Confirmation} from 'components/Confirmation'
import {http} from 'utils/http'
import {useHistory} from 'react-router'

const SortIcon = ({
  direction
}: {
      direction: 'asc' | 'desc',
  }) => (
  <Icon name={direction === 'asc' ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} size={5}/>
)

const CollectionsList = ({
  sortDirection,
  sortBy,
  collections,
  onSort,
  onDelete
}: {
    sortBy?: string;
    sortDirection?: 'asc' | 'desc';
    collections: AiCollection[];
    onSort?: (column: string, direction: 'asc' | 'desc') => void;
    onDelete: () => void;
}) => {
  const history = useHistory()
  const handleSort = (column: string) => {
    if (onSort) {
      onSort(column, column !== sortBy || sortDirection === 'desc' ? 'asc' : 'desc')
    }
  }

  const deleteCollection = (id: string) => {
    http.delete(`/api/ai/collections/${id}`).then(() => {
      onDelete()
    })
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>
              <div className="flex items-center cursor-pointer" onClick={() => handleSort('name')}>
                {translate('Name')}
                {sortBy === 'name' && sortDirection && (
                  <SortIcon direction={sortDirection}/>
                )}
              </div>
            </th>
            <th>
              <div className="flex items-center cursor-pointer" onClick={() => handleSort('description')}>
                {translate('Description')}
                {sortBy === 'description' && sortDirection && (
                  <SortIcon direction={sortDirection}/>
                )}
              </div>
            </th>
            <th className="w-16"></th>
          </tr>
        </thead>
        <tbody>
          {
            collections && collections.length ?
              collections.map((collection, index) => {
                return (
                  <tr
                    key={index}
                    className="hover:bg-gray-000 cursor-pointer rounded align-middle table-row border-b-1 border-gray-100"
                  >
                    <td
                      className="px-6 py-7"
                    >
                      {collection.name}
                    </td>
                    <td
                      className="px-6 py-7"
                    >
                      <div dangerouslySetInnerHTML={{__html: collection.description}} />
                    </td>
                    <td>
                      <Menu handle="more_horiz">
                        <MenuItem
                          icon="file-archive"
                          onClick={() => {
                            //history.push(`/ai/collections/edit/${collection.id}`)
                            window.open(collection.download_url, '_blank')
                          }}
                          nowrap
                        >
                          {translate('Download')}
                        </MenuItem>
                        <MenuItem
                          icon="edit"
                          onClick={() => {
                            history.push(`/ai/collections/edit/${collection.id}`)
                          }}
                          nowrap
                        >
                          {translate('Edit')}
                        </MenuItem>
                        <Confirmation
                          onConfirm={() => {
                            deleteCollection(collection.id)
                          }}
                          trigger={({onClick}) => (
                            <MenuItem
                              icon="delete"
                              onClick={onClick}
                              nowrap
                            >
                              {translate('Delete')}
                            </MenuItem>
                          )}
                          heading={translate('Delete Collection')}
                          buttonText={translate('Yes, delete')}
                          color="red"
                        >
                          {translate(`Are you sure you want to delete ${collection.name} collection?. This action can't be undone.`)}
                        </Confirmation>
                      </Menu>
                    </td>
                  </tr>
                )

              })
              : (<tr>
                <td colSpan={8} className="text-center">
                  {translate('No Collections found')}
                </td>
              </tr>)
          }
          {/* TODO: Map collections
          <td>
            <Icon name="more_horiz" className="text-gray-600" size={6} />
          </td>
          */}
        </tbody>
      </table>
    </>
  )
}

export default CollectionsList
