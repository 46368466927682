import React, {FC, useState} from 'react'
import {Form} from '../../components/Form'
import {http} from '../../utils/http'
import {translate} from 'utils/translations'
import {Link} from 'react-router-dom'
import {Layout} from './Layout'
import {Button} from '../../components/Button'
import {Icon} from '../../components/Icon'
import {Message} from '../../components/Message'

export const ForgotPasswordPage: FC = () => {
  const [message, setMessage] = useState<string | null>(null)

  return (
    <Layout>
      <div className="mb-4">
        <Link to="/login" className="flex items-center text-gray-600 hover:text-gray-700">
          <Icon name="keyboard_arrow_left" size={6} className="mr-2" />
          <p className="leading-tight">{translate('Back to sign in')}</p>
        </Link>
      </div>

      <h1 className="text-3xl leading-tight font-semibold select-none text-gray-800">
        {translate('Reset password')}
      </h1>

      <p className="mt-6 mb-30 text-gray-600">
        {translate(
          'Type below the email you use for signing in to the application and we will send you a link to reset your password.'
        )}
      </p>

      {message ? (
        <>
          <Message.Success>{message}</Message.Success>

          <Button.Link to="/login" color="white" className="mt-30 w-full">
            {translate('Back to sign in')}
          </Button.Link>
        </>
      ) : (
        <Form
          initialValues={{email: ''}}
          onSubmit={(values) => http.post('/api/auth/password/forgotten', values)}
          onSuccess={(response: string) => setMessage(translate(response))}
          allowPristineSubmission={true}
          guard={false}
        >
          <Form.TextInput name="email" label="Email" placeholder="your@email.com" />
          <Form.Submit className="w-full mt-30">
            {translate('Reset password')}
          </Form.Submit>
        </Form>
      )}
    </Layout>
  )
}
