import {Clause} from '@contractool/schema'
import {useStatefulResource} from '@rest-hooks/legacy'
import React, {useContext} from 'react'
import {useParams, useHistory} from 'react-router'
import {useFetcher} from 'rest-hooks'

import {ClauseOverlay} from 'components/template-clause/clause'
import {useToasts} from 'hooks'
import {ClauseResource} from 'resources'
import {translate} from 'utils/translations'
import {Context as ClausesContext} from 'views/template-clause/tab/clauses/index'

const ClauseEditOverlay: React.FC = () => {
  const {success} = useToasts()
  const history = useHistory()
  const {id} = useParams() as {id: string}

  const updateClause = useFetcher(ClauseResource.update())
  const {data: clause} = useStatefulResource(ClauseResource.detail(), {id})

  const handleDismiss = () => {
    history.push('/template-clause/clauses')
  }

  const {refreshClauses} = useContext(ClausesContext)

  return clause ? (
    <ClauseOverlay
      cta={translate('Save clause')}
      heading={translate('Edit clause')}
      initialValues={clause as Clause}
      onDismiss={handleDismiss}
      onSubmit={async (clause) => {
        await updateClause({id: clause.id}, clause)
        handleDismiss()
        success(translate('Template is successfully updated.'))
        await refreshClauses()
      }}
    />
  ) : null
}

export default ClauseEditOverlay
