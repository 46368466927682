import * as React from 'react'
import {Route, Switch} from 'react-router-dom'

import Templates from './templates'
import Clauses from './clauses'

const Routes = () => (
  <Switch>
    <Route path="/template-clause/templates">
      <Templates/>
    </Route>
    <Route path="/template-clause/clauses">
      <Clauses/>
    </Route>
  </Switch>
)

export default Routes
