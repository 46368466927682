import {createContext} from 'react'

import {IMetaDataField} from 'components/TinymcePluginMDF'
import {ToggleType} from 'hooks/useToggle'

interface IContext {
    searchString: string
    updateSearchString: (query: string) => void
    metaDataFieldsLoaded: boolean
    metaDataFields: IMetaDataField[]
    newTemplateVisible: boolean
    newTemplateToggle: ToggleType
    newClauseVisible: boolean
    newClauseToggle: ToggleType
}

const Context = createContext<IContext>({
  searchString: '',
  updateSearchString: () => {},
  metaDataFieldsLoaded: false,
  metaDataFields: [],
  newTemplateVisible: false,
  newTemplateToggle: null as unknown as ToggleType,
  newClauseVisible: false,
  newClauseToggle: null as unknown as ToggleType
})

export default Context
