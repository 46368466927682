import React, {FC} from 'react'
import {ActivityBody, ActivityContainer, ActivityHeader} from '../ActivityPanel'
import {LogFull} from '@contractool/schema'
import {Icon} from 'components/Icon'

export const EmailLog: FC<{
    desc: string;
    log: LogFull;
}> = ({desc, log}) => {

  return (
    <ActivityContainer>
      <ActivityHeader
        causer={log.causer}
        desc={desc}
        created_at={log.created_at}
        timezone={log.causer.timezone}
      />
      <ActivityBody>
        <div className="text-gray-700">
          {log.data?.subject}
        </div>
        <pre className="text-gray-600 text-sm leading-8 my-6">
          {log.data?.body}
        </pre>
      </ActivityBody>
      {log.data?.attachments?.map((attachment: any) => (
        <ActivityBody className="bg-gray-100 rounded-lg p-5 inline-block">
          <div className="flex items-center">
            <span className="mr-4"><Icon name="word" size={6} className="text-blue-600" /></span>
            {attachment.title}
          </div>
        </ActivityBody>
      ))}
      
    </ActivityContainer>
  )
}
