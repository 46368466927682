export const renderedTinyMceContentStyle = `
[data-mdf] {
  border: 1px solid #007bff;
  padding: 0px 6px;
}
`

const addIframeStyle = (iframeClass: string, css: string) => {
  const addStyleToIframe = (iframe: HTMLIFrameElement) => {
    var style = document.createElement('style')
    style.textContent = css
    iframe.contentDocument && iframe.contentDocument.head.appendChild(style)
  }
  const styleAddedToIframe = () => {
    var iframe = document.getElementsByClassName(iframeClass)[0] as HTMLIFrameElement
    if (iframe !== undefined) {
      addStyleToIframe(iframe)

      return true
    }

    return false
  }
  const interval = setInterval(() => {
    if (styleAddedToIframe()) {
      clearInterval(interval)
    }
  }, 100)
}

export interface IMetaDataField {
    value: string;
    text: string
}

export const MetaDataFieldInit = (
  tinymce: any,
  editor: any,
  metaDataFields: IMetaDataField[],
  editable?: boolean,
  noneEditableClassName?: string
) => {
  if (tinymce === undefined) {
    console.log('tinymce is not defined')
  }
  if (editor === undefined) {
    console.log('editor is not defined')
  }
  tinymce.PluginManager.add('mdf', function(editor: any) {
    addIframeStyle('tox-edit-area__iframe', renderedTinyMceContentStyle)
    let filteredFields = [...metaDataFields]
    const searchName = 'search'
    var searchValue = ''

    const onChange = function(dialogApi: any, changeData: any) {
      if (changeData.name === searchName) {
        console.log(dialogApi, changeData)
        var str = dialogApi.getData().search
        searchValue = str
        let regex = new RegExp(str, 'i')
        filteredFields = metaDataFields.filter(item => {
          if (item.text.match(regex)) return true
          if (item.value.match(regex)) return true

          return false
        })

        dialogApi.redial(getConfiguration())
        dialogApi.focus(searchName)
      }
    }
    const onSubmit = function(api: any) {
      var data = api.getData()

      // Insert content when the window form is submitted
      let field = metaDataFields.find(item => item.value === data.mdf)
      if (field === undefined) {
        console.log(`Meta data field '${data.mdf}' was not found.`)
      }
      field && editor.insertContent(
        `&nbsp;<span ${editable ? '' : `class=${noneEditableClassName}`} data-mdf="${field.value}">${field.text}</span>&nbsp;`
      )
      api.close()
    }

    const getConfiguration = () => {
      return {
        title: 'Select Meta data field',
        body: {
          type: 'panel',
          items: [
            {
              label: 'Search',
              type: 'input',
              name: searchName
            },
            {
              type: 'selectbox', // component type
              name: 'mdf', // identifier
              disabled: false, // disabled state
              size: 5, // number of visible values (optional)
              items: filteredFields
            }
          ]
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close'
          },
          {
            type: 'submit',
            text: 'Insert',
            primary: true
          }
        ],
        initialData: {
          search: searchValue
          // mdf: filteredFields,
        },
        onChange,
        onSubmit
      }
    }

    var openDialog = function() {
      //https://www.tiny.cloud/docs/ui-components/dialogcomponents/
      return editor.windowManager.open(getConfiguration())
    }

    // Add a button that opens a window
    editor.ui.registry.addButton('mdf', {
      text: 'Meta data field',
      onAction: function() {
        // Open window
        openDialog()
      }
    })

    // Adds a menu item, which can then be included in any menu via the menu/menubar configuration
    editor.ui.registry.addMenuItem('mdf', {
      text: 'Meta data field',
      onAction: function() {
        // Open window
        openDialog()
      }
    })

    return {
      getMetadata: function() {
        return {
          name: 'Meta data field plugin',
          url: 'https://contractool.com'
        }
      }
    }
  })
}
