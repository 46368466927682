import {Template} from '@contractool/schema'
import {useStatefulResource} from '@rest-hooks/legacy'
import React, {useContext} from 'react'
import {useParams, useHistory} from 'react-router'
import {useFetcher} from 'rest-hooks'

import {TemplateOverlay} from 'components/template-clause/template'
import {useToasts} from 'hooks'
import {TemplateResource} from 'resources'
import {translate} from 'utils/translations'
import {Context as TemplatesContext} from 'views/template-clause/tab/templates/index'

const TemplateEditOverlay: React.FC = () => {
  const {success} = useToasts()
  const history = useHistory()
  const {id} = useParams() as {id: string}

  const updateTemplate = useFetcher(TemplateResource.update())
  const {data: template} = useStatefulResource(TemplateResource.detail(), {id})

  const {
    refreshTemplates
  } = useContext(TemplatesContext)

  const handleDismiss = () => {
    history.push('/template-clause/templates')
  }

  return template ? (
    <TemplateOverlay
      cta={translate('Save template')}
      heading={translate('Edit template')}
      initialValues={template as Template}
      onDismiss={handleDismiss}
      onSubmit={async (template) => {
        await updateTemplate({id: template.id}, template)
        handleDismiss()
        success(translate('Template is successfully updated.'))
        await refreshTemplates()
      }}
    />
  ) : null
}

export default TemplateEditOverlay
