import React, {useContext, FC} from 'react'
import {Project} from '@contractool/schema'
import {Icon} from 'components/Icon'
import {AppContext} from 'contexts'
import ExecutedSign from './Widgets/ExecutedSign'

type tRisk = 'High' | 'Medium' | 'Small'

const riskTextColorMap = {
  'High': 'text-red-300',
  'Medium': 'text-orange-300',
  'Small': 'text-gray-000'
}

const ExecutedInfos: FC<{project: Project}> = ({project}) => {
  const {config} = useContext(AppContext)

  const formattedDateString = (dateInput: any) => {
    if (typeof dateInput === 'string' &&
      dateInput.match(/-\d+-/) &&
      new Date(dateInput) instanceof Date &&
      !isNaN(new Date(dateInput).valueOf())
    ) {
      const date = new Date(dateInput)
      const {
        date_format: {skeleton, day, month, year}
      } = config

      return skeleton
        .replace('day', date.toLocaleDateString('default', {day}))
        .replace('month', date.toLocaleDateString('default', {month}))
        .replace('year', date.toLocaleDateString('default', {year}))
    } else {
      return null
    }
  }

  const risk = project.fields.risk_profile as tRisk
  const autoRenewal = project.fields.auto_renewal

  return (
    <div className={`grid gap-4 mt-16 ${
      risk ?
        'grid-cols-5' :
        'grid-cols-4'
    }`}>
      <ExecutedSign title="State">
        <Icon name="dot" size={4} className="mr-3 text-teal-700" />
        <div className="align-middle">Active</div>
      </ExecutedSign>
      <ExecutedSign title="Auto-renewal">
        {autoRenewal ? (
          <div className="flex items-center">
            <Icon size={5} name="sync" className="mr-3 text-teal-700" />
            {formattedDateString(autoRenewal)}
          </div>
        ) : (
          <div className="flex items-center">
            <Icon size={5} name="remove_circle" className="mr-3 text-red-700" />
            { 'Not set.' }
          </div>
        )}
      </ExecutedSign>
      <ExecutedSign title="Effective date">
        <Icon name="calendar_today" size={4} className="mr-3 text-gray-500" />
        <div className="align-middle">{formattedDateString(project.finished_at)}</div>
      </ExecutedSign>
      <ExecutedSign title="Template match">
        <Icon name="dot" size={4} className="mr-3 text-lime-500" />
        <div className="align-middle">{project.fields.template_match}%</div>
      </ExecutedSign>
      {risk ? (
        <ExecutedSign title="Risk">
          <Icon name="dot" size={4} className={`mr-3 ${riskTextColorMap[risk] ?? 'text-white'}`} />
          <h3 className='font-bolder'>{risk}</h3>
        </ExecutedSign>
      ) : null}
    </div>
  )
}

export default ExecutedInfos
