import React, {useState, FC, useContext} from 'react'
import {Popover} from 'react-tiny-popover'

import {AppContext} from 'contexts'
import {Dropdown, RenderInputProps} from 'components/Dropdown'
import {getOptions} from 'utils/form'
import {EventEmitter} from 'utils/eventEmitter'
import {translate} from 'utils/translations'
import {Icon} from 'components/Icon'
import {DatePicker} from 'components/DatePicker'
import {
  MultiselectDropdown,
  RenderMultiselectProps,
  SelectedItems
} from 'components/MultiselectDropdown'
import {Filters} from 'integrations/dell_ji/components/views/reports/interfaces'
import './ReportDateRange.css'

const ReportDateRange: FC<{filters: Filters}> = ({filters}) => {
  const {config} = useContext(AppContext)

  const monthOptions = getOptions([
    '2020-12',
    '2020-11',
    '2020-10',
    '2020-09',
    '2020-08',
    '2020-07',
    '2020-06',
    '2020-05',
    '2020-04',
    '2020-03',
    '2020-02',
    '2020-01'
  ])
  const quarterOptions = getOptions([
    'FY21Q4',
    'FY21Q3',
    'FY21Q2',
    'FY21Q1',
    'FY20Q4',
    'FY20Q3',
    'FY20Q2',
    'FY20Q1',
    'FY19Q4',
    'FY19Q3',
    'FY19Q2',
    'FY19Q1'
  ])
  let yearOptions
  if (config.integration === 'dell_ji') {
    yearOptions = getOptions(['FY22', 'FY21', 'FY20', 'FY19', 'FY18'])
  } else {
    yearOptions = getOptions(['2022', '2021', '2020', '2019', '2018'])
  }
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  if (!config.config) {
    return null
  }

  return (
    <>
      <div className="flex items-center">
        <div className="m-3">{translate('Show this')}:</div>
        <div className="m-3 flex rounded-full border border-gray-300 bg-gray-000 report-date-range justify-between">
          {config.integration !== 'dell_ji' && (
            <div className="w-25">
              <Dropdown
                renderInput={dropdownRender}
                name={`report-month${filters.month.length > 0 ? '-active' : ''}`}
                value={filters.month}
                placeholder={translate('Month')}
                options={monthOptions}
                onChange={(value) => {
                  EventEmitter.dispatch('set-date-filter', {
                    year: '',
                    month: value + '',
                    quarter: [],
                    dateFrom: '',
                    dateTo: ''
                  })
                }}
              />
            </div>
          )}
          <div>
            <MultiselectDropdown
              name={`report-quarter${filters.quarter.length > 0 ? '-active' : ''}`}
              values={filters.quarter}
              placeholder={translate('Quarter')}
              options={quarterOptions}
              onChange={(value) => {
                EventEmitter.dispatch('set-date-filter', {
                  year: '',
                  month: '',
                  quarter: value.map((q) => q.value),
                  dateFrom: '',
                  dateTo: ''
                })
              }}
              renderInput={RenderMultiselect}
              useSearch={true}
            />
          </div>
          <div className="w-25">
            <Dropdown
              renderInput={dropdownRender}
              name={`report-year${filters.year.length > 0 ? '-active' : ''}`}
              placeholder={translate('Year')}
              value={filters.year}
              options={yearOptions}
              onChange={(value) => {
                EventEmitter.dispatch('set-date-filter', {
                  year: value + '',
                  month: '',
                  quarter: [],
                  dateFrom: '',
                  dateTo: ''
                })
              }}
            />
          </div>
        </div>

        {config.integration !== 'dell_ji' && (
          <Popover
            isOpen={isPopoverOpen}
            positions={['bottom', 'left']} // preferred positions by priority
            onClickOutside={(_data) => {
              // console.log(data);
              // setIsPopoverOpen(false)
            }}
            content={
              <div className="p-5 rounded flex bg-white" style={{width: '300px'}}>
                <DatePicker
                  name={'report-from-date'}
                  value={filters.dateFrom || null}
                  placement="bottom-end"
                  onChange={(date) => {
                    if (!date) {
                      return
                    }
                    let x = JSON.parse(JSON.stringify(filters))
                    x.year = ''
                    x.month = ''
                    x.quarter = []
                    x.dateFrom = date
                    EventEmitter.dispatch('set-date-filter', x)
                  }}
                  placeholder={translate('From...')}
                />
                <DatePicker
                  name={'report-to-date'}
                  value={filters.dateTo || null}
                  placement="bottom-end"
                  onChange={(date) => {
                    if (!date) {
                      return
                    }
                    let x = JSON.parse(JSON.stringify(filters))
                    x.year = ''
                    x.month = ''
                    x.quarter = []
                    x.dateTo = date
                    EventEmitter.dispatch('set-date-filter', x)

                    // EventEmitter.dispatch('set-date-filter',{
                    //     ...filters,
                    //     year: '',
                    //     month: '',
                    //     quarter: '',
                    //     dateTo: date,
                    // });
                  }}
                  placeholder={translate('to...')}
                />
              </div>
            }
          >
            <div
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              className={`rounded-full h-10 cursor-pointer py-3 px-3 border-gray-300 flex items-center text-gray-700 focus:border-blue-700 ${
                (filters.dateTo &&
                                    filters.dateTo.length > 0 &&
                                    filters.dateFrom &&
                                    filters.dateFrom.length > 0) ||
                                isPopoverOpen
                  ? 'border-blue-700 border-2'
                  : 'border'
              }`}
            >
              {translate('Date range')}
            </div>
          </Popover>
        )}
      </div>
    </>
  )
}

function dropdownRender<T>({
  name = '',
  hasError,
  placeholder,
  isOpen,
  openMenu,
  toggleMenu,
  getInputProps,
  autocomplete,
  autoFocus,
  clearSelection
}: RenderInputProps<T>) {
  let active = name.includes('-active')

  return (
    <div className="relative">
      <input
        className={`h-10 bg-gray-000 text-center py-2 w-25 rounded-full leading-tighter border-2 text-gray-700 cursor-pointer ${
          hasError ? 'border-red-700' : active ? 'border-blue-700' : 'b-transparent'
        } outline-none focus:border-blue-700`}
        readOnly={!autocomplete}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...getInputProps({
          onClick: () => {
            autocomplete && clearSelection()
            toggleMenu()
          },
          onKeyDown: (e) => {
            switch (e.key) {
              case 'Enter':
                if (!isOpen) {
                  openMenu()
                }
                break
            }
          }
        })}
      />

      <button
        onClick={() => toggleMenu()}
        className="absolute w-4 h-10 top-0 right-0 flex items-center justify-center focus:outline-none mr-1"
        tabIndex={-1}
      >
        <Icon
          name="triangle"
          className={`text-gray-600 ${isOpen ? '' : 'rotate-180'}`}
          size={2}
        />
      </button>
    </div>
  )
}

function RenderMultiselect<T>({
  name,
  selectedItems,
  hasError,
  placeholder,
  isOpen,
  getToggleButtonProps,
  shownItemSize
}: RenderMultiselectProps<T>) {
  let active = name.includes('-active')

  return (
    <div
      className={`multiselect-${name} flex items-center justify-center h-10 bg-gray-000 text-center py-2 w-25 rounded-full leading-tighter border-2 text-gray-700 cursor-pointer w-${
        selectedItems.length > 1 ? (selectedItems.length > 2 ? 75 : 50) : 30
      } ${hasError ? 'border-red-700' : active ? 'border-blue-700' : 'b-transparent'} ${
        isOpen && 'border-blue-700'
      }`}
      {...getToggleButtonProps()}
    >
      <div>
        {!isOpen && selectedItems.length ? (
          <SelectedItems selectedItems={selectedItems} shownSize={shownItemSize} />
        ) : (
          <div className="w-25 text-center">{placeholder}</div>
        )}
      </div>
      <Icon
        name="triangle"
        className={`inline-block align-middle text-gray-600 ${isOpen ? '' : 'rotate-180'}`}
        size={2}
      ></Icon>
    </div>
  )
}

export default ReportDateRange
