import React, {memo} from 'react'
import {Icon} from 'components/Icon'
import {translate} from 'utils/translations'

const ExportButton: React.FC<{exportUrl: string}> = ({exportUrl}) => {
  return (
    <div className="text-blue-700 flex items-center mr-4">
      <a
        className="cursor-pointer text-blue-700 flex items-center mr-4"
        href={exportUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon name="get_app" size={5} />
        {translate('Export')}
      </a>
            |
    </div>
  )
}

const PureExportButton = memo(ExportButton)

export default PureExportButton
