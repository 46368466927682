import * as React from 'react'
import {Link, Route, Switch, useHistory} from 'react-router-dom'
import {FieldTemplate, Supplier, SupplierContact} from '@contractool/schema'
import {Dropdown} from 'components/Dropdown'
import FetchSelect from 'components/select/FetchSelect'
import {Form, FormContext} from 'components/Form'
import {SupplierContactModal} from 'components/form/SupplierContactModal'
import {dropdownPreSelectsFirstOptionOf} from 'features/form/dropdown'
import {usePrevious} from 'hooks'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import {SupplierNew} from 'views/suppliers/SuppliersNew'
import ProjectFormContext from 'views/project/ProjectFormContext'
import {AppContext, GuardContext} from 'contexts'
import {userPermissionRequest} from 'utils/wildcard'
import {useContext} from 'react'

const getOptions = (entities: any[]) =>
  entities.map((entity) => ({
    label: entity.name,
    value: entity.id
  }))

const SupplierContactDropdown = dropdownPreSelectsFirstOptionOf(Dropdown)

const SupplierSelect: React.FC<{required?: boolean, field?: FieldTemplate}> = ({required, field}) => {
  const {values: formValues, handleChange: handleFieldChange} = React.useContext(FormContext)

  const [supplier, setSupplier] = React.useState<Supplier | undefined>(undefined)
  const getSupplier = React.useCallback((supplier_id) => {
    http.get<Supplier>(`/api/suppliers/${supplier_id}`)
      .then((response) => {
        setSupplier(response.data)
      })
  }, [])

  const [supplierContacts, setSupplierContacts] = React.useState<SupplierContact[]>([])
  const getSupplierContacts = React.useCallback((supplier_id) => {
    http.get<SupplierContact[]>(`/api/suppliers/${supplier_id}/contacts`)
      .then((response) => {
        setSupplierContacts(response.data)
      })
  }, [])

  // re-fetches supplier and contacts when supplier_id changes
  React.useEffect(() => {
    if (formValues.supplier_id) {
      getSupplier(formValues.supplier_id)
      getSupplierContacts(formValues.supplier_id)
    }
    // eslint-disable-next-line
    }, [formValues.supplier_id]);

  // reset supplier_contact_id to '' when supplier_id changes
  const previousSupplierId = usePrevious(formValues.supplier_id)
  React.useEffect(() => {
    if (previousSupplierId && previousSupplierId !== formValues.supplier_id) {
      setSupplierContacts([])
      handleFieldChange('supplier_contact_id', '')
    }
  }, [previousSupplierId, formValues.supplier_id, handleFieldChange])

  const {updateSupplierTitle} = React.useContext(ProjectFormContext)
  React.useEffect(() => {
    if (supplier?.title) {
      updateSupplierTitle(supplier.title)
    }
    // eslint-disable-next-line
    }, [supplier]);

  const apiParams = React.useMemo(() => ({
    phrase: supplier?.title || ''
  }), [supplier])

  const history = useHistory()
  const currentUrl = history.location.pathname
  const supplierModalPath = `${currentUrl.replace('/new-supplier', '')}/new-supplier`
  const contactModalPath = `${currentUrl.replace('/new-supplier-contact', '')}/new-supplier-contact`

  const {config} = React.useContext(AppContext)
  const {user} = useContext(GuardContext)
  let canCreateNew = userPermissionRequest(user, 'suppliers.new')
  // need to check supplier?.id === formValues.supplier_id because when user change supplier_id using dropdown, const supplier keeps old supplier data until response arrive

  return (
    <>
      <Form.Field
        api="/api/suppliers"
        apiParams={apiParams}
        className="mb-6"
        component={FetchSelect}
        label={translate('Supplier')}
        legend={field && field.legend ? translate(field.legend) : ''}
        helptext={field && field.helptext ? translate(field.helptext) : ''}
        name="supplier_id"
        required={required}
        right={canCreateNew ?
          <Link
            to={{
              pathname: supplierModalPath,
              search: history.location.search
            }}
            className="text-blue-600 hover:text-blue-300"
          >
            {'+ ' + translate('New supplier')}
          </Link> : null
        }
      />
      {config.config.supplier_poc && (
        <Form.Field
          autocomplete
          className="mb-6"
          component={SupplierContactDropdown}
          label={translate('Supplier point of contact')}
          legend={field && field.extra && field.extra.poc_legend ? translate(field.extra.poc_legend) : ''}
          helptext={field && field.extra && field.extra.poc_helptext ? translate(field.extra.poc_helptext) : ''}
          name="supplier_contact_id"
          options={getOptions(supplierContacts)}
          required={required}
          right={
            formValues.supplier_id ? (
              <Link
                to={{
                  pathname: `${currentUrl}/new-supplier-contact`,
                  search: history.location.search
                }}
                className="text-blue-600 hover:text-blue-300"
              >
                {`+ ${translate('Add contact')}`}
              </Link>
            ) : null
          }
        />
      )}
      <Switch>
        <Route path={supplierModalPath}>
          <SupplierNew
            onCreate={(supplier: Supplier | null) => {
              history.push({
                // why we need this?
                pathname: currentUrl,
                search: history.location.search
              })
              if (supplier?.id) {
                handleFieldChange('supplier_id', supplier.id)
              }
            }}
            closeUrl={currentUrl.replace('/new-supplier', '')}
          />
        </Route>
        {formValues.supplier_id && supplier && (
          <Route path={contactModalPath}>
            <SupplierContactModal
              label={translate('Add contact')}
              contact={{
                name: '',
                email: '',
                phone: '',
                id: 0,
                supplier_id: formValues.supplier_id,
                url: ''
              }}
              onSubmit={(supplierContactValues) =>
                http.post(supplier.contacts_url, supplierContactValues)
              }
              onCancel={() =>
                history.push(currentUrl.replace('/new-supplier-contact', ''))
              }
              onSuccess={(contact: SupplierContact) => {
                handleFieldChange('supplier_contact_id', contact.id)
                getSupplierContacts(contact.supplier_id)
                history.push(currentUrl.replace('/new-supplier-contact', ''))
              }}
            />
          </Route>
        )}
      </Switch>
    </>
  )
}

export default SupplierSelect
