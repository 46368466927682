import React, {useState} from 'react'

import {Button} from 'components/Button'
import {Icon} from 'components/Icon'
import {Modal} from 'components/Modal'
import {Switch} from 'components/Switch'
import Search from 'components/Search'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import {useBigLoader} from 'components/Loader'

const titleColumnStyle = {
  width: '16rem'
}

function CruncherListModal({phrase, onClose}: {phrase: string; onClose: () => void}) {
  const [exact, setExact] = useState(false)
  const [searched, setSearched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<any[]>([])
  const [cruncherPhrase, setCruncherPhrase] = useState<string>(phrase)

  let loader = useBigLoader()

  const doSearch = () => {
    setSearched(false)
    if (cruncherPhrase.length >= 2) {
      setLoading(true)
      loader.start('Searching in documents...')
      setSearched(true)
      http.get('api/cruncher-search?fuzzy=' + (exact ? 0 : 1), {phrase: cruncherPhrase}).then(
        (result: any) => {
          console.log('cruncher result', result)
          // alert(result);
          setResult(result.data)
          loader.stop()
          setLoading(false)
        }
      )
    }
  }

  return (
    <Modal
      heading={
        <div className="text-md flex items-end">
          <Search
            className={'w-200'}
            placeholder={translate('Search in files')}
            value={cruncherPhrase}
            onChange={setCruncherPhrase}
          />
          <Button color="white"
            className="ml-4 w-50 text-md"
            onClick={doSearch}>Search in files</Button>
        </div>
      }
      corner={
        <Switch name="exact" value={exact} onChange={setExact} > {translate('Exact match')} </Switch>
      }
      isOpen={true}
      onClose={onClose}
      size="w-10/12"
      compact={true}
      containerStyle={{height:'95vh', width: '95%'}}
    >
      {searched && cruncherPhrase.length > 2 && <div style={{height: '200px', marginTop: '-26px'}} className='border rounded-xl'>
        <iframe title="Word cloud" style={{width: '100%', height: '100%'}} src={`/cruncher-cloud?phrase=${encodeURIComponent(cruncherPhrase)}`}></iframe>
      </div>}
      {searched && !loading && result.length === 0 && <div className="p-8 text-gray-500">No documents were found for "{cruncherPhrase}"</div>}
      <table className="table file-search">
        <tbody>
          {result.map((row: any, idx: number) => {
            let result = row.occurrences[0]
            // let index = result.search('%%%');
            // let count = 0;
            // while (index !== -1) {
            //     if (count % 2 === 0) {
            //         result = result.split('');
            //         result.splice(index, 3, ...'<span style="background-color: yellow">'.split(''));
            //         result = result.join('');
            //     } else {
            //         result = result.split('');
            //         result.splice(index, 3, ...'</span>'.split(''));
            //         result = result.join('');
            //     }
            //     count++;
            //     index = result.search('%%%');
            // }

            return (
              <>
                <tr key={idx + '-1'} style={{borderBottom: 'none'}}>
                  <td colSpan={4}>
                    <div className="font-bold text-md overflow-hidden mt-3 text-gray-800">
                      {row.project_title}
                    </div>
                    <div className="text overflow-hidden mt-1 mb-2 text-gray-700">
                      {row.title}
                    </div>
                  </td>
                </tr>
                <tr
                  key={idx + '-2'}
                  className="flex items-center pb-7 border-b text-gray-700"
                >
                  <td className="pr-2">
                    <Icon name={row.icon} className="text-gray-500" size={20} />
                  </td>
                  <td style={titleColumnStyle}>
                    <div className="text-gray-700">
                      {row.category?.title}
                    </div>
                    <div className="text-gray-700">
                      {row.supplier?.title}
                    </div>
                  </td>
                  <td className="flex-1 pl-3">
                    <div dangerouslySetInnerHTML={{__html: result}} />
                  </td>
                  <td>
                    <a
                      href={row.document_url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Icon
                        name="external_link"
                        className="text-gray-500 m-auto"
                        size={8}
                      />
                      {translate('Show more')}
                    </a>
                  </td>
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const MemorizedCruncherListModal = React.memo(CruncherListModal)

export default MemorizedCruncherListModal
