import {ReportTab, FilterTemplate} from '@contractool/schema'
import {groupBy} from 'lodash'
import * as React from 'react'
import {Route, Redirect, useHistory, useParams} from 'react-router-dom'

import {AppContext} from 'contexts'
import {Menu, MenuItem} from 'components/Menu'
import {Page} from 'components/Page'
import {Tab, Tabs} from 'components/Tabs'
import {useRequest} from 'hooks/useRequest'
import {Context as ReportsContext} from 'contexts/reports'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import {useToggle} from 'hooks'
import DellJiReportsView from 'integrations/dell_ji/components/views/reports'

import ReportTabView from './tab'
import TabsOverlay from './TabsOverlay'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import {Confirmation} from 'components/Confirmation'

const getTabName = (tab: ReportTab, idx: number) => tab ? `${tab.heading} ${idx}` : ''

const ReportTabs: React.FC = ({children}) => {
  const {tabs} = React.useContext(ReportsContext)

  // todo: refactor Tabs component and remove this block
  const {tabIdx} = useParams<{tabIdx?: string}>()
  const idx = Number(tabIdx)
  const tab = tabs[idx]
  const selectedTabName = getTabName(tab, idx)
  // end block

  const history = useHistory()
  const handleSelectTab = React.useCallback((name: string) => {
    const segments = name.split(' ')
    const idx = Number(segments[segments.length - 1])
    history.push(`/reports/${idx}`)
  }, [history])

  return tabs.length > 0 ? (
    <Tabs
      selected={selectedTabName}
      onSelect={handleSelectTab}
      fixedHeight={false}
    >
      {tabs.map((tab: ReportTab, idx: number) => (
        <Tab
          key={getTabName(tab, idx)}
          name={getTabName(tab, idx)}
          heading={tab.heading}
          className="pt-4"
        >
          <div className={'-mx-6'}>
            {children}
          </div>
        </Tab>
      ))}
    </Tabs>
  ) : null
}

const Layout = () => {
  const [tabsOverlayVisible, tabsOverlayDisplayToggle] = useToggle(false)
  const {resetTabs, refreshTabs} = React.useContext(ReportsContext)
  const resetReports = React.useCallback(async () => {
    await resetTabs()
    await refreshTabs()
  }, [resetTabs, refreshTabs])

  return (
    <div className="bg-gray-000 min-h-screen">
      <Page
        heading={translate('Reports')}
        right={<div id="create-button"/>}
      >
        <div className="mb-5 relative">
          <div
            title={translate('Manage tabs')}
            className="absolute top-0 right-0 text-gray-500 cursor-pointer"
          >
            {/*<button*/}
            {/*    className="p-4 bg-transparent text-blue-700 outline-none focus:shadow-outline rounded-lg"*/}
            {/*    onClick={tabsOverlayDisplayToggle.on}*/}
            {/*>*/}
            {/*    <Icon name="settings" size={5}/>*/}
            {/*</button>*/}
            <Menu handle="settings">
              <MenuItem
                icon="list"
                onClick={tabsOverlayDisplayToggle.on}
                nowrap
              >
                {translate('Manage reports')}
              </MenuItem>
              <Confirmation
                onConfirm={resetReports}
                trigger={({onClick}) => (
                  <MenuItem
                    icon="settings_restore"
                    onClick={onClick}
                    nowrap
                  >
                    {translate('Reset reports')}
                  </MenuItem>
                )}
                heading={translate('Reset reports')}
                buttonText={translate('Yes, reset')}
                color="red"
              >
                {translate('Are you sure you want to reset all the reports to the default setup? All reports created by you will be removed. This action can\'t be undone.')}
              </Confirmation>
            </Menu>
          </div>
        </div>
        <ReportTabs>
          <ReportTabView/>
        </ReportTabs>
        {tabsOverlayVisible && (
          <TabsOverlay onDismiss={tabsOverlayDisplayToggle.off}/>
        )}
      </Page>
    </div>
  )
}

const Provider: React.FC = ({children}) => {
  const [filterTemplates] = useRequest<FilterTemplate[]>('/api/report-groups', [])
  const groupedFilterTemplates = groupBy(filterTemplates, 'name')

  const [tabs, {refresh: refreshTabs}] = useRequest<ReportTab[]>('/api/report-tabs', [])
  const createTab = React.useCallback(async (payload: ReportTab) => {
    const {
      data: newTab
    } = await http.post<ReportTab>('/api/report-tabs', payload)

    return newTab
  }, [])

  const updateTab = React.useCallback(async (idx: number, payload: ReportTab) => {
    const {
      data: updatedTab
    } = await http.put<ReportTab>(`/api/report-tabs/${idx}`, payload)

    return updatedTab
  }, [])

  const removeTab = React.useCallback(async (idx: number) => {
    const {
      data: deletedTab
    } = await http.delete<ReportTab>(`/api/report-tabs/${idx}`)

    return deletedTab
  }, [])

  const orderTabs = React.useCallback(async (order: number[]) => {
    const {
      data: tabs
    } = await http.post<ReportTab[]>('/api/report-tabs-order', {
      order
    })

    return tabs
  }, [])

  const resetTabs = React.useCallback(async () => {
    const {
      data: tabs
    } = await http.post<ReportTab[]>('/api/report-tabs-reset', {})

    return tabs
  }, [])

  const value = React.useMemo(
    () => ({
      groups: filterTemplates,
      groupedFilterTemplates,
      tabs,
      createTab,
      updateTab,
      removeTab,
      orderTabs,
      resetTabs,
      refreshTabs
    }), [
      filterTemplates,
      groupedFilterTemplates,
      tabs,
      createTab,
      updateTab,
      removeTab,
      orderTabs,
      resetTabs,
      refreshTabs
    ]
  )

  return <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
}

const Routes = () => (
  <>
    <Route path="/reports" exact>
      <Redirect to={'/reports/0'}/>
    </Route>
    <Route path="/reports/:tabIdx">
      <Layout/>
    </Route>
  </>
)

const View = () => (
  <Provider>
    <Routes/>
  </Provider>
)

const ReportsPage = () => {
  const {config} = React.useContext(AppContext)
  const {integration} = config

  return 'dell_ji' === integration ? <DellJiReportsView/>
    : 'scantraxx' === integration ? null
      : <View/>
}

export default ReportsPage

