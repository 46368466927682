import React from 'react'
import {Icon} from 'components/Icon'
import {translate} from 'utils/translations'
import {Menu, MenuItem} from 'components/Menu'
import {Confirmation} from 'components/Confirmation'
import {useHistory} from 'react-router'
import {http} from 'utils/http'
import {AiTask} from '../../../../../_common/schema'

const SortIcon = ({
  direction
}: {
      direction: 'asc' | 'desc',
  }) => (
  <Icon name={direction === 'asc' ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} size={5}/>
)

const TasksList = ({
  sortDirection,
  sortBy,
  tasks,
  onSort,
  onDelete
}: {
    sortBy?: string;
    sortDirection?: 'asc' | 'desc';
    tasks: AiTask[];
    onSort?: (column: string, direction: 'asc' | 'desc') => void
    onDelete: () => void;
  }) => {
  const history = useHistory()

  const deleteTask = (id: string) => {
    http.delete(`/api/ai/tasks/${id}`).then(() => {
      onDelete()
    })
  }
  const handleSort = (column: string) => {
    if (onSort) {
      onSort(column, column !== sortBy || sortDirection === 'desc' ? 'asc' : 'desc')
    }
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>
              <div className="flex items-center cursor-pointer" onClick={() => handleSort('name')}>
                {translate('Name')}
                {sortBy === 'name' && sortDirection && (
                  <SortIcon direction={sortDirection}/>
                )}
              </div>
            </th>
            <th>
              <div className="flex items-center cursor-pointer" onClick={() => handleSort('description')}>
                {translate('Description')}
                {sortBy === 'description' && sortDirection && (
                  <SortIcon direction={sortDirection}/>
                )}
              </div>
            </th>
            <th className="w-16"></th>
          </tr>
        </thead>
        <tbody>
          {
            tasks && tasks.length ?
              tasks.map((task, index) => {
                return (
                  <tr
                    key={index}
                    className="hover:bg-gray-000 cursor-pointer rounded align-middle table-row border-b-1 border-gray-100"
                  >
                    <td
                      className="px-6 py-7"
                    >
                      {task.name}
                    </td>
                    <td
                      className="px-6 py-7"
                    >
                      <div dangerouslySetInnerHTML={{__html: task.description}} />
                    </td>
                    <td>
                      <Menu handle="more_horiz">
                        <MenuItem
                          icon="edit"
                          onClick={() => {
                            history.push(`/ai/tasks/edit/${task.id}`)
                          }}
                          nowrap
                        >
                          {translate('Edit')}
                        </MenuItem>
                        <Confirmation
                          onConfirm={() => {
                            deleteTask(task.id)
                          }}
                          trigger={({onClick}) => (
                            <MenuItem
                              icon="delete"
                              onClick={onClick}
                              nowrap
                            >
                              {translate('Delete')}
                            </MenuItem>
                          )}
                          heading={translate('Delete Task')}
                          buttonText={translate('Yes, delete')}
                          color="red"
                        >
                          {translate(`Are you sure you want to delete ${task.name} task?. This action can't be undone.`)}
                        </Confirmation>
                      </Menu>
                    </td>
                  </tr>
                )

              })
              : (<tr>
                <td colSpan={8} className="text-center">
                  {translate('No Tasks found')}
                </td>
              </tr>)
          }
        </tbody>
      </table>
    </>
  )
}

export default TasksList
