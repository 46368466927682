import React, {useContext, useState} from 'react'
import {Page} from 'components/Page'
import {Button} from 'components/Button'
import {GuardContext} from 'contexts'
import {Editor} from '@tinymce/tinymce-react'
import {useRequest} from 'hooks/useRequest'
import {http} from 'utils/http'
import {useToasts} from 'hooks'
import {News} from '@contractool/schema'
import ReactHtmlParser, {convertNodeToElement} from 'react-html-parser'
import {DomElement} from 'htmlparser2'

import './NewsPage.css'
import {userPermissionRequest} from 'utils/wildcard'
import {translate} from 'utils/translations'
import {useSmallLoader} from 'components/Loader'

const EDITOR_API_KEY = 'rth1kxg3nn0dla1xo4fpv0wzgpi8j09n56838c4akj5k3hc0'

export function NewsPage() {
  const {success, error} = useToasts()
  const {user} = useContext(GuardContext)
  const [news, {refresh}] = useRequest<News>('api/news', {article: ''})
  const [edit, setEdit] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)
  const [editArticle, setEditArticle] = useState<string>('')
  const {start, stop} = useSmallLoader()

  const init = {
    content_style: '.mce-content-body {padding : 7px}',
    height: 500,
    outerWidth: 800,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
            'undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help'
  }

  const submit = () => {
    start(`${translate('Uploading new version of article')}`)
    http.put('api/news', {article: editArticle})
      .then(() => {
        refresh()
        success(`${translate('Successfully updated article')}.`)
        stop()
        setDirty(false)
        setEdit(false)
      })
      .catch(() => {
        error(`${translate('Something went wrong')}. ${translate('Article wasn\'t updated.')}.`)
        stop()
        setDirty(false)
        setEdit(false)
      })
  }

  const handleEditButton = () => {
    setEditArticle(news.article)
    setEdit(true)
  }

  const handleCancelButton = () => {
    setDirty(false)
    setEdit(false)
  }

  const pagesTopRightPart = userPermissionRequest(user, 'news.edit') ? (
    edit ? (
      <>
        <Button
          type="button"
          color="white"
          className="mr-10"
          icon="close"
          onClick={handleCancelButton}
        >
          {translate('Cancel')}
        </Button>
        <Button type="submit" icon="done" onClick={submit} disabled={!dirty}>
          {translate('Submit')}
        </Button>
      </>
    ) : (
      <Button type="button" color="white" onClick={handleEditButton} icon="edit">
        {translate('Edit article')}
      </Button>
    )
  ) : undefined

  const transformTags = (node: DomElement, index: number) => {
    if (node.type === 'tag') {
      if (
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'li', 'ul', 'ol'].find((v) => v === node.name)
      ) {
        node.attribs.class = node.name

        return convertNodeToElement(node, index, transformTags)
      }
    }

    return undefined
  }

  console.log(news.article)

  return (
    <Page heading="News" right={pagesTopRightPart}>
      {edit ? (
        <Editor
          value={editArticle}
          apiKey={EDITOR_API_KEY}
          init={init}
          id={'news-editor'}
          onEditorChange={(data) => {
            setDirty(true)
            setEditArticle(data)
          }}
        />
      ) : (
        <div className="custom" style={{maxWidth: 800}}>
          {news.article.trim() === '' ? (
            <div className="text-lg text-gray-600">There is no news yet.</div>
          ) : (
            ReactHtmlParser(news.article, {transform: transformTags})
          )}
        </div>
      )}
    </Page>
  )
}
