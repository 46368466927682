import {Role, FieldTemplate, ProjectCategory, ProjectType, Supplier} from '@contractool/schema'
import React, {FC} from 'react'
import {Form} from 'components/Form'
import {commodities} from '../../../utils/commodities'
import {translate} from 'utils/translations'
import {CustomField} from 'components'
import {convertArrayToObject, getOptions, TeamRoleSelect} from '../../../utils/form'
import {useRequest} from 'hooks/useRequest'

export const DellDefaultDetailsForm: FC<{roles: Role[]; fields: FieldTemplate[]}> = ({
  roles,
  fields
}) => {
  const [categories] = useRequest<ProjectCategory[]>('/api/project-categories', [])
  const [types] = useRequest<ProjectType[]>('/api/project-types', [])
  const [suppliers] = useRequest<Supplier[]>('/api/suppliers', [])

  const fieldsObj = convertArrayToObject(fields, 'name')

  const categoriesAsOptions = getOptions(categories)
  const typesAsOptions = getOptions(types)
  const supplierAsOptions = getOptions(suppliers)

  return (
    <>
      <Form.TextInput
        name="title"
        label={translate('Title')}
        placeholder={translate('Title')}
        autoFocus
        className="mb-6"
        required={true}
      />
      <Form.Dropdown
        name="category_id"
        label={translate('Category')}
        options={categoriesAsOptions}
        className="mb-6"
        required={true}
      />
      <Form.Multiselect
        name="types"
        label={translate('Project Types')}
        options={typesAsOptions}
        className="mb-6"
        autocomplete
        placeholder={translate('Project Types')}
        dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
        changeKeys={{key1: 'id', key2: 'title'}}
        required={true}
      />
      <Form.Dropdown
        name="supplier_id"
        label={translate('Supplier')}
        options={supplierAsOptions}
        className="mb-6"
        required={true}
      />
      <CustomField
        field={fieldsObj.region}
        option="fields."
        className="mb-6"
        config={{
          type: 'GENERATING',
          target: 'title',
          dependencies: [
            'supplier_id',
            'category_id',
            'types',
            'fields.region',
            'fields.country'
          ]
        }}
      />
      <CustomField field={fieldsObj.country} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.nda_no_date} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.contract_amount} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.request_defects} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.supplier_poc} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.supplier_email} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.supplier_address} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.effective_at} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.requested_at} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.effective_at} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.business_partner} option="fields." className="mb-6" />
      <CustomField
        field={fieldsObj.business_partner_email}
        option="fields."
        className="mb-6"
      />
      <CustomField field={fieldsObj.business_director} option="fields." className="mb-6" />
      <CustomField
        field={fieldsObj.business_director_email}
        option="fields."
        className="mb-6"
      />
      <CustomField field={fieldsObj.request_id} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.workspace_id} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.signatories_supplier} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.signatories_dell} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.approvers} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.legacy_dell_emc} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.adobe_sign_direct} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.new_onboarding} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.priority} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.hard_copies} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.additional_contracts} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.solution_description} option="fields." className="mb-6" />
      <CustomField field={fieldsObj.note} option="fields." className="mb-6" />
      {roles.map(
        (role: Role) =>
          role.key !== 'admin' &&
                    role.key !== 'assignee' && (
            <TeamRoleSelect key={role.key} role={role} className="mb-6" />
          )
      )}
    </>
  )
}

export const DellOnboardingDetailsForm: FC<{roles: Role[]; fields: FieldTemplate[]}> = ({
  roles,
  fields
}) => {
  const [suppliers] = useRequest<Supplier[]>('/api/suppliers', [])
  const supplierAsOptions = getOptions(suppliers)

  const fieldsObj = convertArrayToObject(fields, 'name')

  return (
    <>
      <Form.Dropdown
        name="supplier_id"
        label={translate('Supplier')}
        options={supplierAsOptions}
        className="mb-6"
        required={true}
      />
      <CustomField field={fieldsObj.onboarding__new_request} option="fields." />
      <CustomField
        field={fieldsObj.onboarding__sir_number}
        option="fields."
        className="mb-6"
        config={{
          type: 'GENERATING',
          target: 'title',
          dependencies: ['fields.onboarding__sir_number', 'fields.onboarding__region']
        }}
      />
      <CustomField
        field={fieldsObj.onboarding__onboarding_scenario}
        option="fields."
        className="mb-6"
      />
      <CustomField
        field={fieldsObj.onboarding__spend_category_portfolio}
        option="fields."
        className="mb-6"
      />
      <CustomField
        config={{
          type: 'DEPENDING',
          dependencies: [
            'fields.onboarding__spend_category_portfolio',
            'fields.onboarding__new_request'
          ],
          alt: ['Software & Logistics', 'Indirect Procurement'],
          options: commodities
        }}
        field={fieldsObj.onboarding__commodity}
        option="fields."
        className="mb-6"
      />
      <CustomField
        field={fieldsObj.onboarding__region}
        option="fields."
        className="mb-6"
        config={{
          type: 'GENERATING',
          target: 'title',
          dependencies: ['fields.onboarding__sir_number', 'fields.onboarding__region']
        }}
      />
      <CustomField
        field={fieldsObj.onboarding__set_of_book_company_code}
        option="fields."
        className="mb-6"
      />
      <CustomField
        field={fieldsObj.onboarding__last_assignment_to_obt}
        option="fields."
        className="mb-6"
      />
      <CustomField
        field={fieldsObj.onboarding__payment_terms}
        option="fields."
        className="mb-6"
      />
      <CustomField
        field={fieldsObj.onboarding__realm.values}
        option="fields."
        className="mb-6"
      />
      <CustomField
        field={fieldsObj.onboarding__high_priority}
        option="fields."
        className="mb-6"
      />
      <CustomField field={fieldsObj.onboarding__comments} option="fields." className="mb-6" />
      <CustomField
        field={fieldsObj.onboarding__request_defects}
        option="fields."
        className="mb-6"
      />
      <CustomField
        field={fieldsObj.onboarding__spend_amount_validated}
        option="fields."
        className="mb-6"
      />
      {roles.map(
        (role: Role) =>
          role.key !== 'admin' &&
                    role.key !== 'assignee' &&
                    role.key.includes('onboarding') && (
            <TeamRoleSelect key={role.key} role={role} className="mb-6" />
          )
      )}
    </>
  )
}
