import {Icon} from 'components/Icon'
import {renderedTinyMceContentStyle} from 'components/TinymcePluginMDF'
import React, {FC, useRef} from 'react'
import {useDrag} from 'react-dnd'
import {ClauseResource} from 'resources'
import {clausesTpTypes} from './TemplateEditor'

const ClauseItem:FC<{clause:ClauseResource}> = ({clause})=>{
  const [clauseOpened, setClauseState] = React.useState(false)

  const ref = useRef(null)

  const [{isDragging}, connectDrag] = useDrag(() => ({
    type: clausesTpTypes.clause ,
    item:{clause},
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    }),
    end:() => {
      if (ref.current != null){
        console.log(ref.current)
      }
    }
  }))

  connectDrag(ref)

  const isDraggingStyle = {
    backgroundColor:isDragging ? '#eee' : 'white',
    borderBottom:isDragging ? 'none' : ''
  }

  return (
    <div className='border-b border-gray-100 py-6 px-2 focus:rounded hover:border hover:border-blue-600'
      style={isDraggingStyle}
      tabIndex={0}
      ref={ref}>
      <div className="flex">
        <div className="leading-tighter text-gray-900">
          {clause.name}
        </div>
      </div>

      <div className="flex-grow flex justify-end text-gray-600">
        <div onClick={() => setClauseState(s => !s)} className="cursor-pointer">
          <Icon name={clauseOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} size={5} />
        </div>
      </div>

      {clauseOpened && (
        <div className="w-full mt-2 text-gray-600">
          <style>
            {renderedTinyMceContentStyle}
          </style>
          <div className="clause-content" dangerouslySetInnerHTML={{__html: clause.content}} />
        </div>
      )}
    </div>
  )
}

export default ClauseItem
