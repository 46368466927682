import React, {useState} from 'react'
import {Button} from '../../components/Button'
import {Modal} from '../../components/Modal'
import {Section, Variation} from './DesignSystem'
import {Confirmation} from '../../components/Confirmation'

export function Modals() {
  const [small, setSmall] = useState(false)
  const [regular, setRegular] = useState(false)
  const [corner, setCorner] = useState(false)
  const [compact, setCompact] = useState(false)
  const [compactCorner, setCompactCorner] = useState(false)
  const [long, setLong] = useState(false)
  const [longCompact, setLongCompact] = useState(false)

  return (
    <>
      <Section title="Modal sizes">
        <Variation hint="Small modal">
          <Button color="white" onClick={() => setSmall(true)}>
                        Show modal
          </Button>

          <Modal
            heading="Small modal"
            isOpen={small}
            onClose={() => setSmall(false)}
            size="small"
          >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book.
            <div className="mt-16">
              <Button color="white" onClick={() => setSmall(false)}>
                                Cancel
              </Button>
            </div>
          </Modal>
        </Variation>

        <Variation hint="Regular modal">
          <Button color="white" onClick={() => setRegular(true)}>
                        Show modal
          </Button>

          <Modal
            heading="Regular modal"
            isOpen={regular}
            onClose={() => setRegular(false)}
            size="regular"
          >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining essentially unchanged. It
                        was popularised in the 1960s with the release of Letraset sheets containing
                        Lorem Ipsum passages, and more recently with desktop publishing software
                        like Aldus PageMaker including versions of Lorem Ipsum.
            <div className="mt-16">
              <Button color="white" onClick={() => setRegular(false)}>
                                Cancel
              </Button>
            </div>
          </Modal>
        </Variation>
      </Section>

      <Section title="Headers & Corners">
        <Variation hint="Modal with a corner">
          <Button color="white" onClick={() => setCorner(true)}>
                        Show modal
          </Button>

          <Modal
            heading="Modal with a corner"
            isOpen={corner}
            onClose={() => setCorner(false)}
            corner={<div>Hello, I'm a corner. Nice to meet you.</div>}
          >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining essentially unchanged. It
                        was popularised in the 1960s with the release of Letraset sheets containing
                        Lorem Ipsum passages, and more recently with desktop publishing software
                        like Aldus PageMaker including versions of Lorem Ipsum.
            <div className="mt-16">
              <Button color="white" onClick={() => setCorner(false)}>
                                Cancel
              </Button>
            </div>
          </Modal>
        </Variation>

        <Variation hint="Compact modal">
          <Button color="white" onClick={() => setCompact(true)}>
                        Show modal
          </Button>

          <Modal
            heading="Compact modal"
            isOpen={compact}
            onClose={() => setCompact(false)}
            compact={true}
          >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining essentially unchanged. It
                        was popularised in the 1960s with the release of Letraset sheets containing
                        Lorem Ipsum passages, and more recently with desktop publishing software
                        like Aldus PageMaker including versions of Lorem Ipsum.
            <div className="mt-16">
              <Button color="white" onClick={() => setCompact(false)}>
                                Cancel
              </Button>
            </div>
          </Modal>
        </Variation>

        <Variation hint="Compact modal with a corner">
          <Button color="white" onClick={() => setCompactCorner(true)}>
                        Show modal
          </Button>

          <Modal
            heading="Compact modal with a header"
            isOpen={compactCorner}
            onClose={() => setCompactCorner(false)}
            corner={<div>Hello, I'm a corner. Nice to meet you.</div>}
            compact={true}
          >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining essentially unchanged. It
                        was popularised in the 1960s with the release of Letraset sheets containing
                        Lorem Ipsum passages, and more recently with desktop publishing software
                        like Aldus PageMaker including versions of Lorem Ipsum.
            <div className="mt-16">
              <Button color="white" onClick={() => setCompactCorner(false)}>
                                Cancel
              </Button>
            </div>
          </Modal>
        </Variation>
      </Section>

      <Section title="Scrolling">
        <Variation hint="Long-content modal">
          <Button color="white" onClick={() => setLong(true)}>
                        Show modal
          </Button>

          <Modal heading="Looooooong modal" isOpen={long} onClose={() => setLong(false)}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam feugiat
                        pretium sagittis. Etiam mattis ex sit amet magna cursus scelerisque. Duis
                        imperdiet ipsum sed dui consequat mattis. Donec pretium nisi nibh, a
                        convallis arcu ullamcorper in. Phasellus ullamcorper sapien et gravida
                        posuere. Donec vitae arcu nulla. Maecenas et ullamcorper quam, in porttitor
                        libero. Suspendisse non porttitor dolor. Quisque porta, risus at ultrices
                        pharetra, dolor nisi rhoncus sapien, quis eleifend sem est nec turpis.
                        Maecenas ac ligula ut magna tempor blandit a feugiat arcu. Suspendisse dolor
                        velit, fringilla et aliquam quis, fermentum sit amet nulla. Curabitur
                        vulputate eget sem vel finibus. Cras quis gravida ex. Proin at urna vel odio
                        eleifend convallis. Nunc sed tortor turpis. Curabitur vitae nunc tincidunt,
                        rhoncus nulla sed, pharetra lacus. Donec placerat ullamcorper magna, vitae
                        feugiat sem tincidunt sed. Nunc venenatis, magna at sagittis faucibus, dolor
                        purus condimentum massa, bibendum luctus enim nibh nec urna. Ut eget augue
                        vel purus dictum euismod. Vestibulum justo ex, lobortis ac lorem non,
                        tristique facilisis nisi. Suspendisse id imperdiet mi. Proin congue, tortor
                        a bibendum lacinia, nibh purus lacinia dolor, quis vehicula leo ex in massa.
                        Nunc vel lacinia ante. Mauris a ligula felis. Vivamus eu pellentesque nisl,
                        et semper metus. Mauris sit amet nisi et lacus finibus porttitor a a velit.
                        Vivamus eget feugiat lacus. Vivamus venenatis mauris vel nisi sodales
                        rutrum. Vestibulum finibus commodo congue. Integer sed tellus ut augue
                        tempus sodales. Etiam imperdiet leo arcu, ac imperdiet odio interdum et.
                        Aliquam urna mauris, scelerisque at blandit a, dignissim rhoncus nibh.
                        Aliquam in ligula rutrum, efficitur ex in, rhoncus purus. Etiam at bibendum
                        ante, et ultricies dui. Mauris non odio eget tellus pulvinar laoreet ac nec
                        dolor. Proin sed nisi quis tortor interdum dictum nec eu lacus. Aenean
                        faucibus massa eget rutrum porta. Proin velit dolor, faucibus non malesuada
                        sed, posuere sed enim. Vivamus vulputate lorem a lorem laoreet interdum.
                        Aliquam non cursus magna. Praesent ac ultricies dolor, nec blandit massa.
                        Praesent dui libero, mollis eget sagittis vel, venenatis eget urna.
                        Pellentesque posuere, metus et aliquet tincidunt, ipsum magna faucibus sem,
                        vel facilisis risus tortor et ex. Mauris quis bibendum eros, fermentum
                        vehicula risus. Nam at tellus elementum, aliquam tortor id, vulputate dolor.
                        Donec eu mollis erat, et porttitor diam. Pellentesque faucibus, nibh aliquam
                        ullamcorper consequat, mi sem sodales elit, et malesuada dui leo et est.
                        Curabitur a nunc nisi. Mauris iaculis volutpat augue et pretium. Sed viverra
                        dapibus lectus vel cursus. Cras orci metus, hendrerit eget eros nec,
                        molestie dignissim mauris. Duis justo lorem, rhoncus in leo sit amet,
                        commodo rhoncus nibh. Pellentesque ac massa ipsum. Suspendisse pulvinar diam
                        vehicula orci scelerisque venenatis. Aliquam lobortis est risus, vitae
                        tempor diam pellentesque sit amet. Aliquam commodo, eros non finibus tempus,
                        sem eros euismod ligula, sed mattis magna nulla at urna. Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit. Etiam feugiat pretium sagittis. Etiam
                        mattis ex sit amet magna cursus scelerisque. Duis imperdiet ipsum sed dui
                        consequat mattis. Donec pretium nisi nibh, a convallis arcu ullamcorper in.
                        Phasellus ullamcorper sapien et gravida posuere. Donec vitae arcu nulla.
                        Maecenas et ullamcorper quam, in porttitor libero. Suspendisse non porttitor
                        dolor. Quisque porta, risus at ultrices pharetra, dolor nisi rhoncus sapien,
                        quis eleifend sem est nec turpis. Maecenas ac ligula ut magna tempor blandit
                        a feugiat arcu. Suspendisse dolor velit, fringilla et aliquam quis,
                        fermentum sit amet nulla. Curabitur vulputate eget sem vel finibus. Cras
                        quis gravida ex. Proin at urna vel odio eleifend convallis. Nunc sed tortor
                        turpis. Curabitur vitae nunc tincidunt, rhoncus nulla sed, pharetra lacus.
                        Donec placerat ullamcorper magna, vitae feugiat sem tincidunt sed. Nunc
                        venenatis, magna at sagittis faucibus, dolor purus condimentum massa,
                        bibendum luctus enim nibh nec urna. Ut eget augue vel purus dictum euismod.
                        Vestibulum justo ex, lobortis ac lorem non, tristique facilisis nisi.
                        Suspendisse id imperdiet mi. Proin congue, tortor a bibendum lacinia, nibh
                        purus lacinia dolor, quis vehicula leo ex in massa. Nunc vel lacinia ante.
                        Mauris a ligula felis. Vivamus eu pellentesque nisl, et semper metus. Mauris
                        sit amet nisi et lacus finibus porttitor a a velit. Vivamus eget feugiat
                        lacus. Vivamus venenatis mauris vel nisi sodales rutrum. Vestibulum finibus
                        commodo congue. Integer sed tellus ut augue tempus sodales. Etiam imperdiet
                        leo arcu, ac imperdiet odio interdum et. Aliquam urna mauris, scelerisque at
                        blandit a, dignissim rhoncus nibh. Aliquam in ligula rutrum, efficitur ex
                        in, rhoncus purus. Etiam at bibendum ante, et ultricies dui. Mauris non odio
                        eget tellus pulvinar laoreet ac nec dolor. Proin sed nisi quis tortor
                        interdum dictum nec eu lacus. Aenean faucibus massa eget rutrum porta. Proin
                        velit dolor, faucibus non malesuada sed, posuere sed enim. Vivamus vulputate
                        lorem a lorem laoreet interdum. Aliquam non cursus magna. Praesent ac
                        ultricies dolor, nec blandit massa. Praesent dui libero, mollis eget
                        sagittis vel, venenatis eget urna. Pellentesque posuere, metus et aliquet
                        tincidunt, ipsum magna faucibus sem, vel facilisis risus tortor et ex.
                        Mauris quis bibendum eros, fermentum vehicula risus. Nam at tellus
                        elementum, aliquam tortor id, vulputate dolor. Donec eu mollis erat, et
                        porttitor diam. Pellentesque faucibus, nibh aliquam ullamcorper consequat,
                        mi sem sodales elit, et malesuada dui leo et est. Curabitur a nunc nisi.
                        Mauris iaculis volutpat augue et pretium. Sed viverra dapibus lectus vel
                        cursus. Cras orci metus, hendrerit eget eros nec, molestie dignissim mauris.
                        Duis justo lorem, rhoncus in leo sit amet, commodo rhoncus nibh.
                        Pellentesque ac massa ipsum. Suspendisse pulvinar diam vehicula orci
                        scelerisque venenatis. Aliquam lobortis est risus, vitae tempor diam
                        pellentesque sit amet. Aliquam commodo, eros non finibus tempus, sem eros
                        euismod ligula, sed mattis magna nulla at urna.
            <Modal.Footer>
              <Button color="white" onClick={() => setLong(false)}>
                                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </Variation>

        <Variation hint="Long-content compact modal">
          <Button color="white" onClick={() => setLongCompact(true)}>
                        Show modal
          </Button>

          <Modal
            heading="Looooooong & compaaaact modal"
            isOpen={longCompact}
            onClose={() => setLongCompact(false)}
            compact={true}
          >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam feugiat
                        pretium sagittis. Etiam mattis ex sit amet magna cursus scelerisque. Duis
                        imperdiet ipsum sed dui consequat mattis. Donec pretium nisi nibh, a
                        convallis arcu ullamcorper in. Phasellus ullamcorper sapien et gravida
                        posuere. Donec vitae arcu nulla. Maecenas et ullamcorper quam, in porttitor
                        libero. Suspendisse non porttitor dolor. Quisque porta, risus at ultrices
                        pharetra, dolor nisi rhoncus sapien, quis eleifend sem est nec turpis.
                        Maecenas ac ligula ut magna tempor blandit a feugiat arcu. Suspendisse dolor
                        velit, fringilla et aliquam quis, fermentum sit amet nulla. Curabitur
                        vulputate eget sem vel finibus. Cras quis gravida ex. Proin at urna vel odio
                        eleifend convallis. Nunc sed tortor turpis. Curabitur vitae nunc tincidunt,
                        rhoncus nulla sed, pharetra lacus. Donec placerat ullamcorper magna, vitae
                        feugiat sem tincidunt sed. Nunc venenatis, magna at sagittis faucibus, dolor
                        purus condimentum massa, bibendum luctus enim nibh nec urna. Ut eget augue
                        vel purus dictum euismod. Vestibulum justo ex, lobortis ac lorem non,
                        tristique facilisis nisi. Suspendisse id imperdiet mi. Proin congue, tortor
                        a bibendum lacinia, nibh purus lacinia dolor, quis vehicula leo ex in massa.
                        Nunc vel lacinia ante. Mauris a ligula felis. Vivamus eu pellentesque nisl,
                        et semper metus. Mauris sit amet nisi et lacus finibus porttitor a a velit.
                        Vivamus eget feugiat lacus. Vivamus venenatis mauris vel nisi sodales
                        rutrum. Vestibulum finibus commodo congue. Integer sed tellus ut augue
                        tempus sodales. Etiam imperdiet leo arcu, ac imperdiet odio interdum et.
                        Aliquam urna mauris, scelerisque at blandit a, dignissim rhoncus nibh.
                        Aliquam in ligula rutrum, efficitur ex in, rhoncus purus. Etiam at bibendum
                        ante, et ultricies dui. Mauris non odio eget tellus pulvinar laoreet ac nec
                        dolor. Proin sed nisi quis tortor interdum dictum nec eu lacus. Aenean
                        faucibus massa eget rutrum porta. Proin velit dolor, faucibus non malesuada
                        sed, posuere sed enim. Vivamus vulputate lorem a lorem laoreet interdum.
                        Aliquam non cursus magna. Praesent ac ultricies dolor, nec blandit massa.
                        Praesent dui libero, mollis eget sagittis vel, venenatis eget urna.
                        Pellentesque posuere, metus et aliquet tincidunt, ipsum magna faucibus sem,
                        vel facilisis risus tortor et ex. Mauris quis bibendum eros, fermentum
                        vehicula risus. Nam at tellus elementum, aliquam tortor id, vulputate dolor.
                        Donec eu mollis erat, et porttitor diam. Pellentesque faucibus, nibh aliquam
                        ullamcorper consequat, mi sem sodales elit, et malesuada dui leo et est.
                        Curabitur a nunc nisi. Mauris iaculis volutpat augue et pretium. Sed viverra
                        dapibus lectus vel cursus. Cras orci metus, hendrerit eget eros nec,
                        molestie dignissim mauris. Duis justo lorem, rhoncus in leo sit amet,
                        commodo rhoncus nibh. Pellentesque ac massa ipsum. Suspendisse pulvinar diam
                        vehicula orci scelerisque venenatis. Aliquam lobortis est risus, vitae
                        tempor diam pellentesque sit amet. Aliquam commodo, eros non finibus tempus,
                        sem eros euismod ligula, sed mattis magna nulla at urna. Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit. Etiam feugiat pretium sagittis. Etiam
                        mattis ex sit amet magna cursus scelerisque. Duis imperdiet ipsum sed dui
                        consequat mattis. Donec pretium nisi nibh, a convallis arcu ullamcorper in.
                        Phasellus ullamcorper sapien et gravida posuere. Donec vitae arcu nulla.
                        Maecenas et ullamcorper quam, in porttitor libero. Suspendisse non porttitor
                        dolor. Quisque porta, risus at ultrices pharetra, dolor nisi rhoncus sapien,
                        quis eleifend sem est nec turpis. Maecenas ac ligula ut magna tempor blandit
                        a feugiat arcu. Suspendisse dolor velit, fringilla et aliquam quis,
                        fermentum sit amet nulla. Curabitur vulputate eget sem vel finibus. Cras
                        quis gravida ex. Proin at urna vel odio eleifend convallis. Nunc sed tortor
                        turpis. Curabitur vitae nunc tincidunt, rhoncus nulla sed, pharetra lacus.
                        Donec placerat ullamcorper magna, vitae feugiat sem tincidunt sed. Nunc
                        venenatis, magna at sagittis faucibus, dolor purus condimentum massa,
                        bibendum luctus enim nibh nec urna. Ut eget augue vel purus dictum euismod.
                        Vestibulum justo ex, lobortis ac lorem non, tristique facilisis nisi.
                        Suspendisse id imperdiet mi. Proin congue, tortor a bibendum lacinia, nibh
                        purus lacinia dolor, quis vehicula leo ex in massa. Nunc vel lacinia ante.
                        Mauris a ligula felis. Vivamus eu pellentesque nisl, et semper metus. Mauris
                        sit amet nisi et lacus finibus porttitor a a velit. Vivamus eget feugiat
                        lacus. Vivamus venenatis mauris vel nisi sodales rutrum. Vestibulum finibus
                        commodo congue. Integer sed tellus ut augue tempus sodales. Etiam imperdiet
                        leo arcu, ac imperdiet odio interdum et. Aliquam urna mauris, scelerisque at
                        blandit a, dignissim rhoncus nibh. Aliquam in ligula rutrum, efficitur ex
                        in, rhoncus purus. Etiam at bibendum ante, et ultricies dui. Mauris non odio
                        eget tellus pulvinar laoreet ac nec dolor. Proin sed nisi quis tortor
                        interdum dictum nec eu lacus. Aenean faucibus massa eget rutrum porta. Proin
                        velit dolor, faucibus non malesuada sed, posuere sed enim. Vivamus vulputate
                        lorem a lorem laoreet interdum. Aliquam non cursus magna. Praesent ac
                        ultricies dolor, nec blandit massa. Praesent dui libero, mollis eget
                        sagittis vel, venenatis eget urna. Pellentesque posuere, metus et aliquet
                        tincidunt, ipsum magna faucibus sem, vel facilisis risus tortor et ex.
                        Mauris quis bibendum eros, fermentum vehicula risus. Nam at tellus
                        elementum, aliquam tortor id, vulputate dolor. Donec eu mollis erat, et
                        porttitor diam. Pellentesque faucibus, nibh aliquam ullamcorper consequat,
                        mi sem sodales elit, et malesuada dui leo et est. Curabitur a nunc nisi.
                        Mauris iaculis volutpat augue et pretium. Sed viverra dapibus lectus vel
                        cursus. Cras orci metus, hendrerit eget eros nec, molestie dignissim mauris.
                        Duis justo lorem, rhoncus in leo sit amet, commodo rhoncus nibh.
                        Pellentesque ac massa ipsum. Suspendisse pulvinar diam vehicula orci
                        scelerisque venenatis. Aliquam lobortis est risus, vitae tempor diam
                        pellentesque sit amet. Aliquam commodo, eros non finibus tempus, sem eros
                        euismod ligula, sed mattis magna nulla at urna.
            <Modal.Footer>
              <Button color="white" onClick={() => setLongCompact(false)}>
                                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </Variation>
      </Section>
      <Section title="Confirmations">
        <Variation hint="Confirmation - yellow">
          <Confirmation
            onConfirm={() => alert('Closing!')}
            trigger={({onClick}) => (
              <Button onClick={onClick} color="yellow">
                                Close window
              </Button>
            )}
            heading="Unsaved changes"
            buttonText="Yes, close"
            color="yellow"
          >
                        You have unsaved changes. If you close the window they will be lost. Are you
                        sure you want to close the window?
          </Confirmation>
        </Variation>

        <Variation hint="Confirmation - red">
          <Confirmation
            onConfirm={() => alert('Deleting!')}
            trigger={({onClick}) => (
              <Button onClick={onClick} color="red">
                                Delete user
              </Button>
            )}
            heading="Are you sure?"
            buttonText="Yes, delete"
            color="red"
          >
                        You are about to delete this user. This action is irreversible.
          </Confirmation>
        </Variation>
      </Section>
    </>
  )
}
