import {useStatefulResource} from '@rest-hooks/legacy'
import React, {FC, createContext, useCallback, useContext, useMemo, useState} from 'react'
import {useHistory} from 'react-router'
import {Route} from 'react-router-dom'
import {useFetcher, useInvalidator} from 'rest-hooks'

import {translate} from 'utils/translations'
import {Icon} from 'components/Icon'
import {Menu as CommonMenu, MenuItem} from 'components/Menu'
import {Pagination} from 'components/Pagination'
import {TemplateResource} from 'resources'
import {SortDirection} from 'views/template-clause/types'
import TemplateClauseContext from 'views/template-clause/context'

import TemplateCreateOverlay from './TemplateCreateOverlay'
import TemplateEditOverlay from './_id'

const SortIcon = ({
  direction
}: {
    direction: 'asc' | 'desc',
}) => (
  <Icon name={direction === 'asc' ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} size={5} />
)

const templatesSortableColumns = ['name'] as const
type TemplatesSortableColumn = typeof templatesSortableColumns[number];

interface IResourceType {
    keyword: string,
    page: number,
    sortBy: TemplatesSortableColumn,
    sortDirection: SortDirection
}

interface IContext {
    currentPage: number
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    templatesSortBy: TemplatesSortableColumn
    setTemplatesSortBy: React.Dispatch<React.SetStateAction<TemplatesSortableColumn>>
    templatesSortDirection: SortDirection
    setTemplatesSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>
    resourceParams: IResourceType
    refreshTemplates: () => void
}

export const Context = createContext<IContext>({
  currentPage: null as unknown as number,
  setCurrentPage: null as unknown as React.Dispatch<React.SetStateAction<number>>,
  templatesSortBy: null as unknown as TemplatesSortableColumn,
  setTemplatesSortBy: null as unknown as React.Dispatch<React.SetStateAction<TemplatesSortableColumn>>,
  templatesSortDirection: null as unknown as SortDirection,
  setTemplatesSortDirection: null as unknown as React.Dispatch<React.SetStateAction<SortDirection>>,
  resourceParams: null as unknown as IResourceType,
  refreshTemplates: null as unknown as () => void
})

const Provider: FC = ({children}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [templatesSortBy, setTemplatesSortBy] = useState<TemplatesSortableColumn>(templatesSortableColumns[0])
  const [templatesSortDirection, setTemplatesSortDirection] = useState<SortDirection>('asc')

  const {searchString} = useContext(TemplateClauseContext)

  const resourceParams = useMemo(() => ({
    keyword: searchString,
    page: currentPage,
    sortBy: templatesSortBy,
    sortDirection: templatesSortDirection
  }), [
    searchString,
    currentPage,
    templatesSortBy,
    templatesSortDirection
  ])

  const invalidateTemplates = useInvalidator(TemplateResource.list())
  const refreshTemplates = useCallback(() => {
    invalidateTemplates(resourceParams)
  }, [invalidateTemplates, resourceParams])

  const value = useMemo(() => ({
    currentPage,
    setCurrentPage,
    templatesSortBy,
    setTemplatesSortBy,
    templatesSortDirection,
    setTemplatesSortDirection,
    resourceParams,
    refreshTemplates
  }), [
    currentPage,
    setCurrentPage,
    templatesSortBy,
    setTemplatesSortBy,
    templatesSortDirection,
    setTemplatesSortDirection,
    resourceParams,
    refreshTemplates
  ])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

const Layout = () => {
  const history = useHistory()

  const {
    setCurrentPage,
    templatesSortBy,
    setTemplatesSortBy,
    templatesSortDirection,
    setTemplatesSortDirection,
    resourceParams,
    refreshTemplates
  } = useContext(Context)

  const {data: {data: templates, meta}} = useStatefulResource(TemplateResource.list(), resourceParams)

  const handleSort = useCallback((column) => {
    setTemplatesSortBy(column)
    setTemplatesSortDirection(column !== templatesSortBy || templatesSortDirection === 'desc' ? 'asc' : 'desc')
    setCurrentPage(1)
  }, [templatesSortBy, templatesSortDirection, setCurrentPage, setTemplatesSortBy, setTemplatesSortDirection])

  const deleteTemplate = useFetcher(TemplateResource.delete())
  const handleRemove = useCallback(async (template: TemplateResource) => {
    await deleteTemplate(template)
    refreshTemplates()
  }, [deleteTemplate, refreshTemplates])

  const {
    newTemplateVisible,
    newTemplateToggle
  } = React.useContext(TemplateClauseContext)

  return templates ? (
    <>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>
                <div className="flex items-center cursor-pointer" onClick={() => handleSort('name')}>
                  {translate('Name')}
                  {templatesSortBy === 'name' && templatesSortDirection && (
                    <SortIcon direction={templatesSortDirection} />
                  )}
                </div>
              </th>
              <th>{translate('Clauses')}</th>
              <th className="w-16"/>
            </tr>
          </thead>
          <tbody>
            {templates.map((template: TemplateResource) => (
              <tr key={template.id}>
                <td>
                  {template.name}
                </td>
                <td>
                  {template.clauses.length}
                </td>
                <td>
                  <CommonMenu handle="more_horiz">
                    <MenuItem
                      icon="edit"
                      onClick={() => history.push(`/template-clause/templates/${template.id}`)}
                    >
                      {translate('Edit')}
                    </MenuItem>
                    <MenuItem
                      icon="edit"
                      onClick={() => history.push(`/template-editor/${template.id}`)}
                    >
                      {translate('Open Editor')}
                    </MenuItem>
                    <MenuItem
                      icon="delete"
                      onClick={() => handleRemove(template)}
                    >
                      {translate('Delete')}
                    </MenuItem>
                  </CommonMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="flex justify-center pt-16">
          <Pagination
            currentPage={meta.current_page}
            totalPages={meta.last_page}
            totalBlocks={5}
            onSelect={(page) => {
              setCurrentPage(page)
            }}
          />
        </div>
      </div>
      {newTemplateVisible && (
        <TemplateCreateOverlay onDismiss={newTemplateToggle.off}/>
      )}
    </>
  ) : null
}

const View = () => (
  <Provider>
    <Route path="/template-clause/templates">
      <>
        <Layout />
        <Route path="/template-clause/templates/:id">
          <TemplateEditOverlay />
        </Route>
      </>
    </Route>
  </Provider>
)

export default View
