import {File, Project} from '@contractool/schema'
import React, {FC, useState} from 'react'
import {Link, useParams} from 'react-router-dom'

import {Modal} from 'components/Modal'
import {Icon} from 'components/Icon'
import {Button} from 'components/Button'
import {translate} from 'utils/translations'
import {EventEmitter} from 'utils/eventEmitter'
import {SidePanel} from 'views/projects/ProjectDetail'
import {Route, Switch} from 'react-router'
import {ContractDocument} from 'views/projects/ContractPanel'

interface ProjectRedFlagWidgetProps {
    project: Project,
    onClose: () => void
}


export const ProjectRedFlagWidget: FC<ProjectRedFlagWidgetProps> = ({project, onClose}) => {

  const [loading, setLoading] = useState(false)
  let textColor = 'white'
  if (project.fields_human.risk_profile === 'High') {
    textColor = 'red'
  }
  if (project.fields_human.risk_profile === 'Medium') {

    textColor = 'orange'
  }
  if (project.fields_human.risk_profile === 'Small') {
    textColor = 'gray'
  }
  EventEmitter.subscribe('contract-start', (data: any) => {
    console.log('ProjectRedFlagWidget on contract-start', data)
    setLoading(true)
  })
  EventEmitter.subscribe('contract-end', (data: any) => {
    console.log('ProjectRedFlagWidget on contract-end', data)
    setLoading(false)
  })


  return (
    <SidePanel heading={<>
      {loading && <span className="flex items-center "><img alt="loading" src="/images/loading-bar.gif"
        style={{height: '30px'}} className="mr-2" />Calculating risk profile...</span>}
      {!loading && <>{translate('Risk Profile')}
        {!project.fields_human.risk_profile && <Link
          to={`/projects/${project.id}/contract/red-flags/test`} className="text-sm"> - unprocessed</Link>}
      </>}
    </>}>
      {!loading && <>
        <div className="flex my-6 text-xl">
          <div className="rounded-full mr-5 my-auto"
            style={{height: '10px', width: '10px', backgroundColor: textColor}} />
          <h3 className="font-bolder">{project.fields_human.risk_profile ? project.fields_human.risk_profile : ''}</h3>
        </div>
        {project.extra.red_flags && project.extra.red_flags.length > 0 && <label
          className="block leading-none select-none text-gray-600 mt-5 mb-5">{`${project.extra.red_flags.length} ${translate('Reasons')} `}</label>}
        {project.extra.red_flags.map((flag: any) => {
          return <RedflagLine project={project} flag={flag} />
        })}
      </>}
      <Switch>
        <Route path={`/projects/${project.id}/contract/red-flags/:flag`}>
          <ProjectRedFlagWidgetDetail onClose={onClose} project={project} />
        </Route>
      </Switch>
    </SidePanel>
  )
}

export const RedflagLine: FC<{ project: Project, flag: any }> = ({project, flag}) => {
  let bgColor = '#ffe3e3'
  let textColor = '#fa5252'

  if (flag.risk === 'medium') {
    textColor = 'orange'
    bgColor = '#ff980036'
  }
  if (flag.risk === 'small') {
    textColor = 'gray'
    bgColor = 'lightgray'
  }

  return <Link
    to={`/projects/${project.id}/contract/red-flags/${flag.word}`}
    key={flag.word}
  >
    <div className="flex justify-between p-3 my-3 rounded border-red-200"
      style={{color: textColor, backgroundColor: bgColor}}>
      <div className="flex items-center">
        <span className="mx-2  font-bold bolder">{flag.word}</span>
        <span className="text-sm" style={{textTransform: 'capitalize'}}> - {flag.risk} risk</span>
      </div>
      <Icon name="arrow_forward" size={6} />
    </div>
  </Link>
}

interface ProjectRedFlagWidgetDetailProps {
    project: Project;
    onClose: () => void;
}

function formatHOcr(file: File, flag: string) {
  let hOcr = file.hocr_text?.replace(new RegExp(flag, 'ig'), `<span class="red-flag-highlighted">${flag}</span>`)
  setTimeout(() => {
    const els = document.querySelectorAll('.ocr_page, .ocr_line') // .ocr_carea, .ocr_par,

    var i = 0
    for (; i < els.length; ++i) {
      const el = els[i]
      const title = el.getAttribute('title') || ''
      const bboxPos = title.indexOf('bbox')
      if (bboxPos > -1) {
        var endPos = title.indexOf(';', bboxPos)
        if (endPos === -1)
          endPos = title.length
        const bboxCoordinates = title.substring(bboxPos + 4, endPos).trim().split(' ')
        const x0 = parseInt(bboxCoordinates[0]) || 0
        const y0 = parseInt(bboxCoordinates[1]) || 0
        const x1 = parseInt(bboxCoordinates[2]) || 0
        const y1 = parseInt(bboxCoordinates[3]) || 0
        const width = x1 - x0 + 100 // 1 ( + 10 only for ocr_line)
        const height = y1 - y0 + 1

        // @ts-ignore
        el.style.cssText = 'font-family: sans-serif; position: absolute; left: ' + x0 + 'px; top: ' + y0 + 'px; width: ' + width + 'px; height: ' + height + 'px;'
      }
    }

    const ocrPage = document.querySelector('.ocr_page')

    const outputDiv = document.getElementById('hocrOutputDiv')
    if (outputDiv && ocrPage) {
      // @ts-ignore
      outputDiv.style.cssText = 'font-family: sans-serif; position: relative; width: ' + ocrPage.style.width + '; max-width: 1200px; height: ' + ocrPage.style.height + ';'
    }
  }, 500)

  return hOcr
}


export const ProjectRedFlagWidgetDetail: FC<ProjectRedFlagWidgetDetailProps> = ({onClose, project}) => {
  const {flag} = useParams() as { flag: string }

  const contract = project.attachments.find(attachment => attachment.id === project.document_id)
  let file = contract?.versions[contract.versions.length - 1].file
  let plainText = ''
  let hOcr = ''
  if (file && file.hocr_text) {
    hOcr = formatHOcr(file, flag)
  }
  if (file && file.plain_text && !file.hocr_text) {
    plainText = file
      .plain_text
      ?.replace(new RegExp(flag, 'ig'), `<span class="red-flag-highlighted">${flag}</span>`)
      .replaceAll(/\n/g, '<br>')
            || ''
  }
  setTimeout(() => {
    let list = document.getElementsByClassName('red-flag-highlighted')
    if (list.length > 0) {
      list[0].scrollIntoView()
    }
  }, 300)

  let flagObject = project.extra.red_flags.filter((fObject: any) => {
    return fObject.word === flag
  })[0]

  return (
    <Modal onClose={onClose} size="w-1200">
      <div className="flex flex-col" style={{height: '700px'}}>
        <ContractDocument project={project} />
        <RedflagLine project={project} flag={flagObject} />
        <div className="py-5 overflow-auto mt-8" style={{flex: '1'}}>
          {plainText !== '' && <div dangerouslySetInnerHTML={{__html: plainText}} />}
          {hOcr !== '' && <div id="hocrOutputDiv">
            <div dangerouslySetInnerHTML={{__html: hOcr}} />
          </div>}
        </div>
        <Modal.Footer className="flex justify-between flex-row-reverse">
          <Button onClick={onClose}>
            {translate('Close')}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  )
}
