import React, {useState} from 'react'

import {Form} from 'components/Form'
import {Document} from '@contractool/schema'
import {translate} from 'utils/translations'
import {useRequest} from 'hooks/useRequest'
import {getOptions} from 'utils/form'
import {DocumentUpload} from 'views/projects/DocumentUpload'
import {http} from 'utils/http'
import {AiCollection} from '../../../../../_common/schema/AiCollection'

const TaskForm = ({
  setDocuments,
  documents
}: {
  setDocuments: (value: any) => void;
  documents: any[];
}) => {

  const [collections] = useRequest<AiCollection[]>('/api/ai/collections', [])
  const [sending, setSending] = useState(0)
  const collectionsAsOptions = getOptions(collections)
  const onSend = (file: {path: string; name: string}) => {
    setSending((prev) => prev + 1)

    return http.post<Document>('/api/ai/documents/attachments', {file})
  }

  const onUpdate = (data: any) => {
    setDocuments((prevDocs: number[]) => ([...prevDocs, {id: data?.id, name: data.title}]))
  }

  return (
    <>
      <label htmlFor="new-files" className="block leading-none select-none text-gray-500">
        {translate('Upload new files')}
      </label>
      <div id="new-files" className="pt-6 pb-8 mb-12 border-gray-200 border-b">
        <DocumentUpload onSend={onSend} onUpload={onUpdate} singleFile={false} />
        <div className="mt-6 block leading-none select-none text-gray-500">
          {`Documents uploaded: ${documents.length}/${sending}`}
        </div>
        {/* {
          docs?.map((_document, index) => {
            return (
              <div key={index}>
                <DocumentComponent
                  document={_document}
                  change={false}
                  deletable={false}
                  download={false}
                  modalUrl={'preview'}
                />
              </div>
            )
          })
        } */}
      </div>

      <Form.DynamicMultiSelect
        name="collections"
        label={translate('Collections')}
        placeholder={translate('Select Collections..')}
        options={collectionsAsOptions}
        autocomplete
      />
    </>
  )
}

export default TaskForm
