import {Template} from '@contractool/schema'
import React, {useContext} from 'react'
import {useFetcher} from 'rest-hooks'

import {TemplateOverlay} from 'components/template-clause/template'
import {useToasts} from 'hooks'
import {TemplateResource} from 'resources'
import {translate} from 'utils/translations'
import {Context as TemplatesContext} from 'views/template-clause/tab/templates'

const initialValues: Template = {
  id: 0,
  name: '',
  categories: [],
  clauses: []
}

const TemplateCreateOverlay: React.FC<{
  onDismiss: () => void
}> = ({onDismiss}) => {
  const createTemplate = useFetcher(TemplateResource.create())
  const {success} = useToasts()

  const {
    refreshTemplates
  } = useContext(TemplatesContext)

  return (
    <TemplateOverlay
      cta={translate('Add template')}
      heading={translate('Add template')}
      initialValues={initialValues}
      onDismiss={onDismiss}
      onSubmit={async (template) => {
        await createTemplate({}, template)
        onDismiss()
        success(translate('Template is successfully created.'))
        await refreshTemplates()
      }}
    />
  )
}

export default TemplateCreateOverlay
