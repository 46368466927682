import React, {FC, useState} from 'react'
import {Form} from 'components/Form'
import {Icon} from 'components/Icon'
import {translate} from 'utils/translations'

const ProjectTitle: FC<{className?: string}> = ({className}) => {
  const [editTitle, setEditTitle] = useState<boolean>(false)

  return editTitle ? (
    <Form.TextInput
      name="title"
      label="Title"
      placeholder={translate('Title')}
      autoFocus
      required={true}
      className={className}
    />
  ) : (
    <Form.Context>
      {({values}) => (
        <div className={`${className}`}>
          <div className="text-gray-600 mb-1">Title</div>
          <div className="flex justify-between items-center bg-gray-100 rounded-lg py-3 pl-3 pr-6">
            <span
              className={`${values.title !== '' ? 'text-blue-500' : 'text-gray-500'}`}
            >
              {values.title !== ''
                ? values.title
                : translate('Title is automatically generated')}
            </span>
            <span onClick={() => setEditTitle(true)} className="cursor-pointer">
              <Icon
                size={5}
                name="edit"
                className="text-blue-700 hover:text-blue-500"
              />
            </span>
          </div>
        </div>
      )}
    </Form.Context>
  )
}

export default ProjectTitle
