import React, {useContext} from 'react'
import {Icon} from 'components/Icon'
import {SidePanel} from '../../../../ProjectDetail'
import {useToggle} from 'hooks'
import {ExportButton, MemberModal} from './components'
import {Tab, Group} from './interfaces'
import ProjectContext from 'components/../pages/projects/ProjectContext'
import {translate} from 'utils/translations'

const Member: React.FC<{memberKey: string; group: Tab | Group}> = ({memberKey, group}) => {
  const [modalVisible, modalVisibilityToggle] = useToggle(false)

  const hasTabs = 'tabs' in group

  const {resource: project} = useContext(ProjectContext)
  const hasExportUrl = Boolean(project.meta?.widget_fields && project.meta.widget_fields[memberKey]?.export_url)

  return (
    <SidePanel
      heading={translate(group.name)}
      right={
        <div className="flex items-center">
          {hasTabs && hasExportUrl ? (
            <ExportButton
              exportUrl={project.meta.widget_fields[memberKey].export_url}
            />
          ) : (
            ''
          )}
          <div
            className="cursor-pointer text-blue-700 flex items-center"
            onClick={modalVisibilityToggle.on}
          >
            <Icon name="edit" size={5} />
            <span className="ml-2">{translate('Edit')}</span>
          </div>
        </div>
      }
    >
      <MemberModal
        memberKey={memberKey}
        group={group}
        isOpen={modalVisible}
        heading={translate(group.name)}
        closeModal={modalVisibilityToggle.off}
      />
    </SidePanel>
  )
}

const PureMember = React.memo(Member)

export default PureMember
