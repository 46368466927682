import React, {useCallback} from 'react'
import {Modal} from 'components/Modal'
import {translate} from 'utils/translations'
import {useHistory} from 'react-router'
import {Form} from 'components/Form'
import {http} from 'utils/http'
import {useToasts} from 'hooks'
import {Button} from 'components/Button'
import CollectionForm from './Form'
import {AiCollection} from '../../../../../_common/schema/AiCollection'

const NewCollection = ({
  onCreate,
  closeUrl
}: {
  onCreate: (collection: AiCollection) => void;
  closeUrl: string;
}) => {
  const history = useHistory()
  const close = useCallback(() => {
    history.push(closeUrl)
  }, [history, closeUrl])

  const handleSubmit = useCallback((values) => http.post<AiCollection>('/api/ai/collections', values), [])
  const {success} = useToasts()
  const handleSuccess = useCallback(
    (collection) => {
      console.log(collection)

      onCreate(collection)
      success(
        `${translate('Ai :collection was added successfully', {
          collection: collection.name
        })}`
      )
      close()
    },
    [onCreate, close, success]
  )

  return (
    <Modal heading={translate('Add Collection')} onClose={close}>
      <Form
        initialValues={{
          name: ''
        }}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
      >
        <CollectionForm />
        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={close}>
            {translate('Cancel')}
          </Button>

          <Form.Submit>{translate('Add collection')}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default NewCollection
