import {FieldTemplate, Project} from '@contractool/schema'
import React, {FC} from 'react'

interface IFieldAtTopProps {
  field: FieldTemplate
  project: Project
}

const FieldAtTop: FC<IFieldAtTopProps> = ({field, project}) => (
  <div className="p-8 border-b border-gray-200">
    <div className="mb-4 text-lg">
      {field.label}
    </div>
    {project.fields_human[field.name]}
  </div>
)

export default FieldAtTop
