import {FieldTemplate, Project} from '@contractool/schema'
import React, {FC, useContext, useState} from 'react'
import {SidePanel} from 'views/projects/ProjectDetail'
import {AppContext} from 'contexts'
import {Icon} from 'components/Icon'
import {EventEmitter} from 'utils/eventEmitter'
import {translate} from 'utils/translations'

interface ProjectAiExtractedDataWidgetProps {
  project:Project
}

export const ProjectAiExtractedDataWidget: FC<ProjectAiExtractedDataWidgetProps> = ({project}) => {
  const {config} = useContext(AppContext)
  const [loading, setLoading] = useState(false)

  const extractableFields = config.fields
    ? config.fields.filter((f: FieldTemplate) => f.workflow === project?.workflow && f.extractable)
    : []

  let fieldsLoaded = extractableFields.filter((field: FieldTemplate) => !!project.fields_human[field.name]).length > 0
  const [loaded, setLoaded] = useState(fieldsLoaded)

  EventEmitter.subscribe('contract-start', (data: any) => {
    console.log('ProjectRedFlagWidget on contract-start', data)
    setLoading(true)
  })
  EventEmitter.subscribe('contract-end', (data: any) => {
    console.log('ProjectRedFlagWidget on contract-end', data)
    setLoading(false)
    setLoaded(true)
  })


  return (
    <>
      {loading && <SidePanel heading={<div className="flex">
        <span className="flex items-center"><img alt="loading" src='/images/loading-bar.gif' style={{height: '30px'}} className="mr-2" />Extracting properties...</span>
      </div>}/>}
      {!loading && !loaded && <SidePanel heading={<>{translate('Extracted properties')}
        <span className="text-sm"> - unprocessed</span>
      </>}/>}
      {!loading && loaded && <SidePanel heading="Extracted properties">
        {extractableFields.map((field: FieldTemplate) => {
          let found = project.fields_human[field.name]

          return <div className="p-4 pl-0 border-b border-gray-200 flex" key={field.name}>
            <div className="flex items-center justify-center pr-6">
              {!!found &&
                        <Icon name="done" size={8} className="p-1 text-green-700 bg-green-100 rounded-full"/>}
              {!found && <Icon name="remove_circle" size={8} className="text-gray-500"/>}
            </div>
            <div className="flex-1">
              <div className="text-md flex justify-between">
                <div>{field.label}</div>
                <div className="ml-8 flex items-center">
                  {/*{!!found &&*/}
                  {/*              <Link to={`/projects/${project.id}/contract/red-flags/${found}`}>*/}
                  {/*                <Icon name="remove_red_eye" size={6}*/}
                  {/*                  className="text-gray-500 mr-2 cursor-pointer pt-1"/>*/}
                  {/*              </Link>}*/}
                  {project.fields_human[field.name]}
                </div>
              </div>

            </div>
          </div>
        })}
      </SidePanel>
      }
    </>
  )}
