import React from 'react'
import {Document} from '@contractool/schema'
import {Modal} from '../Modal'
import {Form} from '../Form'
import {http} from '../../utils/http'
import {useToasts} from 'hooks'
import {DocumentUpload} from '../../pages/projects/DocumentUpload'
import {Icon} from '../Icon'
import {parseISO, format} from 'date-fns'
import {sign} from '../../utils/auth'
import {translate} from 'utils/translations'
import {Button} from '../Button'

export function DocumentDetail({
  document,
  onUpdate,
  onClose
}: {
    document: Document;
    onUpdate: () => void;
    onClose: () => void;
}) {
  const {success} = useToasts()

  const onSend = (file: {path: string; name: string}) => {
    return http.post<Document>(document.versions_url, {file})
  }

  return (
    <Modal heading={translate('File detail')} onClose={onClose}>
      <Form
        initialValues={{...document}}
        onSubmit={(values) => http.put<Document>(document.url, values)}
        onSuccess={(document: Document) => {
          onClose()
          onUpdate()
          success(
            translate('Document :file was updated successfully', {
              file: document.title
            })
          )
        }}
      >
        <Form.TextInput
          name="title"
          label={translate('Name')}
          className="mb-12"
          autoFocus={true}
        />

        <label
          htmlFor="new-version"
          className="block leading-none select-none text-gray-600"
        >
          {translate('Upload new version')}
        </label>
        <div id="new-version" className="pt-6 pb-8 mb-12 border-gray-200 border-b">
          <DocumentUpload onSend={onSend} onUpload={onUpdate} singleFile={true} />
        </div>

        <div className="block leading-none select-none text-gray-600  pb-6">
          {document.versions.length}{' '}
          {document.versions.length === 1 ? translate('Version') : translate('Versions')}
        </div>
        <div>
          {document.versions
            .slice(0)
            .reverse()
            .map((version, index) => (
              <div
                key={index}
                className="flex items-center leading-tighter pt-7 pb-7 border-gray-200 border-b"
              >
                <Icon name="description" size={6} className="mr-4" />
                <span className="flex-grow mr-12">{version.file.filename}</span>
                <span className="text-gray-600 mr-12">
                  {format(parseISO(version.created_at), 'dd MMM `yyyy')}
                </span>
                <a
                  href={sign(version.preview_url)}
                  className="mr-3 text-gray-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="remove_red_eye" size={6} />
                </a>
                <a
                  href={sign(version.download_url)}
                  className="mr-3 text-gray-500"
                  download
                >
                  <Icon name="get_app" size={6} />
                </a>
                <a
                  href="/"
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    let filteredVersions = document.versions.filter(
                      (v) => v.id !== version.id
                    )
                    http.post(document.versions_url, {
                      versions: filteredVersions
                    }).then(() => {
                      onClose()
                      onUpdate()
                      success(
                        translate('Version :index was deleted.', {
                          index: `${index}`
                        })
                      )
                    })
                  }}
                >
                  {/* <Icon name="delete" size={6} className="text-gray-500" /> */}
                </a>
              </div>
            ))}
        </div>

        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={onClose}>
            {translate('Cancel')}
          </Button>

          <Form.Submit>{translate('Save Changes')}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
