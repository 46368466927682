import {Icon} from 'components/Icon'
import {renderedTinyMceContentStyle} from 'components/TinymcePluginMDF'
import React, {FC} from 'react'
import {useDrag, useDrop} from 'react-dnd'
import {ClauseResource} from 'resources'
import {clausesTpTypes} from './TemplateEditor'

const TemplateClauseItem: FC<{
  clause: ClauseResource
  idIndex: number
  id: number
  moveTpClause(draggedId:number, id:number): void
  deleteTpClause(tpClauseIndex:number): void
  addTpClause(clause:ClauseResource, index:number): void
}> = ({clause, idIndex, id, moveTpClause, deleteTpClause, addTpClause})=>{
  const [clauseOpened, setClauseState] = React.useState(true)
  const [clauseLocked/*, setClauseLocked */] = React.useState(false)

  const ref = React.useRef(null)

  const [, connectDrag] = useDrag({
    type: clausesTpTypes.tpClause,
    item: {id, idIndex},
    canDrag:!clauseLocked,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const [{isOver}, connectDrop] = useDrop({
    accept: [clausesTpTypes.clause, clausesTpTypes.tpClause],
    collect:(monitor) => ({
      isOver: monitor.isOver() && !clauseLocked,
      canDrop: monitor.canDrop()
    }),
    drop({id: draggedId, idIndex:draggedIdIndex, clause:draggedClause}: {id: number, type: string, idIndex:number | undefined, clause:ClauseResource}) {
      if (draggedId !== id && !clauseLocked) {
        if (typeof draggedIdIndex != 'undefined')
          moveTpClause(draggedIdIndex, idIndex)
        else {
          addTpClause(draggedClause, idIndex )
        }
      }
    }
  })

  connectDrag(ref)
  connectDrop(ref)

  const droppingStyle = {
    borderTop:isOver ? 'solid 3px #d4e4ff' : ''
  }

  return (
    <div className="border-b border-gray-100 py-6" style={droppingStyle} ref={ref} >
      <div className="flex">
        <div className="flex">
          {!clauseOpened && (
            <div className="leading-tighter text-gray-900 uppercase flex items-center">
              {clause.name}
              {/* <span onClick={() => setClauseLocked(s => !s)} className="cursor-pointer text-gray-600 flex-grow"> */}
              {/*  <Icon name={clauseLocked ? 'lock' : 'lock_open'} size={5}/> */}
              {/* </span> */}
              <span onClick={() => deleteTpClause(idIndex)} className="cursor-pointer ml-3 text-gray-600">
                <Icon name='delete' size={5}/>
              </span>
            </div>
          )}
        </div>
        <div className="flex-grow flex justify-end text-gray-600 flex">
          <div onClick={() => setClauseState(s => !s)} className="cursor-pointer">
            <Icon name={clauseOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} size={5} />
          </div>
        </div>
      </div>

      {clauseOpened && (
        <div className="w-full mt-2 text-gray-600">
          <style>{renderedTinyMceContentStyle}</style>
          <div className="clause-content" dangerouslySetInnerHTML={{__html: clause.content}} />
        </div>
      )}
    </div>
  )
}

export default TemplateClauseItem
