import React, {FC} from 'react'

const ExecutedSign: FC<{title: string, children: React.ReactNode}> = ({title, children}) => {
  return (
    <div className="rounded-xl bg-gray-000 p-6">
      <div className="text-gray-400">
        {title}
      </div>
      <div className="flex items-center mt-4">
        {children}
      </div>
    </div>
  )
}

export default ExecutedSign
