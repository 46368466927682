import * as React from 'react'

import {Context as ReportsTabContext} from 'contexts/reports/tab'
import {Button} from 'components/Button'
import {translate} from 'utils/translations'

const ReportCreateButton = () => {
  const {idx: selectedTabIdx} = React.useContext(ReportsTabContext)

  return selectedTabIdx !== null ? (
    <Button.Link to={`/reports/${selectedTabIdx}/create`} color="blue" icon="add">
      {translate('Add report')}
    </Button.Link>
  ) : null
}

export default ReportCreateButton
