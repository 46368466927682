import React, {FC} from 'react'
import {Supplier} from '../../../../../_common/schema'
import {useToasts} from 'hooks'
import {Menu, MenuItem} from '../../../components/Menu'
import {Confirmation} from '../../../components/Confirmation'
import {http} from '../../../utils/http'
import {translate} from 'utils/translations'

export const SupplierMenu: FC<{
    supplier: Supplier;
    onRefresh: () => void;
    onClose: () => void;
}> = ({supplier, onRefresh, onClose}) => {
  const {success} = useToasts()

  return (
    <Menu handle="more_vert">
      <Confirmation
        onConfirm={() => {
          http.delete(supplier.delete_url).then(() => {
            onClose()
            onRefresh()
            success(
              `${translate('Supplier :title was deleted', {title: supplier.title})}.`
            )
          })
        }}
        trigger={({onClick}) => (
          <MenuItem
            icon="remove_circle"
            onClick={(e) => {
              e.stopPropagation()
              onClick()
            }}
          >
            Delete
          </MenuItem>
        )}
        heading={translate('Delete supplier')}
        buttonText={translate('Yes, delete')}
        color="red"
      >
        {translate(
          'Are you sure you want to delete supplier :title? You can\'t undo this action.',
          {title: supplier.title}
        )}
      </Confirmation>
    </Menu>
  )
}
