import {DocumentCategory, Document} from '@contractool/schema'
import React, {useContext, useState, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'
import {Route, Switch, useHistory} from 'react-router'
import {useParams} from 'react-router-dom'
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar'

import {AppContext} from 'contexts'
import {Page} from 'components/Page'
import {Tabs, Tab} from 'components/Tabs'
import {DocumentComponent} from 'components/documents/Document'
import {DocumentDetail} from 'components/documents/DocumentDetail'
import {useUploadingReducer} from 'components/Avatar'
import {Icon} from 'components/Icon'
import {Menu, MenuItem} from 'components/Menu'
import {MainPanel} from 'components'
import {useToggle} from 'hooks'
import {sign} from 'utils/auth'
import {getOptions} from 'utils/form'
import {useRequest} from 'utils/hooks/useRequest'
import {http} from 'utils/http'
import {upload} from 'utils/upload'
import {translate} from 'utils/translations'

import CategoriesOverlay from './CategoriesOverlay'

export function Library() {
  const {config} = useContext(AppContext)

  const [categories, {refresh: refreshCategories}] = useRequest<DocumentCategory[]>('/api/library/categories', [])
  const [_documents, {refresh: refreshDocuments}] = useRequest<Document[]>('/api/library', [])
  const [{uploading, progress}, dispatch] = useUploadingReducer()
  const [tab, setTab] = useState(0)

  const documents = useMemo(() => {
    return _documents.filter((doc) => doc.type !== 'ai')
  }, [_documents])
 
  const startUpload = (file: File) => {
    console.log('START UPLOAD', file)
    dispatch({type: 'start_uploading'})
    upload(file, {
      onUploadProgress: (progress) => {
        dispatch({type: 'progress_uploading', progress})
      }
    }).then((response) => {
      console.log('Upload finished', response)
      http.post<Document>('/api/library', {
        file: response,
        category_id: tab
      }).then(() => {
        dispatch({type: 'finish_uploading'})
        refreshDocuments()
      })
    })
  }

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (files) => {
      files.forEach((file) => startUpload(file))
    }
  })

  const optCtgs = getOptions(categories)
  optCtgs.unshift({label: translate('Uncategorized'), value: 0})

  const [categoriesOverlayVisible, categoriesOverlayToggle] = useToggle(false)
  const handleSelect = (tabIdString: string) => {
    setTab(Number(tabIdString))
  }

  return (
    <Page heading={translate('Library')}>
      <MainPanel heading={translate('Upload new document')} className=" rounded-lg p-5"
        right={<Menu handle="settings">
          <MenuItem
            icon="list"
            onClick={categoriesOverlayToggle.on}
            nowrap
          >
            {translate('Manage categories')}
          </MenuItem>
        </Menu>}>
        {uploading && (
          <div className="rounded-full w-full h-full bg-gray-000 flex items-center justify-center">
            <CircularProgressbar
              value={progress}
              className="w-6 h-6"
              strokeWidth={5}
              styles={buildStyles({
                pathTransitionDuration: 0.5,
                pathColor: '#2979ff',
                trailColor: '#e9ecef'
              })}
            />
          </div>
        )}
        {config.config.office365 && (
          <div>
            <a
              href={sign(`/document/new-library/${tab}`)}
              className="flex items-center mt-5 text-blue-700"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="add" size={5}/>
              <span className="ml-2">{translate('Create new template online')}</span>
            </a>

            <div className="my-5">{translate('Or')}</div>
          </div>
        )}
        <div className={'focus:outline-none mt-5'} {...getRootProps()} onClick={() => {
        }}>
          <input type="file" className="hidden" {...getInputProps()} />

          <div className="bg-gray-000 text-gray-600 p-7 rounded-xl flex items-center justify-center">
            <Icon name="cloud_upload" size={6}/>
            <div className="pl-3">
              {translate('Drop file here or')}{' '}
              <span
                className="text-blue-700 cursor-pointer focus:outline-none"
                {...getRootProps({props: 'onClick'})}
              >
                {translate('browse it')}
              </span>
            </div>
          </div>
        </div>
      </MainPanel>
      <Tabs selected="0" className="bg-white px-5" onSelect={handleSelect}>
        <Tab
          name="0"
          heading={translate('Uncategorized')}
          className="pt-4 bg-white p-5"
        >
          {documents
            .filter((document) => !document.category_id)
            .map((document, index) => {
              return (
                <DocumentComponent
                  document={document}
                  key={index}
                  modalUrl="library"
                  onDelete={refreshDocuments}
                />
              )
            })}
        </Tab>
        {categories.map((category, index) => {
          return (
            <Tab
              key={'document-category' + index}
              name={`${category.id}`}
              heading={translate(category.title)}
              className="pt-4 bg-white p-5"
            >
              {documents
                .filter((document) => document.category_id === category.id)
                .map((document, index) => {
                  return (
                    <DocumentComponent
                      document={document}
                      key={index}
                      modalUrl="library"
                      onDelete={refreshDocuments}
                    />
                  )
                })}
            </Tab>
          )
        })}
      </Tabs>
      {categoriesOverlayVisible && (
        <CategoriesOverlay
          categories={categories}
          documents={documents}
          refreshCategories={refreshCategories}
          onDismiss={categoriesOverlayToggle.off}
        />
      )}
      <Switch>
        <Route path={'/library/documents/:documentId'}>
          <LibraryDocumentDetail documents={documents} onUpdate={refreshDocuments}/>
        </Route>
      </Switch>
    </Page>
  )
}

export function LibraryDocumentDetail({
  documents,
  onUpdate
}: {
    documents: Document[];
    onUpdate: () => void;
}) {
  const {documentId} = useParams<{documentId?: string}>()
  const history = useHistory()
  const document = documents.find((t) => documentId && t.id === parseInt(documentId))
  const close = () => {
    history.push('/library')
  }

  if (!document) return null

  return <DocumentDetail document={document} onUpdate={onUpdate} onClose={close}/>
}
