import {FinancialQuarter} from '@contractool/schema'
import React, {FC} from 'react'

import {translate} from 'utils/translations'
import {useHistory} from 'react-router'
import ReactDOM from 'react-dom'
import {Button} from 'components/Button'

interface IFinancialQuarters {
  financialQuarters: FinancialQuarter[]
}

const List: FC<IFinancialQuarters> = ({
  financialQuarters
}) => {
  const history = useHistory()
  const buttonContainer = document.getElementById('page-header-right')

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>{translate('Year')}</th>
            <th>{translate('Quarter 1')}</th>
            <th>{translate('Quarter 2')}</th>
            <th>{translate('Quarter 3')}</th>
            <th>{translate('Quarter 4')}</th>
          </tr>
        </thead>
        <tbody>
          {financialQuarters.map((financialQ) => (
            <tr
              key={financialQ.id}
              className="hover:bg-gray-000 cursor-pointer rounded"
              onClick={() => history.push(`/settings/financial-quarters/${financialQ.id}/edit`)}
            >
              <td>{financialQ.year}</td>
              <td className="text-gray-600">{financialQ.qt1}</td>
              <td>{financialQ.qt2}</td>
              <td className="text-gray-600">{financialQ.qt3}</td>
              <td>{financialQ.qt4}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {buttonContainer && ReactDOM.createPortal((
        <Button.Link to="/settings/financial-quarters/new" color="blue" icon="add">
          {translate('Add financial quarters')}
        </Button.Link>
      ), buttonContainer)}
    </>
  )
}

export default List
