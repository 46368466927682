import {FilterTemplate} from '@contractool/schema'
import * as React from 'react'
import {useHistory} from 'react-router'
import {Redirect, Route, Switch, useParams} from 'react-router-dom'

import {translate} from 'utils/translations'
import TemplateClauseContext from 'views/template-clause/context'
import {Page} from 'components'
import {Button} from 'components/Button'
import {Menu, MenuItem} from 'components/Menu'
import Search from 'components/Search'
import {Tabs, Tab} from 'components/Tabs'
import {useRequest} from 'hooks/useRequest'
import {useToggle} from 'hooks'

import TabView from './tab'
import TemplateCategoriesOverlay from './TemplateCategoriesOverlay'
import ClauseCategoriesOverlay from './ClauseCategoriesOverlay'

const tabs = [
  {
    name: 'templates',
    heading: 'Templates'
  },
  {
    name: 'clauses',
    heading: 'Clauses'
  }
]

const Layout = () => {
  const history = useHistory()
  const {tab} = useParams() as {tab: string}

  const {
    searchString,
    updateSearchString,
    newTemplateToggle,
    newClauseToggle
  } = React.useContext(TemplateClauseContext)
  const clearSearch = React.useCallback(() => {
    updateSearchString('')
  }, [updateSearchString])

  const updateSelectedTab = (tabName: string) => {
    history.push(`/template-clause/${tabName}`)
  }

  const [templateCategoriesVisible, templateCategoriesOverlayToggle] = useToggle(false)
  const [clauseCategoriesVisible, clauseCategoriesOverlayToggle] = useToggle(false)

  return (
    <Page
      heading={translate('Templates')}
      right={<div>
        <Button
          className="mr-3"
          color="white"
          icon="add"
          onClick={newClauseToggle.on}
        >
          {translate('Add clause')}
        </Button>
        <Button
          color="blue"
          icon="add"
          onClick={newTemplateToggle.on}
        >
          {translate('Add template')}
        </Button>
      </div>}
    >
      <div className="flex items-center mb-12">
        <div className="flex-1">
          <Search
            value={searchString}
            onChange={updateSearchString}
            onClear={clearSearch}
            placeholder={`${translate('Search')}...`}
          />
        </div>
        <div>
          <Menu handle="settings">
            <MenuItem
              icon="list"
              onClick={templateCategoriesOverlayToggle.on}
              nowrap
            >
              {translate('Template categories')}
            </MenuItem>
            <MenuItem
              icon="list"
              onClick={clauseCategoriesOverlayToggle.on}
              nowrap
            >
              {translate('Clause categories')}
            </MenuItem>
          </Menu>
        </div>
      </div>
      <Tabs onSelect={updateSelectedTab} selected={tab}>
        {tabs.map(tab => (
          <Tab
            key={tab.name}
            name={tab.name}
            heading={
              <div>
                {translate(tab.heading)}
              </div>
            }
          >
            <TabView/>
          </Tab>
        ))}
      </Tabs>
      {templateCategoriesVisible && (
        <TemplateCategoriesOverlay onDismiss={templateCategoriesOverlayToggle.off}/>
      )}
      {clauseCategoriesVisible && (
        <ClauseCategoriesOverlay onDismiss={clauseCategoriesOverlayToggle.off}/>
      )}
    </Page>
  )
}

const Provider: React.FC = ({children}) => {
  const [searchString, setSearchString] = React.useState('')
  const updateSearchString = React.useCallback((query: string) => {
    setSearchString(query)
  }, [])

  const [loaded, setLoaded] = React.useState(false)
  const [filterTemplates] = useRequest<FilterTemplate[]>('/api/project-filter-templates', [], {}, setLoaded)
  const metaDataFields = filterTemplates.map((template: FilterTemplate) => ({
    value: template.name,
    text: template.label
  }))

  const [newTemplateVisible, newTemplateToggle] = useToggle(false)
  const [newClauseVisible, newClauseToggle] = useToggle(false)

  const value = React.useMemo(() => ({
    searchString,
    updateSearchString,
    metaDataFieldsLoaded: loaded,
    metaDataFields,
    newTemplateVisible,
    newTemplateToggle,
    newClauseVisible,
    newClauseToggle
  }), [
    searchString,
    updateSearchString,
    loaded,
    metaDataFields,
    newTemplateVisible,
    newTemplateToggle,
    newClauseVisible,
    newClauseToggle
  ])

  return <TemplateClauseContext.Provider value={value}>{children}</TemplateClauseContext.Provider>
}

const Routes = () => (
  <Switch>
    <Route path="/template-clause" exact>
      <Redirect to="/template-clause/templates"/>
    </Route>
    <Route path="/template-clause/:tab">
      <Layout />
    </Route>
  </Switch>
)

const View = () => (
  <Provider>
    <Routes/>
  </Provider>
)

export default View
