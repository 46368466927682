import {FieldTemplate, Report} from '@contractool/schema'
import React, {ComponentType} from 'react'
import {Option} from 'components/Dropdown'

type Props = {
    report: Report,
    dateFilters: any,
    projectFilter?: any,
    usersAsOptions: Option<any>[],
    suppliersAsOptions: Option<any>[],
}

type WithReportFilterProps = {
    selectedFilters: any,
    handleFilterUpdate: (customFilter: any) => void,
    report: Report,// todo: remove
    setSelectedFilters: any,// todo: remove
    filterSettings: FieldTemplate[],
    projectFilter?: any,
    usersAsOptions: Option<any>[],
    suppliersAsOptions: Option<any>[],
}

type State = {
    selectedFilters: any,
    filterSettings: FieldTemplate[],
}

const lobs = {
  CSG: ['', 'Consumer', 'Commercial', 'Incremental', 'EIG'],
  ISG: [
    '',
    'Dell Technologies Cloud',
    'Integrated Products and Solutions',
    'OCTO',
    'SIS',
    'Storage',
    'Telco'
  ]
}

const productGroups = {
  Consumer: ['', 'Alienware', 'Inspiron', 'Innovation POC', 'Vostro', 'XPS', 'XLOB'],
  Commercial: ['', 'Innovation POC', 'Latitude', 'Precision', 'Optiplex', 'XLOB'],
  'Dell Technologies Cloud': ['', 'DTCP', 'DTCP Other'],
  'Integrated Products and Solutions': ['', 'CTO', 'HPC', 'Networking', 'Solutions', 'VxRail'],
  OCTO: ['', 'CTO'],
  SIS: ['', 'CET', 'CTO', 'OEM', 'POC', 'PowerEdge', 'XE'],
  Storage: ['', 'Mid Range', 'PowerMax', 'VxFlex'],
  Telco: ['', 'Edge', 'Telco'],
  Incremental: ['', 'Alienware', 'Gaming', 'Latitude', 'Precision', 'Optiplex', 'XPS', 'Inspiron/Vostro', 'xLOB', 'Other'],
  EIG: ['', 'Alpha', 'Other']
}

const ReportFilterHOC = (Component: ComponentType<WithReportFilterProps>) =>
  class ReportFilterHOC extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props)
      this.state = {
        selectedFilters: JSON.parse(JSON.stringify(props.report.user_filters)),
        filterSettings: JSON.parse(JSON.stringify(props.report.filters))
      }
    }

        setSelectedFilters = (value: any) => {
          this.setState({
            selectedFilters: value
          })
        };

        updateFrontendFilterSettingsWithBizLogic(selectedFilters: any) {
          // business logic
          const getServerFilterSettingByName = (name: string) =>
            this.props.report.filters.find(serverFilterSetting => serverFilterSetting.name === name)

          if (selectedFilters['fields[business_segment]']) {
            this.state.filterSettings.forEach(filterSetting => {
              if (filterSetting.name === 'fields[lob]') {
                // @ts-ignore
                filterSetting.values = lobs[selectedFilters['fields[business_segment]']] || getServerFilterSettingByName('fields[lob]')?.values || []
                if (filterSetting.values.indexOf(selectedFilters['fields[lob]']) === -1) {
                  selectedFilters['fields[lob]'] = ''
                }
              }
            })
          } else {
            this.state.filterSettings.forEach(filterSetting => {
              if (filterSetting.name === 'fields[lob]') {
                filterSetting.values = getServerFilterSettingByName(filterSetting.name)?.values || []
              }
            })
          }

          if (selectedFilters['fields[lob]']) {
            this.state.filterSettings.forEach(filterSetting => {
              if (filterSetting.name === 'fields[product_group]') {
                // @ts-ignore
                filterSetting.values = productGroups[selectedFilters['fields[lob]']] || getServerFilterSettingByName('fields[product_group]')?.values || []
                if (filterSetting.values.indexOf(selectedFilters['fields[product_group]']) === -1) {
                  selectedFilters['fields[product_group]'] = ''
                }
              }
            })
          } else {
            this.state.filterSettings.forEach(filterSetting => {
              if (filterSetting.name === 'fields[product_group]') {
                filterSetting.values = getServerFilterSettingByName(filterSetting.name)?.values || []
              }
            })
          }
        }

        componentDidMount(): void {
          this.updateFrontendFilterSettingsWithBizLogic(this.state.selectedFilters)
        }

        handleFilterUpdate = (customFilter: any) => {
          const {dateFilters} = this.props

          this.updateFrontendFilterSettingsWithBizLogic(customFilter)
          this.setSelectedFilters({...customFilter, ...dateFilters})
        };

        render() {
          const {report, dateFilters, ...restProps} = this.props
          const {selectedFilters, filterSettings} = this.state

          return (
            <Component
              report={report}
              setSelectedFilters={this.setSelectedFilters}
              selectedFilters={selectedFilters}
              filterSettings={filterSettings}
              handleFilterUpdate={this.handleFilterUpdate}
              {...restProps}
            />
          )
        }
  }

export default ReportFilterHOC
