import * as React from 'react'
import ProjectFormContext from './ProjectFormContext'

const ProjectFormProvider: React.FC<{children?: React.ReactElement, create?: boolean}> = ({create = false, children}) => {
  const [supplierTitle, setSupplierTitle] = React.useState('')
  const updateSupplierTitle = React.useCallback((value) => {
    setSupplierTitle(value)
  }, [])

  const value = {
    create,
    supplierTitle,
    updateSupplierTitle
  }

  return (
    <ProjectFormContext.Provider value={value}>
      {children}
    </ProjectFormContext.Provider>
  )
}

export default ProjectFormProvider
