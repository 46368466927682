import {EChartsOption} from 'echarts-for-react'
import * as React from 'react'
import {useHistory} from 'react-router-dom'

import {Context as ReportContext} from 'contexts/reports/tab/report'
import {Context as ReportTabContext} from 'contexts/reports/tab'
import {http} from 'utils/http'
import Chart from './Chart'
import Filters from './Filters'
import Header from './Header'

const style = {
  padding: '10px 0',
  marginBottom: 40,
  height: '100%',
  border: '1px solid lightgray',
  overflow: 'auto'
}

const Report: React.FC = () => {
  const history = useHistory()
  const {idx: tabIdx} = React.useContext(ReportTabContext)
  const {idx, config} = React.useContext(ReportContext)

  const onReportList = React.useCallback((data: any) => {
    const name = data.data?.name || data.name
    history.push({
      pathname: `/reports/${tabIdx}/${idx}/project-list`,
      search: name ? `name=${encodeURIComponent(name)}` : ''
    })
  }, [history, idx, tabIdx])

  const ref = React.useRef(null)
  // todo: refactor / resolve the issue without using DOM element
  // trick for filters panel overlap issue
  React.useEffect(() => {
    if (ref.current) {
      (ref.current || {} as any).parentNode.style.zIndex = config.collapsed ? 0 : 1
    }
  }, [config])

  const {metric, groupBy, chartType, filters, grid} = config
  const [chartOptions, setChartOptions] = React.useState<EChartsOption>(null)
  const serializedFilters = JSON.stringify(filters)
  const fetchChart = React.useCallback(() => {
    const filters = JSON.parse(serializedFilters)
    http.post('/api/reports/generic-report', {
      metric,
      groupBy,
      chartType,
      filters
    }).then((response: any) => {
      const d = response.data
      if (d.series.type === 'pie' && d.series.data.length > 12 && (d.series.data.length / ((grid.h - 1) * 3)) > 5) {
        d.legend.width = 30
        d.legend.left = '30%'
        d.legend.textStyle = {
          width: 1800 / (d.series.data.length / (grid.h - 1)),
          ellipsis: '...',
          overflow: 'truncate'
        }
        d.series.center = ['15%', '50%']
      }

      setChartOptions(response.data)
    })
  }, [metric, groupBy, chartType, serializedFilters, grid.h])

  React.useEffect(() => {
    fetchChart()
  }, [fetchChart, config])

  return chartOptions && (
    <div className="bg-white flex rounded-xl" style={style} ref={ref}>
      <div className="flex-auto flex flex-col pl-8 pr-20" style={{minWidth: 0}}>
        <div className="mb-3 mr-16">
          <Header/>
        </div>
        <div className="flex-auto">
          <Chart options={chartOptions} onReportList={onReportList}/>
        </div>
      </div>
      <Filters/>
    </div>
  )
}

export default Report
