import {Editor} from '@tinymce/tinymce-react'
import * as React from 'react'

import TemplateClauseContext from 'views/template-clause/context'
import {IMetaDataField, MetaDataFieldInit} from 'components/TinymcePluginMDF'

const noneEditableClassName = 'is-locked'

const getInit: any = (metaDataFields: IMetaDataField[], height: number) => {
  return {
    content_style: '.mce-content-body {padding : 7px; height: 300px}',
    menubar: false,
    height,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount table',
      'noneditable mdf lists'
    ],
    toolbar:
            'mdf | undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | table',
    setup: (editor: any) => {
      // @ts-ignore
      MetaDataFieldInit(window.tinymce, editor, metaDataFields, false, noneEditableClassName)
    },
    noneditable_noneditable_class: noneEditableClassName
  }
}

const TinymceInput: React.FC<{
    height?: number
    value: string
    onChange: (value: string) => void
    inline: false
}> = ({value, onChange, inline, height = 286}) => {
  const {
    metaDataFieldsLoaded,
    metaDataFields
  } = React.useContext(TemplateClauseContext)

  const init = React.useMemo(() => getInit(metaDataFields, height), [metaDataFields, height])

  return metaDataFieldsLoaded ? (
    <Editor
      value={value}
      inline={inline}
      init={init}
      apiKey="rth1kxg3nn0dla1xo4fpv0wzgpi8j09n56838c4akj5k3hc0"
      onEditorChange={(data: any) => {
        onChange(data)
      }}
    />
  ) : null
}

export default TinymceInput
