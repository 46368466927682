import * as React from 'react'

import {Option} from 'components/Dropdown'
import {http} from 'utils/http'

function dropdownFetchesOptionsFor<T = string | number | boolean>(Component: React.ComponentType<any>) {
  return ({api, apiParams, ...restProps}: any) => {
    const [options, setOptions] = React.useState<Option<T>[]>([])
    const [optionsReady, setOptionsReady] = React.useState<boolean>(false)

    const loadOptions = React.useCallback((key: string | null) => {
      setOptionsReady(false)

      http.get<any>(api, {
        ...apiParams,
        // phrase is able to overwrite, default phrase is ''
        phrase: typeof key === 'string' ? key : apiParams?.phrase || ''
      }).then((response) => {
        const options = response.data.map((item: any) => ({
          label: item.name,
          value: item.id
        }))

        setOptions(options)
        setOptionsReady(true)
      })
    }, [api, apiParams])

    React.useEffect(() => {
      loadOptions(null)
      // eslint-disable-next-line
        }, [loadOptions]);

    return (
      <Component
        loadOptions={loadOptions}
        options={options}
        optionsReady={optionsReady}
        {...restProps}
      />)
  }
}

export default dropdownFetchesOptionsFor
