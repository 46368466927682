import React from 'react'
import {translate} from 'utils/translations'
import {AiDocument} from '../documents/New'
import {http} from 'utils/http'
import {DocumentComponent} from 'components/documents/Document'
import {Icon} from 'components/Icon'

const DocumentsList = ({
  documents,
  onDelete
}: {
    sortBy?: string;
    sortDirection?: 'asc' | 'desc';
    documents: AiDocument[];
    onSort?: (column: string, direction: 'asc' | 'desc') => void
    onDelete: () => void;
  }) => {

  const deleteDocument = (id: string) => {
    http.delete(`/api/ai/documents/${id}`).then(() => {
      onDelete()
    })
  }

  return (
    <>
      {
        documents && documents.length ?
          documents.map((document, index) => {

            return document?.document && (
              <DocumentComponent
                document={document?.document}
                key={index}
                extra={document.answers ? <Icon name="done" size={6} className="text-green-700 rounded-full bg-green-100 p-1"/> : <></>}
                modalUrl="library"
                onDelete={() => { deleteDocument(document.id) }}
                change={false}
              />
            )
          })
          : (<table className="table">
            <tbody>
              <tr>
                <td colSpan={8} className="text-center">
                  {translate('No Documents found')}
                </td>
              </tr>
            </tbody>
          </table>
          )
      }
    </>
  )
}

export default DocumentsList
