import React, {FC} from 'react'

import {Context as ReportContext} from 'contexts/reports/tab/report'
import Title from './Title'

const Header: FC = () => {
  const {config} = React.useContext(ReportContext)

  return (
    <div
      className={`${config.chartType === 'simple-box' ? 'mt-0' : ''}`}
      style={{
        display: config.chartType === 'simple-box' ? 'none' : 'inherit'
      }}
    >
      <div className="flex items-center">
        <Title />
      </div>
    </div>
  )
}

export default Header
