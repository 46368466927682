import {FieldTemplate, Project, TeamMember} from '@contractool/schema'
import React, {FC, ReactNode, useContext, useState} from 'react'
import {useParams, useHistory} from 'react-router'


import {Icon} from 'components/Icon'
import FieldAtTop from 'components/FieldAtTop'
import {AppContext} from 'contexts'
import {useRequest} from 'hooks/useRequest'
import {translate} from 'utils/translations'
import {permissionRequest} from 'utils/wildcard'
import {ApprovalsPanel} from 'views/projects/Approvals/ApprovalsPanel'

import {cx} from 'utils/classnames'
import {Tutorial} from 'views/projects/Widgets/Tutorial'
import {TaskPanel} from './TaskPanel'
import {ProjectDetailsPanel} from './ProjectDetailsPanel'
import {ContractPanel} from './ContractPanel'
import {DocumentsPanel} from './DocumentsPanel'
import {ProjectActions} from './ProjectActions'
import {ProgressPanel} from './ProgressPanel'
import {ActivityPanel} from './ActivityPanel'
import {ProjectTeam} from './ProjectTeam'
import {Milestones} from './Widgets/Milestone'
import {Esign} from './Widgets/Esign'
import {WetSign} from './Widgets/WetSign'
import {Hierarchy} from './Widgets/Hierarchy'
import {Workload} from './Widgets/Workload'
import Group from './Widgets/Group'
import ProjectContext from './ProjectContext'
import ExecutedInfos from './ExecutedInfos'
import ShowDocumentButton from './ShowDocumentButton'
import {ProjectCreatedPopup} from 'views/ProjectCreatedPopup'
import {ProjectLabels} from 'views/projects/ProjectLabels'
import {ProjectAiExtractedDataWidget} from 'views/projects/Widgets/Ai/ProjectAiExtractedDataWidget'
import {ProjectRedFlagWidget} from 'views/projects/Widgets/RedFlags/ProjectRedFlagWidget'

export function ProjectDetail() {
  const {id} = useParams<{id?: string}>()
  const [project, {refresh}] = useRequest<Project | undefined>(
    `/api/projects/${id}`,
    undefined,
    undefined,
    () => {setProjectLoading(false)}
  )
  const history = useHistory()
  const [projectLoading, setProjectLoading] = useState<boolean>(false)
  const {config} = useContext(AppContext)
  const topProjectFields = config.fields
    ? config.fields.filter((f: FieldTemplate) => f.workflow === project?.workflow && f.isAtTop)
    : []

  const projectContext = React.useMemo(
    () => ({
      resource: project as Project,
      teamMembers: project?.team.memberships.filter(
        (member: TeamMember) => member.role !== 'assignee' && member.role !== 'requestor' && !member.deleted
      ) ?? [],
      refresh
    }),
    [project, refresh]
  )
  if (!project) return null
  const close = () => {
    history.push(`/projects/${project.id}`)
  }

  const CancelTag: FC<{project: Project}> = ({project}) => {
    return ['denied', 'canceled', 'onhold'].some((item) => project.state.key.includes(item)) ? (
      <div
        className="px-2 py-1 self-center bg-red-100 text-red-700 rounded-lg"
        data-cy="canceled-tag"
      >
        {translate(project.state.label)}
      </div>
    ) : null
  }

  const repositoryView = project.state.key === 'completed' && project.state.extra?.repository_view

  return (
    <ProjectContext.Provider value={projectContext}>
      <div className="flex flex-col h-full">
        <ProjectCreatedPopup/>
        <div className="p-16 border-b border-gray-200" data-cy-section="header">
          {repositoryView ?
            <div className={cx(
              'mb-6 flex justify-between',
              'items-center'
            )}>
              <div className={cx(
                'flex',
                'items-center'
              )}>
                {project.category && (
                  <div
                    className="self-center mr-4 py-2 rounded-full text-gray-500 flex cursor-pointer"
                    onClick={() => {history.goBack()}}
                  >
                    <Icon name="keyboard_arrow_left" size={6} />
                    {project.category?.title}
                  </div>
                )}
              </div>
            </div>
            :
            <div className={cx(
              'mb-6 flex justify-between'
            )}>
              <div className={cx('flex')}>
                {project.category && (
                  <div
                    className="self-center mr-4 py-2 px-4 rounded-full flex"
                    style={{
                      background: project.category?.icon?.background
                        ? project.category?.icon?.background
                        : '#ECF3FF',
                      color: project.category?.icon?.foreground
                        ? project.category?.icon?.foreground
                        : '#6CA3FF'
                    }}
                  >
                    {project.category?.icon && (
                      <div
                        style={{
                          background: project.category.icon.background,
                          color: project.category.icon.foreground
                        }}
                        className="mr-4 bg-gray-100 rounded-full "
                      >
                        <Icon name={project.category.icon.name} size={6} />
                      </div>
                    )}
                    {project.category?.title}
                  </div>
                )}
                <ProjectActions project={project} onRefresh={refresh} repositoryView={repositoryView} />
              </div>
              <div className="flex justify-between">
                <ProjectTeam onUpdate={refresh} onClose={close} />
              </div>
            </div>
          }
          {repositoryView ? (
            <div className="flex justify-between">
              <div className="flex">
                <div
                  className="self-center mr-4 p-4 rounded-full flex"
                  style={{
                    background: project.category?.icon?.background
                      ? project.category?.icon?.background
                      : '#ECF3FF',
                    color: project.category?.icon?.foreground
                      ? project.category?.icon?.foreground
                      : '#6CA3FF'
                  }}
                >
                  {project.category?.icon && (
                    <div
                      style={{
                        background: project.category.icon.background,
                        color: project.category.icon.foreground
                      }}
                      className="bg-gray-100 rounded-full "
                    >
                      <Icon name={project.category.icon.name} size={6} />
                    </div>
                  )}
                </div>
                <h1 className="text-xl self-center mr-4">{project.title}</h1>
                <CancelTag project={project} />
              </div>
              <div className="flex">
                <ProjectLabels project={project} />
                <ProjectActions
                  size="regular"
                  radius="regular"
                  project={project}
                  onRefresh={refresh}
                  repositoryView={repositoryView} />
                <ShowDocumentButton project={project} />
              </div>
            </div>
          ) :
            <div className="flex">
              <h1 className="text-xl mr-4">{project.title}</h1>
              <CancelTag project={project} />
            </div>
          }
          {repositoryView ? (
            <ExecutedInfos
              project={project}
            />
          ) : null}
          {!repositoryView && (
            <ProgressPanel
              project={project}
              onRefresh={() => {
                refresh()
              }}
              projectLoading={projectLoading}
              setProjectLoading={setProjectLoading}
            />
          )}
        </div>
        {project.extra.sign_url && (
          <div className="mb-8" style={{height: '800px'}}>
            <iframe
              title={project.title}
              src={project.extra.sign_url}
              width="100%"
              height="100%"
              style={{
                border: 0,
                overflow: 'hidden',
                minHeight: '600px',
                minWidth: '600px'
              }}
            />
          </div>
        )}
        {project.state.extra.components?.includes('milestone') &&
      permissionRequest(project.can, 'project.widget.milestone.view') && (
            <div className="p-10 border-b border-gray-200 bg-gray-000">
              <Milestones project={project} onUpdate={refresh} />
            </div>
          )}
        {project.state.extra.components?.includes('tutorial') && <Tutorial project={project} />}
        {project.state.extra.components?.includes('workload') && (
          <div className="p-10 border-b border-gray-200 ">
            <Workload project={project}/>
          </div>
        )}
        <div className="flex flex-col-reverse md:flex-row md:flex-1 ">
          <div className="w-full md:w-7/12 p-10 pt-8 bg-gray-000">
            {project.state.extra.components?.includes('log') && (
              <ActivityPanel project={project} onUpdate={refresh} />
            )}
          </div>
          <div className="w-full md:w-5/12 border-l border-gray-200">
            {topProjectFields.map((field: FieldTemplate) => <FieldAtTop key={field.name} field={field} project={project} />)}
            {!repositoryView &&
          project.state.extra.components?.includes('contract') && (
              <ContractPanel project={project} onUpdate={refresh} onClose={close} />
            )}
            {config.integration === 'dell_sow' && <ProjectRedFlagWidget  project={project} onClose={close}/>}
            {config.integration === 'dell_sow' && <ProjectAiExtractedDataWidget project={project}/>}
            {project.state.extra.components?.includes('documents') && (
              <DocumentsPanel
                heading={translate('Attachments')}
                fileAddLabel={translate('Add files')}
                project={project}
                onUpdate={refresh}
                onClose={close}
                repositoryView={repositoryView}
              />
            )}
            {project.signatures?.length > 0 && (
              <Esign project={project} />
            )}
            {project.state.extra.components?.includes('wet_sign') && (
              <WetSign project={project} />
            )}
            {project.fields.rfp === true && (
              <DocumentsPanel
                heading={translate(':type Files', {type: 'RFP'})}
                fileAddLabel={translate('Add :type file', {type: 'RFP'})}
                type="RFP"
                project={project}
                onUpdate={refresh}
                onClose={close}
                repositoryView={repositoryView}
              />
            )}
            {project.fields.competitive_bidding === 'Yes' && (
              <DocumentsPanel
                heading="Bid Files"
                fileAddLabel="Add Bid Files"
                type="RFP"
                project={project}
                onUpdate={refresh}
                onClose={close}
                repositoryView={repositoryView}
              />
            )}
            {project.state.extra.components?.includes('tasks') && (
              <TaskPanel project={project} onUpdate={refresh} onClose={close} />
            )}
            {project.state.extra.components?.includes('approvals') && (
              <ApprovalsPanel project={project} onUpdate={refresh} onClose={close} />
            )}
            {project.state.extra.components?.includes('details') && (
              <ProjectDetailsPanel project={project} onUpdate={refresh} onClose={close} repositoryView={repositoryView} />
            )}
            {project && (
              <ProjectContext.Provider value={projectContext}>
                <Group />
              </ProjectContext.Provider>
            )}
            {project.state.extra.components?.includes('hierarchy') && (
              <Hierarchy project={project} />
            )}
          </div>
        </div>
      </div>
    </ProjectContext.Provider>
  )
}

export const SidePanel: FC<{heading: ReactNode; right?: ReactNode; className?: string}> = ({
  heading,
  right,
  className,
  children
}) => {
  return (
    <div
      className={className ? className : 'p-8 border-b border-gray-200'}
      data-cy-section={heading}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="text-lg">{heading}</div>
        {right}
      </div>
      {children}
    </div>
  )
}
