import {EmailTemplates} from '@contractool/schema'
import React, {useCallback, useMemo} from 'react'
import {useParams, useHistory, Redirect} from 'react-router'

import {Button} from 'components/Button'
import {Icon} from 'components/Icon'
import {Menu, MenuItem} from 'components/Menu'
import {Modal} from 'components/Modal'
import {useToasts} from 'hooks'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import Form from './Form'
import {tag} from './constants'
import {Form as CommonForm} from 'components/Form'
import {useRequest} from 'hooks/useRequest'

export function Edit({onUpdate}: {
  onUpdate: () => void
}) {
  const history = useHistory()
  const {id: idParam} = useParams<{id: string}>()
  const id = parseInt(idParam)
  const [emailTemplateResponse] = useRequest<EmailTemplates>(`/api/settings/${tag}/${id}`, {})
  const emailTemplateSource = useMemo(() => emailTemplateResponse, [emailTemplateResponse])

  const goToSettingsPage = useCallback(() => history.push(`/settings/${tag}`), [history])
  const {success} = useToasts()

  const deleteEmailTemplatesSetting = useCallback(() => {
    http.delete(`/api/settings/${tag}/${id}`).then(() => {
      onUpdate()
      success('The email template has been deleted.', {
        position: 'center'
      })
      goToSettingsPage()
    })
  }, [goToSettingsPage, onUpdate, success, id])

  return emailTemplateSource ? 
    (
      <>
        {
          emailTemplateSource.id && (
            <Modal
              heading={translate('Email Template Detail')}
              onClose={goToSettingsPage}
              contentClassName="h-124"
              corner={
                <Menu
                  handle={({toggleMenu}) => (
                    <div onClick={toggleMenu} className="flex items-center cursor-pointer">
                      <Icon name="more_horiz" className="text-gray-600 cursor-pointer" size={6} />
                    </div>
                  )}
                >
                  <MenuItem icon="remove_circle" onClick={deleteEmailTemplatesSetting}>
                    {translate('Delete')}
                  </MenuItem>
                </Menu>
              }
            >
              {emailTemplateSource.id && (<CommonForm
                initialValues={emailTemplateSource}
                onSubmit={(emailTemplate) => {

                  return http.post(`/api/settings/${tag}/${id}`, emailTemplate)
                }}
                onSuccess={() => {
                  onUpdate()
                  success('Email Template has been updated.', {
                    position: 'center'
                  })
                  goToSettingsPage()
                }}
              >
                <Form />

                <Modal.Footer className="flex justify-between">
                  <Button color="white" onClick={history.goBack}>
                    {translate('Cancel')}
                  </Button>

                  <CommonForm.Submit>{translate('Save Changes')}</CommonForm.Submit>
                </Modal.Footer>
              </CommonForm>)}
            </Modal>
          )
        }
      </>
    ) : <Redirect to="/settings" />
}
