import {Project, TeamMember} from '@contractool/schema'
import {createContext} from 'react'

const ProjectContext = createContext<{
    resource: Project,
    teamMembers: TeamMember[],
    refresh: () => void// todo: remove refresh, add CRUD here
      }>({
        resource: null as unknown as Project,
        teamMembers: null as unknown as TeamMember[],
        refresh: () => {}
      })

export default ProjectContext
