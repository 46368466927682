/**
 * Accepts a map of "counts" to "placeholders".
 *
 * Example: {1: 'book', 5: 'BOOKS', _: 'books'}
 * For this (^) input it returns "book" when the
 * count argument is 1, "BOOKS" when it's 5, and
 * "books" in every other case.
 *
 * You can also make use of the special ":count"
 * placeholder to make your life easier.
 *
 * Example: {1: ':count book', _: ':count books'}
 * As you would expect, the ":count" string gets
 * replaced by the actual count.
 */
export function pluralize(
  placeholders: {_: string} & Record<number, string>
): (count: number) => string {
  return function (count: number): string {
    return (placeholders[count] || placeholders['_']).replace(':count', String(count))
  }
}
