import {useLocalStorage} from '@rehooks/local-storage'

import {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {LS_PROJECTS_QUERY_KEY} from 'data'

const useMemorizedQuery = () => {
  const [
    projectsQueryInLocalStorage,
    setProjectsQueryInLocalStorage
  ] = useLocalStorage<string>(LS_PROJECTS_QUERY_KEY)

  const location = useLocation()
  useEffect(() => {
    setProjectsQueryInLocalStorage(location.search)
  }, [location, setProjectsQueryInLocalStorage])

  const history = useHistory()
  const [isQueryLoaded, setQueryAsLoaded] = useState(false)
  useEffect(() => {
    if (projectsQueryInLocalStorage && projectsQueryInLocalStorage !== history.location.search) {
      history.push({
        ...history.location,
        search: projectsQueryInLocalStorage
      })
    }
    setQueryAsLoaded(true)
    // eslint-disable-next-line
    }, []);

  return isQueryLoaded
}

export default useMemorizedQuery
