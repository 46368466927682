import {Clause} from '@contractool/schema'
import React, {useContext} from 'react'
import {useFetcher} from 'rest-hooks'

import {ClauseOverlay} from 'components/template-clause/clause'
import {useToasts} from 'hooks'
import {ClauseResource} from 'resources'
import {translate} from 'utils/translations'
import {Context as ClausesContext} from 'views/template-clause/tab/clauses'

const initialValues: Clause = {
  id: 0,
  name: '',
  content: '',
  categories: []
}

const ClauseCreateOverlay: React.FC<{
  onDismiss: () => void
}> = ({onDismiss}) => {
  const createClause = useFetcher(ClauseResource.create())
  const {success} = useToasts()

  const {refreshClauses} = useContext(ClausesContext)

  return (
    <ClauseOverlay
      cta={translate('Add clause')}
      heading={translate('Add clause')}
      initialValues={initialValues}
      onDismiss={onDismiss}
      onSubmit={async (clause) => {
        await createClause({}, clause)
        success(translate('Clause is successfully created.'))
        onDismiss()
        success(translate('Template is successfully created.'))
        await refreshClauses()
      }}
    />
  )
}

export default ClauseCreateOverlay
