import {NotificationSetting, Role} from '@contractool/schema'
import React, {FC, useContext} from 'react'
import ReactDOM from 'react-dom'
import {useHistory} from 'react-router'

import {Button} from 'components/Button'
import {AppContext} from 'contexts'
import {translate} from 'utils/translations'
import {tag} from './constants'

interface IListProps {
    notificationSettings: NotificationSetting[]
}

const List: FC<IListProps> = ({notificationSettings}) => {
  const history = useHistory()

  const {config} = useContext(AppContext)
  const buttonContainer = document.getElementById('page-header-right')

  return (
    <><table className="table">
      <thead>
        <tr>
          <th>{translate('Roles')}</th>
          <th>{translate('Email Template')}</th>
          <th>{translate('In App Notification')}</th>
        </tr>
      </thead>
      <tbody>
        {notificationSettings.map((notificationSetting) => (
          <tr
            key={notificationSetting.id}
            className="hover:bg-gray-000 cursor-pointer rounded"
            onClick={() => history.push(`/settings/${tag}/${notificationSetting.id}/edit`)}
          >
            <td>{config.roles.filter((role: Role) => notificationSetting.roleKeys?.includes(role.key)).map(({label}: Role) => label).join(', ')}</td>
            <td className="text-gray-600" dangerouslySetInnerHTML={{__html: notificationSetting.emailTemplate ?? ''}}/>
            <td>{notificationSetting.inAppNotification}</td>
          </tr>
        ))}
      </tbody>
    </table>
    {buttonContainer && ReactDOM.createPortal((
      <Button.Link to="/settings/notifications/new" color="blue" icon="add">
        {translate('Add notification')}
      </Button.Link>
    ), buttonContainer)}
    </>
  )
}

export default List
