import {Resource} from '@rest-hooks/rest'

export class TemplateResource extends Resource {
    readonly id: number | undefined = undefined
    readonly name: string = ''
    readonly categories: any[] = []
    readonly clauses: any[] = []

    pk() {
      return this.id?.toString()
    }

    static urlRoot = '/api/templates'

    static list<T extends typeof Resource>(this: T) {
      return super.list().extend({
        schema: {
          data: [this],
          links: {},
          meta: {
            current_page: 1,
            last_page: 1
          }
        }
      })
    }
}
