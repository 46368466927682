import React from 'react'
import {AppContext} from 'contexts'
import {Member} from './components'

const Group = () => {
  const {
    config: {
      widget_fields: groups
    }
  } = React.useContext(AppContext)

  return (
    <>
      {Object.keys(groups).map(key => (
        <Member
          key={key}
          memberKey={key}
          group={groups[key]}
        />
      ))}
    </>
  )
}

const PureGroup = React.memo(Group)

export default PureGroup
