import {FieldTemplate, Report} from '@contractool/schema'
import React, {FC, useCallback, useState, useContext} from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import {useRequest} from 'hooks/useRequest'
import {EventEmitter} from 'utils/eventEmitter'
import {ReportFilter} from '../ReportFilter'
import {Option} from 'components/Dropdown'
import ReportFilterHOC from '../ReportFilterHOC'
import {AppContext} from 'contexts'

const OpenByState: FC<{
    report: Report;
    selectedFilters: any,
    setSelectedFilters: any,
    handleFilterUpdate: (customFilter: any) => void,
    filterSettings: FieldTemplate[],
    usersAsOptions: Option<any>[];
    suppliersAsOptions: Option<any>[];
}> = ({report, selectedFilters, setSelectedFilters, handleFilterUpdate: handleFilterUpdateProp, filterSettings, usersAsOptions, suppliersAsOptions}) => {
  const handleFilterUpdate = useCallback((customFilter: any) => {
    EventEmitter.dispatch('open-by-state-filter', customFilter) // todo: remove
    handleFilterUpdateProp(customFilter)
  }, [handleFilterUpdateProp])

  const {config} = useContext(AppContext)
  const [subscribed, setSubscribed] = useState(false)
  const [data] = useRequest<any>(`/api/reports/${report.slug}`, undefined, {
    params: selectedFilters
  })

  if (!data) {
    return null
  }

  if (!subscribed) {
    EventEmitter.subscribe('report-date-filter', (dateFilter: any) => {
      setSelectedFilters({...selectedFilters, ...dateFilter})
    })

    setSubscribed(true)
  }
  data.plotOptions.series = {
    events: {
      click: function (event: any) {
        let state = ''
        // @ts-ignore
        if (config.integration === 'dell_ji') {//TODO JI
          switch (event.point.name) {
            case 'JI Proposal':
              state = 'proposal'
              break
            case 'Internal Alignment':
              state = 'alignment'
              break
            case 'Negotiation':
              state = 'negotiation'
              break
            case 'Field Team':
              state = 'field'
              break
            case 'Director Review':
              state = 'director'
              break
            case 'Internal Review':
              state = 'internal'
              break
            case 'Vendor Signed':
              state = 'vendor'
              break
            case 'Executed':
              state = 'executed'
              break
          }
        }
        if (config.integration === 'demo') {//TODO JI
          switch (event.point.name) {
            case 'New project':
              state = 'new'
              break
            case 'Data validation':
              state = 'validation'
              break
            case 'Contract Authoring':
              state = 'authoring'
              break
            case 'Negotiating':
              state = 'negotiating'
              break
            case 'Approving':
              state = 'approving'
              break
            case 'Executing':
              state = 'executing'
              break
          }
        }
        EventEmitter.dispatch('report-projects', {
          ...selectedFilters,
          state,
          report: {
            slug: report.slug
          }
        })
      }
    }
  }
  
  return (
    <div>
      <div className="flex justify-between">
        <h1 className="p-5 border-b border-gray-100">{report.title}</h1>
        <ReportFilter
          selectedFilters={selectedFilters}
          filterSettings={filterSettings}
          reportSlug={report.slug}
          onFilter={handleFilterUpdate}
          usersAsOptions={usersAsOptions}
          suppliersAsOptions={suppliersAsOptions}
        />
      </div>
      <div className="p-5 ">
        <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={data} />
      </div>
    </div>
  )
}

const EnhancedOpenByState = ReportFilterHOC(OpenByState)

export default EnhancedOpenByState
