import {ApprovalRule, Role, User, UserGroup} from '@contractool/schema'
import React, {FC, useContext} from 'react'
import ReactDOM from 'react-dom'
import {useHistory} from 'react-router'

import {Button} from 'components/Button'
import {AppContext} from 'contexts'
import {useRequest} from 'hooks/useRequest'
import {translate} from 'utils/translations'

interface IListProps {
    approvalRules: ApprovalRule[]
}

const List: FC<IListProps> = ({approvalRules}) => {
  const history = useHistory()

  const [users] = useRequest<User[]>('/api/users', [], {
    params: {id: approvalRules.map((approvalRule) => approvalRule.userId)}
  })
  const {config} = useContext(AppContext)
  const buttonContainer = document.getElementById('page-header-right')

  return (
    <><table className="table">
      <thead>
        <tr>
          <th>{translate('Name')}</th>
          <th>{translate('User')}</th>
          <th>{translate('Project Role')}</th>
          <th>{translate('Group')}</th>
          <th>{translate('Body')}</th>
          <th>{translate('Deadline')}</th>
        </tr>
      </thead>
      <tbody>
        {approvalRules.map((approvalRule) => (
          <tr
            key={approvalRule.id}
            className="hover:bg-gray-000 cursor-pointer rounded"
            onClick={() => history.push(`/settings/approval-rules/${approvalRule.id}/edit`)}
          >
            <td>{approvalRule.input}</td>
            <td>{users.find(user => approvalRule.userId === user.id)?.name ?? '/'}</td>
            <td>{config.roles.find((role: Role) => approvalRule.roleKey === role.key)?.label ?? '/'}</td>
            <td className="text-gray-600">{config.user_groups.find((group: UserGroup) => approvalRule.groupKey === group.key)?.label ?? '/'}</td>
            <td className="text-gray-600"><div className="w-40 truncate">{approvalRule.text}</div></td>
            <td>{approvalRule.deadline}</td>
          </tr>
        ))}
      </tbody>
    </table>
    {buttonContainer && ReactDOM.createPortal((
      <Button.Link to="/settings/approval-rules/new" color="blue" icon="add">
        {translate('Add approval rule')}
      </Button.Link>
    ), buttonContainer)}
    </>
  )
}

export default List
