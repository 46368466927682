import React from 'react'
import {Section, Variation} from './DesignSystem'
import {Button} from '../../components/Button'
import {useToasts} from 'hooks'

export function Toasts() {
  const {success, warning, error} = useToasts()

  return (
    <>
      <Section title="Toast styles">
        <Variation>
          <Button
            color="blue"
            onClick={() => success('What a wonderful world!', {
              position: 'top-right',
              icon: 'word',
              closeTimeout: 10000
            })}
          >
                        Show a success toast
          </Button>
        </Variation>

        <Variation>
          <Button onClick={() => warning('Danger, Will Robinson!')} color="yellow">
                        Show a warning toast
          </Button>
        </Variation>

        <Variation>
          <Button onClick={() => error('Something went wrong!')} color="red">
                        Show an error toast
          </Button>
        </Variation>
      </Section>
    </>
  )
}
