import {FilterCondition, FilterTemplate, NotificationSetting, Role} from '@contractool/schema'
import React, {FC, useContext, useEffect, useMemo, useReducer, useState} from 'react'

import {Button} from 'components/Button'
import {Option} from 'components/Dropdown'
import {Form as CommonForm, FormContext} from 'components/Form'
import {AppContext} from 'contexts'
import {usePrevious} from 'hooks'
import {useRequest} from 'hooks/useRequest'
import {filterReducer} from 'utils/filterReducer'
import {translate} from 'utils/translations'
import {Condition} from 'views/projects/FilterNew'

const init = {
  content_style: '.mce-content-body {padding : 7px}',
  height: 200,
  menubar: false,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount'
  ],
  toolbar: 'undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help'
}

interface INotificationFormProps {
  notificationSetting: NotificationSetting
}

const Form: FC<INotificationFormProps> = ({
  notificationSetting
}) => {
  const {config} = useContext(AppContext)

  const roleOptions: Option<string>[] = useMemo(() =>
    config.roles.map((role: Role) => ({
      value: role.key,
      label: role.label
    })), [config])

  const [filterTemplates] = useRequest<FilterTemplate[]>('/api/project-filter-templates', [])
  const listOfConditions = filterTemplates.map((template: FilterTemplate) =>
    ({label: template.label, value: template.name})
  )

  const [errors, setErrors] = useState<{index: number; field: string}[]>([])

  const [conditions, setConditions] = useReducer(filterReducer, notificationSetting?.conditions ?? [])
  const previousConditions = usePrevious(conditions)
  const serializedConditions = JSON.stringify(conditions)

  const {handleChange} = useContext(FormContext)
  useEffect(() => {
    if (
      typeof previousConditions !== 'undefined' &&
      JSON.stringify(previousConditions) !== JSON.stringify(conditions)
    ) {
      handleChange('conditions updated', JSON.parse(serializedConditions))
    }
    // eslint-disable-next-line
  }, [previousConditions, conditions])

  return (
    <>
      <div className="mb-6 -mx-2">
        <CommonForm.Multiselect
          label="Role"
          name="roleKeys"
          options={roleOptions}
          placeholder={translate('Select role')}
          hasOnlyValues
        />
      </div>
      <div className="mb-6 -mx-2">
        <CommonForm.TextTinyMce
          name="emailTemplate"
          label={translate('Email Template')}
          className="mb-6"
          init={init}
          required
        />
      </div>
      <div className="mb-6 -mx-2">
        <CommonForm.TextInput
          name="inAppNotification"
          label={translate('In App Notification')}
          className="mb-6"
          required
        />
      </div>
      {conditions.map((condition: FilterCondition, index) => (
        <div key={condition.id + condition.subject}>
          <div className="text-gray-600">{index ? 'and' : 'Condition'}</div>
          <Condition
            errors={errors.filter((error) => error.index === index)}
            onChange={(field) => {
              setErrors(
                errors.filter(
                  (error) =>
                    !(error.field === field && error.index === index)
                )
              )
            }}
            listOfConditions={listOfConditions}
            condition={condition}
            filterTemplates={filterTemplates}
            dispatch={setConditions}
          />
        </div>
      ))}
      <div className="flex items-center my-8 text-gray-600">
        <Button
          onClick={() => setConditions({type: 'ADD_CONDITION'})}
          color="white"
          size="small"
          radius="full"
          icon="add"
          className="mr-4"
        />
        {translate('Add condition')}
      </div>
    </>
  )
}

export default Form
