import React, {ReactNode, ReactElement} from 'react'

export function Radio({
  name,
  value,
  selectedValue,
  children,
  className,
  onChange
}: {
    name: string;
    value: string;
    selectedValue: string | null;
    onChange: (value: string) => void;
    children?: ReactNode;
    className?: string;
}): ReactElement {
  const checked = value === selectedValue

  return (
    <label
      className={`relative cursor-pointer flex items-center select-none ${className}`}
      htmlFor={value}
    >
      <div className="pl-9 ml-px">{children}</div>

      <input
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => {
          onChange && onChange(e.target.value)
        }}
        className="absolute cursor-pointer w-0 h-0 bg-blue-700 hover:bg-blue-800 opacity-0"
      />

      {checked ? (
        <div className="absolute left-0 w-6 h-6 rounded-full bg-white border border-blue-700 hover:border-blue-800 flex justify-center items-center p-1">
          <div className="rounded-full w-full h-full bg-blue-700 hover:bg-blue-800 border border-white"></div>
        </div>
      ) : (
        <div className="absolute left-0 w-6 h-6 rounded-full border border-gray-200 bg-white flex justify-center items-center pb-1"></div>
      )}
    </label>
  )
}
